import { useEffect, useState } from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@material-tailwind/react";
import { Card, Button, CardBody, Avatar } from "@material-tailwind/react";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { TABLE_HEAD_ORDER } from "../../../data";
import OrderFilter from "../../../pages/products/filters/OrderCode";
import Pagination from "../../Pagination";
import {
  OrderResult,
  PaginationProps,
  RootOrder,
  orderList,
} from "../../../utils/sales";
const Order = () => {
  const [orderData, setOrderData] = useState<OrderResult[]>([]);
  const [search, setSearch] = useState<string>();
  const [typeOfOrder, setTypeOfOrder] = useState<string>();
  const [paymentType, setPaymentType] = useState<string>();
  const [paymentStatus, setPaymentStatus] = useState<boolean | undefined>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 10;
  const [pagination, setPagination] = useState<PaginationProps>();
  useEffect(() => {
    (async () => {
      setLoading(true);
      const res: RootOrder = await orderList({
        page: page,
        limit: limit,
      });

      if (res.status === 200) {
        setOrderData(res.data.results);
        setPagination(res.data.pagination);
        setLoading(false);
      } else {
        console.error("Error fetching order list:", res);
      }
    })();
  }, []);

  const filterAction = async () => {
    try {
      const response: RootOrder = await orderList({
        page: page,
        limit: limit,
        search: search?.length ? search : undefined,
        typeoforder: typeOfOrder?.length ? typeOfOrder : undefined,
        paymentType: paymentType?.length ? paymentType : undefined,
        paymentStatus: paymentStatus,
      });
      if (response.status === 200) {
        setOrderData(response.data.results);
        setPagination(response.data.pagination);
      } else {
        console.error("Error fetching gig list:", response);
      }
    } catch (error: any) {
      toast.error(JSON.stringify(error));
      console.error("Error fetching gig list:", error);
    }
  };
  const nextAction = async () => {
    try {
      if (pagination?.next !== undefined) {
        setPage(pagination.next);
        console.log("Next arrow clicked. New skip value:", pagination.next);
        const response: RootOrder = await orderList({
          page: pagination!.next!,
          limit: limit,
        });
        if (response.status === 200) {
          setOrderData(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching user list:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  const previousAction = async () => {
    try {
      if (pagination?.previous !== undefined) {
        setPage(pagination.previous);
        const response: RootOrder = await orderList({
          page: pagination!.previous!,
          limit: limit,
        });
        if (response.status === 200) {
          setOrderData(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching user list:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  return (
    <section>
      <div className="bg-white p-3 rounded-lg flex items-center justify-between">
        <h6 className="font-Roboto text-[1.4rem] font-bold text-black">
          Order
        </h6>
        <Button
          className="flex items-center gap-3 bg-bluePostaam-600"
          size="sm"
        >
          <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" />
          Export CVS
        </Button>
      </div>
      <div>
        <OrderFilter
          setSearch={setSearch}
          setPaymentStatus={setPaymentStatus}
          setPaymentType={setPaymentType}
          setTypeOfOrder={setTypeOfOrder}
          handelFilter={filterAction}
        />
      </div>
      <Card className="h-full w-full mt-4">
        <CardBody className="overflow-auto px-0 py-0">
          {loading && (
            <div className=" flex justify-center items-center h-[60vh]">
              <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
            </div>
          )}
          {!loading && (
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD_ORDER.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-sm text-blue-gray-900 font-normal leading-none opacity-70"
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {orderData.length === 0 && (
                  <tr>
                    <td className="px-6 py-3">No Data Available</td>
                  </tr>
                )}
                {orderData.length > 0 &&
                  orderData.map((item, index) => {
                    const isLast = index === orderData.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr key={index}>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.subOrderCode}
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Avatar
                              src={item.product.image}
                              alt={item.product.brand}
                              size="md"
                              className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                            />
                            <div className="flex flex-col">
                              <span className="font-normal  text-sm">
                                {item.product.productName}
                              </span>
                              <span className="font-normal  text-sm">
                                {item.shippingAddress.firstname}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.product.quantity}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.deliveryDate.split("T")[0]}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.orderStatus}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.paymentType}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.paymentStatus === false ? "Not Paid" : "Paid"}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          {item.details.total}
                        </td>
                        <td className={`${classes} font-normal text-sm`}>
                          <Link to={`/SingleOrder/${item.subOrderCode}`}>
                            <FaEye size={20} />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </CardBody>
        <div>
          {pagination && (
            <Pagination
              nextAction={nextAction}
              previousAction={previousAction}
              total={pagination!.total}
              currentPage={page}
            />
          )}
        </div>
      </Card>
    </section>
  );
};
export default Order;
