import { useEffect, useState } from "react";
import Pagination from "../Pagination";
import { Spinner } from "@material-tailwind/react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import postoomLogo from "../../assets/image/postoomlogo.png";
import {
  RootProductTableList,
  productTableDetails,
  Result,
  PaginationProps,
} from "../../utils/product";
import Filter from "../../pages/products/filters/userlistFilter";
const ProductList = () => {
  const [productList, setProductList] = useState<Result[]>([]);
  const [pagination, setPagination] = useState<PaginationProps>();
  const limit = 10;
  const [page, setPage] = useState(1);
  const [productName, setProductName] = useState<string>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const response: RootProductTableList = await productTableDetails({
          page: page,
          limit: limit,
        });
        if (response.status === 200) {
          setProductList(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching user list:", response);
        }
      } catch (error) {
        console.error("Error fetching user list:", error);
      }
      setLoading(false);
    })();
  }, []);

  const filterAction = async () => {
    try {
      const response: RootProductTableList = await productTableDetails({
        page: page,
        limit: limit,
        productName: productName?.length ? productName : undefined,
      });
      if (response.status === 200) {
        setProductList(response.data.results);
        setPagination(response.data.pagination);
      } else {
        console.error("Error fetching Product list:", response);
      }
    } catch (error: any) {
      toast.error(JSON.stringify(error));
      console.error("Error fetching Product list:", error);
    }
  };
  const nextAction = async () => {
    try {
      if (pagination?.next !== undefined) {
        setPage(pagination.next);
        console.log("Next arrow clicked. New skip value:", pagination.next);
        const response: RootProductTableList = await productTableDetails({
          page: pagination!.next!,
          limit: limit,
        });
        if (response.status === 200) {
          setProductList(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching user list:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  const previousAction = async () => {
    try {
      if (pagination?.previous !== undefined) {
        setPage(pagination.previous);
        const response: RootProductTableList = await productTableDetails({
          page: pagination!.previous!,
          limit: limit,
        });
        if (response.status === 200) {
          setProductList(response.data.results);
          setPagination(response.data.pagination);
        } else {
          console.error("Error fetching user list:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };
  return (
    <section className="sm:p-3 p-0">
      <div className="bg-white mb-3 p-3 rounded-lg flex items-center justify-between">
        <h6 className="font-Roboto text-[1.4rem] font-bold">Product List</h6>
      </div>
      <div>
        <Filter setProductName={setProductName} handleFilter={filterAction} />
      </div>
      {loading && (
        <div className=" flex justify-center items-center h-[60vh]">
          <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
        </div>
      )}

      {!loading && (
        <div className="bg-white p-4 rounded-md">
          {productList.length === 0 && <p>No Data Available </p>}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {productList.length > 0 &&
              productList.map((list, index) => {
                return (
                  <div key={index}>
                    <div className="border-[1px] rounded-md overflow-hidden hover:border-bluePostaam-600  border-gray-500 w-full  hover:shadow-md ">
                      <Link
                        to={`/productDetails/${list._id}`}
                        className="flex gap-3"
                      >
                        {list.images && list.images.length > 0 ? (
                          <img
                            src={list.images[0].image}
                            alt={list.productName}
                            className="w-24 h-24 border-r-[1px]"
                          />
                        ) : (
                          <img
                            src={postoomLogo}
                            alt={list.productName}
                            className="w-24 h-24 object-cover border-r-[1px]"
                          />
                        )}

                        <div className="flex flex-col leading-5 mt-1 productlist">
                          <p className="text-gray-800 font-bold text-[0.9rem] font-Roboto lowercase product_title">
                            {list.productName.length > 30
                              ? `${list.productName.slice(0, 30)}...`
                              : list.productName}
                          </p>

                          <p className="mt-1  leading-5 text-gray-600">
                            {list.subCategory}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
          <div>
            {pagination && (
              <Pagination
                nextAction={nextAction}
                previousAction={previousAction}
                total={pagination!.total}
                currentPage={page}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};
export default ProductList;
