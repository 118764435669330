import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductUpdate from "./ProductUpdate";
import { Button, Dialog, Spinner } from "@material-tailwind/react";
import Edit from "../../../assets/image/edit.png";
import { RxCross2 } from "react-icons/rx";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  ResultProductCategory,
  RootProductDetails,
  addProductApi,
  deleteProdcut,
  getProductCategory,
  getProductDetails,
} from "../../../utils/product";
const ProductDetails = () => {
  const [productInfo, setProductInfo] = useState<RootProductDetails>();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = useState(1);
  const [repeaterIndex, setRepeaterIndex] = useState<number | undefined>(
    undefined
  );
  const history = useNavigate();
  const [categories, setCategories] = useState<ResultProductCategory[]>();
  const [newSpecification, setnewSpecification] = useState(false);
  const [newAttribute, setNewAttribute] = useState(false);
  const [totalCommission, setTotalCommission] = useState<number | undefined>();
  const [affiliation, setAffiliationStatus] = useState<boolean>(false);
  useEffect(() => {
    const calculateTotalCommission = () => {
      if (productInfo && productInfo.results) {
        const standardCommission = getMainCategoryPercentage() ?? 0;
        const additionalCommission = productInfo.results.commission ?? 0;
        const totalCommissionValue = additionalCommission - standardCommission;
        setTotalCommission(totalCommissionValue);
      }
    };

    calculateTotalCommission();
  }, [productInfo]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
    setUpdateLoading(false);
  };
  useEffect(() => {
    (async () => {
      const response = await getProductDetails(id!);
      setLoading(false);
      setProductInfo(response);
      setAffiliationStatus(response.results.affiliation);
    })();

    setLoading(true);
  }, [updateLoading]);
  const handleDelete = async (_id: string) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await deleteProdcut(_id);
        toast.success("Product deleted successfully");
        history("/productList");
      } catch (error) {
        toast.error("Error deleting product");
        console.error("Error deleting product:", error);
      }
    }
  };
  useEffect(() => {
    (async () => {
      let data = await getProductCategory();
      setCategories(data.results);
    })();
  }, []);

  const getMainCategoryPercentage = () => {
    if (productInfo && categories) {
      const mainCategory = categories.find(
        (category) => category.name === productInfo.results.category
      );
      return mainCategory ? mainCategory.percentage : 0;
    }
  };
  const handleAddAttribute = () => {
    setStep(5);
    setNewAttribute(true);
    handleOpen();
  };
  const handleAddDescription = () => {
    setStep(4);
    setnewSpecification(true);
    handleOpen();
  };

  const handleAffiliationStatus = async () => {
    setAffiliationStatus((prev) => !prev);
    let formData = new FormData();
    formData.append("_id", id!);
    formData.append("affiliation", String(!affiliation));
    await addProductApi(formData);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row md:justify-between items-start bg-white gap-3 px-3 py-4 rounded-md shadow-sm">
        <p className="text-gray-800 text-[25px] font-[600] font-mulish text-gray-600">
          Product Details
        </p>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4  items-start md:items-center w-full md:w-auto">
          <Button
            className="bg-bluePostaam-600 rounded-md w-full sm:w-auto"
            onClick={handleAddAttribute}
          >
            Add Attribute
          </Button>
          <Button
            className="bg-bluePostaam-600 rounded-md w-full sm:w-auto"
            onClick={handleAddDescription}
          >
            Add Description
          </Button>
          <Button
            className="bg-red-500 rounded-md w-full sm:w-auto"
            onClick={() => handleDelete(productInfo?.results._id!)}
          >
            Delete
          </Button>

          <label className="inline-flex items-center cursor-pointer w-full sm:w-auto md:mt-3 sm:mt-0">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={affiliation}
              onChange={handleAffiliationStatus}
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bluePostaam-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Affiliation Status
            </span>
          </label>
        </div>
      </div>

      {loading && (
        <div className=" flex justify-center items-center h-[60vh]">
          <Spinner className="h-16 w-16 text-gray-700/50" color="blue" />
        </div>
      )}
      {!loading && (
        <div className="flex md:flex-row gap-6 flex-col">
          <div className="mt-7 flex-1">
            {/* Product Name to tags started */}
            <div className="bg-white w-full p-2 md:p-5 rounded-md shadow-sm leading-8 flex justify-between">
              <div className="flex w-full flex-col md:gap-3 gap-2">
                <h3 className="font-mulish text-gray-600 text-gray-800 text-[25px]">
                  {productInfo?.results.productName &&
                    productInfo.results.productName}
                </h3>
                {/* Product Brand */}
                <div className="flex flex-col md:flex-row md:items-center md:gap-2">
                  <span className="font-bold font-Roboto text-gray-700 w-full md:w-6/12">Product Brand</span>
                  <span className="font-mulish text-gray-600 text-gray-700">
                    {productInfo?.results.brand || "Not Available"}
                  </span>
                </div>

                {/* Weight */}
                <div className="flex flex-col md:flex-row md:items-center md:gap-2">
                  <span className="font-bold font-Roboto text-gray-700 w-full md:w-6/12">Weight</span>
                  <span className="font-mulish text-gray-600 text-gray-700">
                    {productInfo?.results.weight || "Not Available"}
                  </span>
                </div>

                {/* Quantity */}
                <div className="flex flex-col md:flex-row md:items-center md:gap-2">
                  <span className="font-bold font-Roboto text-gray-700 w-full md:w-6/12">Quantity</span>
                  <span className="font-mulish text-gray-600 text-gray-700">
                    {productInfo?.results.quantity || "Not Available"}
                  </span>
                </div>

                {/* Tags */}
                <div className="flex flex-col md:flex-row md:items-center md:gap-2">
                  <span className="font-bold font-Roboto text-gray-700 w-full md:w-6/12">Tags</span>
                  <div className="flex flex-wrap gap-3">
                    {productInfo?.results.tags.map((tag, index) => (
                      <div
                        className="bg-gray-200 p-1 rounded-md font-mulish text-gray-600 text-sm"
                        key={index}
                      >
                        #{tag.tagName || "Not Available"}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div
                className="cursor-pointer"
                onClick={() => {
                  handleOpen();
                  setStep(1);
                }}
              >
                <img
                  src={Edit}
                  alt="edit"
                  className="w-16 object-contain h-10"
                />
              </div>
            </div>
            {/* Product Name to tags ended */}

            {/* Budget section started */}
            <div className="bg-white p-2 md:p-5 rounded-md shadow-sm leading-8 flex justify-between mt-4">
              <div className="w-full">
                {/* Budget Title */}
                <h4 className="text-gray-700 text-[18px] font-semibold mb-2 md:mb-3">
                  Budget
                </h4>

                {/* Regular Price */}
                <div className="flex flex-col md:flex-row md:items-center md:gap-2">
                  <span className="font-bold font-Roboto text-gray-700 w-full md:w-6/12">
                    Regular Price:
                  </span>
                  <span className="font-mulish text-gray-600 text-gray-700">
                    {(productInfo?.results.price && `Rs. ${productInfo?.results.price}`) ||
                      "Not Available"}
                  </span>
                </div>

                {/* Discount */}
                <div className="flex flex-col md:flex-row md:items-center md:gap-2">
                  <span className="font-bold font-Roboto text-gray-700 w-full md:w-6/12">
                    Discount:
                  </span>
                  <span className="font-mulish text-gray-600 text-gray-700">
                    {(productInfo?.results.discountPercentage &&
                      `${productInfo?.results.discountPercentage}%`) ||
                      "Not Available"}
                  </span>
                </div>
              </div>

              {/* Edit Button */}
              <div
                onClick={() => {
                  handleOpen();
                  setStep(2);
                }}
                className="cursor-pointer"
              >
                <img src={Edit} alt="edit" className="w-16 object-contain h-10" />
              </div>
            </div>
            {/* Budget section ended */}

            {/* Categories section started */}
            <div className="bg-white p-2 md:p-5 rounded-md shadow-sm leading-8 flex flex-row justify-between mt-4">
              <div className="w-full">
                <h4 className="text-gray-700 text-[18px] font-semibold mb-2 md:mb-3">
                  Categories
                </h4>

                <ul className="w-full text-gray-700">
                  <li className="flex flex-col md:flex-row md:items-center md:gap-2">
                    <span className="font-bold font-Roboto w-full md:w-6/12">Main Category</span>
                    <span className="font-mulish text-gray-600">{productInfo?.results.category} ({getMainCategoryPercentage()}%)</span>
                  </li>
                  <li className="flex flex-col md:flex-row md:items-center md:gap-2">
                    <span className="font-bold font-Roboto w-full md:w-6/12">Sub Category</span>
                    <span className="font-mulish text-gray-600">{productInfo?.results.subCategory || "Not Available"}</span>
                  </li>
                  <li className="flex flex-col md:flex-row md:items-center md:gap-2">
                    <span className="font-bold font-Roboto w-full md:w-6/12">Third Category</span>
                    <span className="font-mulish text-gray-600">{productInfo?.results.thirdCategory || "Not Available"}</span>
                  </li>
                </ul>
              </div>

              {/* Edit Button */}
              <div
                onClick={() => {
                  handleOpen();
                  setStep(3);
                }}
                className="cursor-pointer flex justify-end md:justify-center mt-3 md:mt-0"
              >
                <img src={Edit} alt="edit" className="w-12 md:w-16 object-contain h-10" />
              </div>
            </div>


            {/* categories section Ended */}


            {/* Pormotion section started */}
            <div className="bg-white p-2 md:p-5 rounded-md shadow-sm leading-8 flex flex-row  justify-between mt-4">
              <div className="w-full">
                <h4 className="text-gray-700 text-[18px] font-semibold mb-2 md:mb-3">
                  Promotion
                </h4>
                <ul className="w-full text-gray-700">
                  <li className="flex flex-col md:flex-row md:items-center md:gap-2 ">
                    <span className="font-bold font-Roboto w-full md:w-6/12">Standard Commission (%)</span>
                    <span className="font-mulish text-gray-600">{getMainCategoryPercentage()}</span>
                  </li>
                  <li className="flex flex-col md:flex-row md:items-center md:gap-2  ">
                    <span className="font-bold font-Roboto w-full md:w-6/12">Additional Commission (%)</span>
                    <span className="font-mulish text-gray-600">{totalCommission}</span>
                  </li>
                  <li className="flex flex-col md:flex-row md:items-center md:gap-2  ">
                    <span className="font-bold font-Roboto w-full md:w-6/12">Total Commission (%)</span>
                    <span className="font-mulish text-gray-600">{productInfo?.results.commission}</span>
                  </li>
                </ul>
              </div>

              {/* Edit Button */}
              <div
                onClick={() => {
                  handleOpen();
                  setStep(6);
                }}
                className="cursor-pointer flex  mt-3 md:mt-0"
              >
                <img src={Edit} alt="edit" className="w-12 md:w-14 object-contain h-10" />
              </div>
            </div>

            {/* Pormotion section ended */}

            {/* Specifications section started */}
            <div className="mt-4 rounded-md shadow-sm leading-8 flex justify-between">
              <div className="w-full">
                <div className="bg-white p-2 md:p-5 py-2 w-full rounded-md shadow-sm leading-8 flex justify-between mb-1">
                  <h4 className="text-gray-700  text-[18px] font-semibold">
                    Specifications
                  </h4>
                </div>
                {productInfo?.results.specifications.map((spec, index) => (
                  <div
                    key={index}
                    className="bg-white p-2 md:p-5  w-full rounded-md shadow-sm leading-8 flex justify-between mb-4"
                  >
                    <div className="flex flex-col w-full">
                      <div className="flex justify-between items-center">
                        <p className="text-gray-700 text-lg font-semibold w-full">
                          {spec.title}
                        </p>
                        <div
                          onClick={() => {
                            setnewSpecification(false);
                            handleOpen();
                            setStep(4);
                            setRepeaterIndex(index);
                          }}
                          className="flex justify-end items-end cursor-pointer"
                        >
                          <img
                            src={Edit}
                            alt="edit"
                            className="w-16 object-contain h-10"
                          />
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: spec.description }}
                        style={{
                          color: "#92939a",
                          marginTop: "18px",
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Specifications section ended */}

            {/* Attributes section started */}
            <div className="mt-4 rounded-md shadow-sm leading-8 flex justify-between">
              <div className="w-full">
                <div className="bg-white p-2 md:p-5 py-2 w-full rounded-md shadow-sm leading-8 flex justify-between mb-1">
                  <h4 className="text-gray-700 text-[18px] font-semibold">
                    Attributes
                  </h4>
                </div>
                {productInfo?.results.attributes.map((spec, index) => (
                  <div
                    key={index}
                    className="bg-white p-2 md:p-5 w-full rounded-md shadow-sm leading-8 flex justify-between mb-4"
                  >
                    <div className="flex flex-col w-full">
                      <div className="flex justify-between items-center">
                        <p className="text-gray-700 text-lg font-semibold w-full">
                          {spec.key}
                        </p>
                        <div
                          onClick={() => {
                            setNewAttribute(false);
                            handleOpen();
                            setStep(5);
                            setRepeaterIndex(index);
                          }}
                          className="flex justify-end items-end cursor-pointer"
                        >
                          <img
                            src={Edit}
                            alt="edit"
                            className="w-16 object-contain h-10"
                          />
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: spec.value }}
                        style={{
                          color: "#92939a",
                          marginTop: "18px",
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Attributes section ended */}
          </div>

          {/* Gallery section started */}
          <div className=" w-full md:w-96    mt-4 md:mt-7">
            <div className="bg-white p-2 md:p-5 rounded-md shadow-sm leading-8">
              <div className="flex justify-between items-center">
                <h4 className="text-[20px] text-gray-700 font-[500]">
                  Gallery
                </h4>
                <div
                  onClick={() => {
                    handleOpen();
                    setStep(7);
                  }}
                >
                  <img
                    src={Edit}
                    alt="edit"
                    className="w-16 object-contain h-10"
                  />
                </div>
              </div>
              <div className="mt-2 grid grid-cols-2 justify-center items-center  gap-2 md:p-5">
                {productInfo?.results.images.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className="w-28 h-28 shadow-md "
                    >
                      <img
                        src={image.image}
                        alt="image"
                        className=" rounded-md object-cover"
                      />
                    </div>
                  );
                })}
                {productInfo?.results.videos.map((video, index) => {
                  return (
                    <div
                      key={index}
                      className="w-28 h-28 shadow-md overflow-hidden"
                    >
                      <video
                        src={video.videoUrl}
                        className="w-full h-full rounded-md object-cover"
                      ></video>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* Gallery section ended */}
        </div>
      )}
      <Dialog open={open} handler={handleOpen} dismiss={{ enabled: false }}>
        <div className="pr-3 flex justify-between items-center">
          <h3 className="font-Roboto text-xl font-bold popupHeader">
            Update Product
          </h3>
          <div
            onClick={handleOpen}
            className="p-2 rounded-full cursor-pointer bg-[#9E9E9E] text-white flex justify-center"
          >
            <RxCross2 size={25} />
          </div>
        </div>
        <ProductUpdate
          step={step}
          id={id!}
          handleOpen={handleOpen}
          repeaterIndex={repeaterIndex}
          newSpecification={newSpecification}
          setUpdateLoading={setUpdateLoading}
          newAttribute={newAttribute}
        />
      </Dialog>
    </>
  );
};
export default ProductDetails;
