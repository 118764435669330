import React, { useEffect, useState } from "react";
import "../../assets/style/Style.css";
import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import {
  ChevronRightIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { sidebarMenu } from "../../data";
import { Link } from "react-router-dom";
import loginFormLogo from "../../assets/image/loginFormLogo.png";


interface HeaderProps {
  sidebarVisible: boolean;
  toggleSidebar: () => void;
  setSidebarVisible: (value: boolean) => void;
}

export function Sidebar({
  toggleSidebar,
  sidebarVisible,
  setSidebarVisible,
}: HeaderProps) {
  const [open, setOpen] = useState<number | null>(null);
  const [activeLink, setActiveLink] = useState(0);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("token");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);




  return (
    <aside
      className={`fixed top-0 left-0 h-full z-50 bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${sidebarVisible ? "translate-x-0" : "-translate-x-full"
        } md:relative md:translate-x-0 md:w-64 md:max-w-[20rem] md:block`}
    >
      <Card className="h-full w-full rounded-none">
        <div className="flex justify-center flex-row items-center gap-4 px-4 py-5 sm:py-8 border-b-2">
          <img src={loginFormLogo} alt="logo" className="w-full object-contain" />
          <XMarkIcon className="h-7 w-7 text-Text block md:hidden" onClick={toggleSidebar} />
        </div>

        <div className="overflow-y-auto overflow-hidden scrollingbar">
          <List className="text-Text p-0">
            {sidebarMenu.map((item, index) => {
              // Check if item should be rendered
              if (item.id === 5 && (!user || user.user.id !== "61227e682b816f05aa85d448")) {
                return null; // Skip rendering this item if the user ID does not match
              }

              return (
                <Accordion
                  key={index}
                  onClick={() => setActiveLink(index)}
                  className={`rounded-none ${index === activeLink ? "bg-white" : "bg-transparent"}`}
                  open={open === index}
                  icon={
                    item.subtitle && (
                      <ChevronDownIcon
                        className={`mx-auto h-5 w-5 transition-transform ${open === index ? "rotate-180" : ""
                          } ${index === activeLink ? "text-indigo-700" : "text-Text"}`}
                      />
                    )
                  }
                >
                  <div key={item.id} className="mt-0 mb-0">
                    <Link
                      to={item.slux!}
                      onClick={() => {
                        if (item.slux) {
                          setSidebarVisible(false);
                        }
                      }}
                    >
                      <ListItem className="p-0 rounded-none" selected={open === index}>
                        <AccordionHeader onClick={() => setOpen(open === index ? null : index)}
                          className="border-b-0 p-3 rounded-none hover:bg-white">
                          <ListItemPrefix className={`${index === activeLink ? "text-sidebarIcon" : "text-Text"}`}>
                            <item.icon />
                          </ListItemPrefix>
                          <div className="mr-auto py-1 w-full text-[1rem] sidebar font-[500]">
                            <div className={`${index === activeLink ? "text-sidebarIcon" : "text-Text"}`}>
                              {item.name}
                            </div>
                          </div>
                        </AccordionHeader>
                      </ListItem>
                    </Link>

                    {/* Render submenus if available */}
                    {item.subtitle && (
                      <AccordionBody className="bg-gray-100 mt-0 mb-0 p-0">
                        <List className="p-0 gap-0">
                          {item.subtitle.map((subtitleItem, subtitleIndex) => (
                            <Link
                              to={subtitleItem.slux!}
                              key={subtitleIndex}
                              onClick={() => setSidebarVisible(false)}
                            >
                              <ListItem className="border-b-2 text-Text border-gray-200 rounded-none mb-0 mt-0 h-auto">
                                <ListItemPrefix>
                                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                {subtitleItem.name1}
                              </ListItem>
                            </Link>
                          ))}
                        </List>
                      </AccordionBody>
                    )}
                  </div>
                </Accordion>
              );
            })}
          </List>
        </div>
      </Card>
    </aside>
  );
}
