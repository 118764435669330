import React, { useEffect, useState } from "react";
import { Dialog } from "@material-tailwind/react";
import { RxCross2 } from "react-icons/rx";
import {
  RootBusinessProfile,
  SubBusiness,
  deleteSubBusiness,
  getBusinessProfile,
} from "../../utils/business";
import Edit from "../../assets/image/edit.png";
import SubBusinessEdit from "../forms/accountandsetting/SubBusinessEdit";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";

const SubBusinessList = () => {
  const [open, setOpen] = useState(false);
  const [businessProfile, setBusinessProfile] = useState<RootBusinessProfile>();
  const [subBusinessProfile, setSubBusinessProfile] = useState<SubBusiness>();
  const [size, setSize] = React.useState(null);
  const [refetching, setRefetching] = useState(false);

  const handleOpen = (value: any) => {
    setSize(value);
    setRefetching(!refetching);
  };

  useEffect(() => {
    (async () => {
      let data = await getBusinessProfile();
      setBusinessProfile(data);
    })();
  }, [refetching]);

  const handleDelete = async (_id: string) => {
    if (window.confirm("Are you sure you want to delete this sub business?")) {
      try {
        const del = await deleteSubBusiness(_id);
        toast.success(del.message);
        setBusinessProfile((prevProfile) => {
          if (prevProfile && del.status === 200) {
            const updatedSubBusinesses = prevProfile.subBusinesses.filter(
              (subBusiness) => subBusiness._id !== _id
            );
            return { ...prevProfile, subBusinesses: updatedSubBusinesses };
          }
          return prevProfile;
        });
      } catch (error) {
        toast.error("Error deleting sub business");
        console.log(error);
      }
    }
  };

  return (
    <section>
      {businessProfile?.subBusinesses.map((subBusiness, index) => {
        return (
          <div className="mt-10" key={index}>
            <div className="bg-white w-full box-shadow p-5 rounded-lg flex justify-between space-x-5">
              <div className="w-full">
                <div className="flex justify-between items-center rounded-md ">
                  <h4 className="SectionHeader">Business Information</h4>
                  <div className="flex gap-2 flex-row">
                    <button
                      className="bg-transparent rounded-full border-[1px] shadow-none w-10 h-10 border-gray-400 flex justify-center items-center"
                      onClick={() => handleDelete(subBusiness._id)}
                    >
                      <AiFillDelete size={25} color="red" />
                    </button>
                    <div
                      onClick={() => {
                        handleOpen("xl");
                        setSubBusinessProfile(subBusiness);
                      }}
                    >
                      <img
                        src={Edit}
                        alt="edit"
                       className="w-10 h-10 object-contain cursor-pointer"
                      />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                  <p className="font-bold text-gray-700">Legal Name</p>
                  <p>{subBusiness.businessName || "Not Available"}</p>

                  <p className="font-bold text-gray-700">Owner</p>
                  <p>{subBusiness.ownerName || "Not Available"}</p>

                  <p className="font-bold text-gray-700">Business Category</p>
                  <p className="flex gap-2 flex-wrap">
                    {subBusiness.categories.map((category, index) => (
                      <span key={index} className=" ">
                        {category.name || "Not Available"}
                      </span>
                    ))}
                  </p>

                  <p className="font-bold text-gray-700">Address</p>
                  <p>{subBusiness.address || "Not Available"}</p>

                  <p className="font-bold text-gray-700">Region</p>
                  <p>{subBusiness.region || "Not Available"}</p>

                  <p className="font-bold text-gray-700">Zone</p>
                  <p>{subBusiness.zone || "Not Available"}</p>

                  <p className="font-bold text-gray-700">Country</p>
                  <p>{subBusiness.country || "Not Available"}</p>

                  <p className="font-bold text-gray-700">Area</p>
                  <p>{subBusiness.area || "Not Available"}</p>

                  <p className="font-bold text-gray-700">Person in Charge</p>
                  <p>{subBusiness.personInChargeName || "Not Available"}</p>

                  <p className="font-bold text-gray-700">
                    Person in Charge Email
                  </p>
                  <p>{subBusiness.personInChargeEmail || "Not Available"}</p>

                  <p className="font-bold text-gray-700">
                    Person in Charge Phone
                  </p>
                  <p>{subBusiness.personInChargePhone || "Not Available"}</p>

                  <p className="font-bold text-gray-700">Business PAN/VAT</p>
                  <p>{subBusiness.registrationNumber || "Not Available"}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <Dialog
        open={size === "xl"}
        size={size || "xl"}
        handler={() => setOpen(false)}
      >
        <div
          onClick={handleOpen}
          className="flex justify-end items-center align-middle px-3 py-3.5 cursor-pointer absolute right-0 bg-gray-500 rounded-full text-white h-11 w-11 m-4"
        >
          <RxCross2 size={24} />
        </div>
        <SubBusinessEdit
          businessProfile={subBusinessProfile!}
          handleOpen={handleOpen}
        />
      </Dialog>
    </section>
  );
};

export default SubBusinessList;
