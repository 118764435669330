import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ProductList from "./components/tables/ProductList";

export default function Test() {
  const data = [
    {
      label: "Product Details",
      value: "productDetails",
      desc: ProductList,
    },
    {
      label: "Affiliated Post",
      value: "affiliatedPost",
      desc: ProductList,
    },
  ];

  return (
    <div>
      <Tabs value="productDetails">
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab key={value} value={value}>
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({ value, desc: Desc }) => (
            <TabPanel key={value} value={value}>
              <Desc />
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
}
