import axios from "axios";
import { baseUrl } from ".";

export interface Root {
  status: Number;
  data: {
    message: string;
    results: Results;
  };
}




interface Data{
// info:string
}

interface Results{
    // info:string
    }


export const topUpApi = async (data:Data): Promise<Root> => {
  try {
    const res = await axios.post(baseUrl + "business/login", data);
    return res as Root;
  } catch (error: any) {
    console.log("Error in Login", error);
    return error.response as Root;
  }
};
