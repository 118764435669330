import { Outlet } from "react-router-dom";
import { Sidebar } from "./menu/SideBar";
import Header from "./menu/Header";
import { useState } from "react";

const Layout = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false); // State to manage sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div className="flex flex-row h-full w-full bg-[#F7F8FC]">
      <div>
        <Sidebar
          sidebarVisible={sidebarVisible}
          toggleSidebar={toggleSidebar}
          setSidebarVisible={setSidebarVisible}
        />
      </div>
      <div className=" p-2 md:p-4 w-full overflow-y-auto flex-1">
        <div className="py-3 sm:px-3 p-1  md:mb-5  flex w-full  ">
          <Header toggleSidebar={toggleSidebar} />
        </div>
        {<Outlet />}
      </div>
    </div>
  );
};

export default Layout;
