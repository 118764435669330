import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
    const storedUser = localStorage.getItem("token");
    const user = storedUser ? JSON.parse(storedUser) : null;
    const isAuthorized = user && user.user.id === "61227e682b816f05aa85d448";
    return isAuthorized ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
