import { useState } from "react";
import { Button, Spinner } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { ForgetPws } from "../../utils/user";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
type FormValue = {
  email: string;
};
const ForgetPassword = () => {
  const router = useNavigate();
  const [isFocus, setIsFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<FormValue>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onsubmit = async (data: FormValue) => {
    setLoading(true);
    const response = await ForgetPws(data);
    if (response.status === 200) {
      toast.success("New OTP Code Has Been Sent For Forget Password");
      router(`/forgetOtpPws?email=${data.email}`);
    } else {
      toast.error(response.data.message);
    }
    setLoading(false);
  };

  const handleBack = () => {
    router(-1);
  }


  return (
    <section className="flex justify-center items-center w-full  h-screen login-background">
      <div className="bg-white pb-8 overflow-y-auto login-shadow  w-[95%] sm:w-400 overflow-hidden rounded-md px-5">
        <ArrowLeftIcon className="text-Text  w-6 h-6 my-4  cursor-pointer" onClick={handleBack} />

        <h3 className="text-4xl font-bold text-bluePostaam-550 text-left">
          Forget Password
        </h3>
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="mt-3 w-full space-y-2">
            <label
              htmlFor="email"
              className="rounded-md text-Text font-[500] outline-none w-full font-mulish"
            >
              Email<span className="text-red-500">*</span>:
            </label>
            <div
              className={`w-full rounded-md custom-input ${errors.email
                ? "focus:border-red-500 focus:ring-red-500 border-red-500 border-2"
                : isFocus
                  ? "focus:border-indigo-500 focus:ring-border-500 border-indigo-500 border-2"
                  : ""
                }`}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            >
              <input
                type="text"
                id="email"
                className="p-5 w-full rounded-md outline-none"
                {...register("email", {
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid Email Address",
                  },
                  required: "Email is required*",
                })}
              />
            </div>
            <p className="text-red-900 font-mulish">{errors.email?.message}</p>
          </div>

          <div className="w-full mt-5">
            {!loading && (
              <Button
                type="submit"
                className="login-bg  w-full py-5 font-Roboto font-bold rounded-md  -tracking-tighter text-base capitalize"
              >
                Reset My Password
              </Button>
            )}
            {loading && (
              <div className="flex items-center gap-2 login-bg text-white w-full justify-center py-5 tracking-wider text-base outline-none">
                <Spinner className="h-4 w-4" />
                <p>Loading</p>
              </div>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default ForgetPassword;
