import { Button } from "@material-tailwind/react";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";

interface FilterProps {
  setSearch: (search: string | undefined) => void;
  setTypeOfOrder: (typeoforder: string | undefined) => void;
  setPaymentType: (paymentType: string | undefined) => void;
  setPaymentStatus: (paymentStatus: boolean | undefined) => void;
  handelFilter: () => void;
}

const OrderFilter = ({
  setPaymentStatus,
  setPaymentType,
  setTypeOfOrder,
  setSearch,
  handelFilter,
}: FilterProps) => {
  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState<string>("All");
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const handlePaymentStatusChange = (e: any) => {
    const value = e.target.value;
    setSelectedPaymentStatus(value);
    setPaymentStatus(
      value === "All" ? undefined : value === "Paid" ? true : false
    );
  };

  return (
    <section>
      {/* Main Filter Row */}
      <div className="p-2 rounded-md shadow-sm mt-3 flex flex-wrap sm:flex-nowrap gap-2 bg-white">
        {/* Search Bar */}
        <div className="w-full sm:w-auto flex-1">
          <input
            type="text"
            placeholder="Search"
            className="py-[0.6rem] px-2 border-2 outline-none rounded-md font-Roboto w-full"
            onInput={(e) => setSearch(e.currentTarget.value)}
          />
        </div>

        {/* Payment Type */}
        <div className="w-full sm:w-[18%] hidden sm:block">
          <select
            className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
            onChange={(e) => setPaymentType(e.target.value)}
          >
            <option value="" disabled selected>
              Select Payment Method
            </option>
            <option value="All">All</option>
            <option value="Cash On Delivery">Cash On Delivery</option>
            <option value="Khalti">Khalti</option>
          </select>
        </div>

        {/* Payment Status */}
        <div className="w-full sm:w-[18%] hidden sm:block">
          <select
            className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
            value={selectedPaymentStatus}
            onChange={handlePaymentStatusChange}
          >
            <option value="All">All</option>
            <option value="Paid">Paid</option>
            <option value="Not Paid">Not Paid</option>
          </select>
        </div>

        {/* Order Type */}
        <div className="w-full sm:w-[18%] hidden sm:block">
          <select
            onChange={(e) => setTypeOfOrder(e.target.value)}
            className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
          >
            <option value="" disabled selected>
              Select Status
            </option>
            <option value="All">All</option>
            <option value="Pending">Pending</option>
            <option value="Processing">Processing</option>
            <option value="Ready To Ship">Ready To Ship</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Completed">Completed</option>
            <option value="Return">Return</option>
            <option value="Exchange">Exchange</option>
          </select>
        </div>

        {/* Search Button */}
        <div className="w-full sm:w-auto">
          <Button
            className="bg-bluePostaam-600 w-full sm:w-auto h-[45.8px] rounded-[5px] lg:w-full hover:shadow-none font-Roboto flex justify-center items-center"
            onClick={handelFilter}
          >
            <FiSearch size={23} />
          </Button>
        </div>

        {/* Add Filter Button for Small Screens */}
        <div className="sm:hidden w-full">
          <Button
            className="bg-bluePostaam-600 text-white w-full h-[45.8px] rounded-[5px] font-Roboto flex justify-center items-center"
            onClick={() => setShowFilters(!showFilters)}
          >
            <span className="text-sm ">Add Filter</span>
            <IoIosArrowDown size={20} />
          </Button>
        </div>
      </div>

      {/* Filters (Only for Small Screens) */}
      {showFilters && (
        <div className="sm:hidden flex flex-col gap-2 mt-2">
          {/* Payment Type */}
          <div className="w-full">
            <select
              className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
              onChange={(e) => setPaymentType(e.target.value)}
            >
              <option value="" disabled selected>
                Select Payment Method
              </option>
              <option value="All">All</option>
              <option value="Cash On Delivery">Cash On Delivery</option>
              <option value="Khalti">Khalti</option>
            </select>
          </div>

          {/* Payment Status */}
          <div className="w-full">
            <select
              className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
              value={selectedPaymentStatus}
              onChange={handlePaymentStatusChange}
            >
              <option value="All">All</option>
              <option value="Paid">Paid</option>
              <option value="Not Paid">Not Paid</option>
            </select>
          </div>

          {/* Order Type */}
          <div className="w-full">
            <select
              onChange={(e) => setTypeOfOrder(e.target.value)}
              className="border-2 py-[0.82rem] outline-none w-full px-2 rounded-md text-[0.84rem]"
            >
              <option value="" disabled selected>
                Select Status
              </option>
              <option value="All">All</option>
              <option value="Pending">Pending</option>
              <option value="Processing">Processing</option>
              <option value="Ready To Ship">Ready To Ship</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Completed">Completed</option>
              <option value="Return">Return</option>
              <option value="Exchange">Exchange</option>
            </select>
          </div>
        </div>
      )}
    </section>
  );
};

export default OrderFilter;
