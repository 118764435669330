import React, { useState } from "react";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { Popup } from "../../components/Popup";
import Pagination from "../../components/Pagination";

interface TransactionProps {
  title: string;
  amount: number;
  date: string;
  paymentStatus: "pending" | "completed";
}
//dummydata for pagination
const transactionList: TransactionProps[] = [
  {
    title: "test transaction",
    amount: 3000,
    date: "2024/05/05",
    paymentStatus: "completed",
  },
  {
    title: "test transaction",
    amount: 2000,
    date: "2024/05/05",
    paymentStatus: "pending",
  },
  {
    title: "test transaction",
    amount: 3000,
    date: "2024/05/05",
    paymentStatus: "completed",
  },
  {
    title: "test transaction",
    amount: 2000,
    date: "2024/05/05",
    paymentStatus: "pending",
  },
  {
    title: "test transaction",
    amount: 3000,
    date: "2024/05/05",
    paymentStatus: "completed",
  },
  {
    title: "test transaction",
    amount: 2000,
    date: "2024/05/05",
    paymentStatus: "pending",
  },
  {
    title: "test transaction",
    amount: 3000,
    date: "2024/05/05",
    paymentStatus: "completed",
  },
  {
    title: "test transaction",
    amount: 2000,
    date: "2024/05/05",
    paymentStatus: "pending",
  },
  {
    title: "test transaction",
    amount: 3000,
    date: "2024/05/05",
    paymentStatus: "completed",
  },
  {
    title: "test transaction",
    amount: 2000,
    date: "2024/05/05",
    paymentStatus: "pending",
  },
  {
    title: "test transaction",
    amount: 3000,
    date: "2024/05/05",
    paymentStatus: "completed",
  },

  {
    title: "test transaction",
    amount: 2000,
    date: "2024/05/05",
    paymentStatus: "pending",
  },

];

function Transactions() {
  const TABLE_HEAD = ["S/N", "Title", "Amount", "Date", "Payment Status"];
  const [loading, setLoading] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = transactionList.length; // Example total items


  const paginatedTransactionList = transactionList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const nextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="w-full px-4">
      <div className="flex justify-between items-center">
        <Typography variant="h4" className="font-bold mb-4">
          Balance
        </Typography>
        <Popup page="transactions" />
      </div>

      <div className="w-full overflow-x-auto">
        <Card className="h-full w-full mt-4 ">
          <CardBody className="overflow-auto px-0 pt-0">
            {" "}
            {/* Use overflow-auto */}
            <table className="w-full min-w-[600px] table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b-2 border-blue-gray-100 p-4"
                    >
                      <Typography
                        variant="small"
                        className="font-semibold leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>

              {!loading && (
                <tbody>
                  {paginatedTransactionList.map((ele, index) => {
                    const isLast = index === transactionList.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";
                    return (
                      <tr key={index} className="hover:bg-blue-gray-50/30">
                        <td className={classes}>
                          <Typography variant="small" className="font-normal">
                            {index + 1}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-normal capitalize"
                          >
                            {ele.title}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography variant="small" className="font-normal">
                            {ele.amount}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography variant="small" className="font-normal">
                            {ele.date}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className={`font-medium capitalize ${ele.paymentStatus === "pending"
                              ? "text-yellow-800"
                              : "text-green-600"
                              }`}
                          >
                            {ele.paymentStatus}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}

              {(loading || transactionList.length) === 0 && (
                <tbody>
                  <tr>
                    <td colSpan={7}>
                      <div className="flex justify-center items-center p-8">
                        {loading ? (
                          <div className="w-10 h-10 border-4 border-t-blue-600 rounded-full animate-spin" />
                        ) : (
                          <Typography variant="small" className="text-gray-500">
                            No transactions found
                          </Typography>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>

          </CardBody>
          {!loading && transactionList.length > 10 &&
            <Pagination
              nextAction={nextPage}
              previousAction={prevPage}
              currentPage={currentPage}
              total={totalItems}
            />
          }


        </Card>

      </div>
    </div>
  );
}

export default Transactions;
