import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  Messaging,
  getToken,
  onMessage,
  isSupported,
  MessagePayload
} from "firebase/messaging";
import { notificationRegisterApi } from "./notificationApi";

// Add the NotificationPayload interface
interface NotificationPayload {
  title: string;
  body: string;
  timestamp: string;
}


// vapidKey:"BKmhHjruFcLPLOSx5qc9qL5d_gEheEL96l6DmKO_JswvarykTOQSi8Yo3eClPqlUD2v28MoBdTACpzXyDopoR1g",
// const firebaseConfig = {
//   apiKey: "AIzaSyBJ3BBZSuYUVqiuAOFxzoOwkdnOs7LnJak",
//   authDomain: "testprject-f787e.firebaseapp.com",
//   projectId: "testprject-f787e",
//   storageBucket: "testprject-f787e.firebasestorage.app",
//   messagingSenderId: "996822803088",
//   appId: "1:996822803088:web:d7d7ccd5914c0981732e15",
//   measurementId: "G-Q9HY47RWQC"
// };


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

// Initialize messaging with definite assignment
let messaging: Messaging;

const initializeMessaging = async () => {
  try {
    if (await isSupported()) {
      messaging = getMessaging(app);
      return true;
    }
    return false;
  } catch (err) {
    console.error('Firebase messaging not supported:', err);
    return false;
  }
};

export const useDesktopNotification = () => {
  const [token, setToken] = useState<string | null>(null);
  const [isMessagingSupported, setIsMessagingSupported] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      const supported = await initializeMessaging();
      setIsMessagingSupported(supported);
    };
    init();
  }, []);

  useEffect(() => {
    if (!isMessagingSupported || !messaging) return;

    const generateToken = async () => {
      try {
        const permission = await Notification.requestPermission();
        console.log("Notification permission:", permission);

        if (permission === "granted" && messaging) {  // Add messaging check here
          const token = await getToken(messaging, {

            // vapidKey:"BKmhHjruFcLPLOSx5qc9qL5d_gEheEL96l6DmKO_JswvarykTOQSi8Yo3eClPqlUD2v28MoBdTACpzXyDopoR1g",

            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
          });
          console.log("Token received:", token);
          if (token) {
            setToken(token);
          }
        } else {
          console.error("Permission not granted for notifications.");
        }
      } catch (error) {
        console.error("Error generating token:", error);
      }
    };
    generateToken();
  }, [isMessagingSupported]);

  useEffect(() => {
    if (!token) return;

    const registerToken = async () => {
      try {
        const res = await notificationRegisterApi({
          deviceToken: token,
          notificationFor: "user",
        });
        console.log("Token successfully registered", res);
      } catch (err) {
        console.error("Error registering token:", err);
      }
    };
    registerToken();
  }, [token]);

  useEffect(() => {
    if (!isMessagingSupported || !messaging) return;

    const unsubscribe = onMessage(messaging, (payload: MessagePayload) => {
      console.log("Message received: ", payload);
      const notificationPayload: NotificationPayload = {
        title: payload.notification?.title || "",
        body: payload.notification?.body || "",
        timestamp: new Date().toString(),
      };

      if (Notification.permission === "granted") {
        new Notification(notificationPayload.title, {
          body: notificationPayload.body,
          icon: "/logo.png",
        });
      }
    });

    return () => unsubscribe();
  }, [isMessagingSupported]);
};