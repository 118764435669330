import axios from "axios";
import { baseUrl } from ".";

interface NotificationRegister {
  deviceToken: string;
  notificationFor: string;
}

export const notificationRegisterApi = async (data: NotificationRegister) => {
  try {
    const res = await axios.post(baseUrl + "notification/register", data, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res;
  } catch (error: any) {
    console.log("Error", error);
    return error.response;
  }
};



export interface  NotificationsData{
  createdAt: Date | string,
  _id:string,
  title:string,
  body: string,
  image: string,
  notificationFor?:string,
  notificationLink?: string,
}

interface getAllNotificationApiResponse{
  message?: string,
results: NotificationsData[] 
}

export const getAllNotificationApi = async () => {
  try {
    const res = await axios.get(baseUrl + "notification/viewsAll",  {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data as getAllNotificationApiResponse;
  } catch (error: any) {
    console.log("Error", error);
    return error.response;
  }
};



export const deleteNotificationByIdApi = async (id: string) => {
  try {
    const res = await axios.get(baseUrl + "notification/viewsAll",  {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data 
  } catch (error: any) {
    console.log("Error", error);
    return error.response;
  }
};



export const deleteAllNotificationApi = async (id: string) => {
  try {
    const res = await axios.get(baseUrl + "notification/removeAllNotification",  {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")!).token,
      },
    });
    return res.data 
  } catch (error: any) {
    console.log("Error", error);
    return error.response;
  }
};

