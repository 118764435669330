import { Card, CardBody } from "@material-tailwind/react";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { orderList, RootOrder, OrderResult } from "../../utils/sales";

interface ChartConfig {
  type: "bar";
  height: number;
  series: {
    name: string;
    data: number[];
  }[];
  options: any;
}

const BarChart = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderResult[]>([]);
  const [pagination, setPagination] = useState<number>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  const [monthlyOrderCounts, setMonthlyOrderCounts] = useState<number[]>([]);
  const [monthCategories, setMonthCategories] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res: RootOrder = await orderList({ page, limit });
        if (res.status === 200) {
          setOrderData(res.data.results);
          setPagination(res.data.pagination.total);

          // Process monthly order counts
          const orderCounts = res.data.results.reduce((acc: Record<string, number>, order: OrderResult) => {
            const date = new Date(order.createdAt);
            const monthYear = date.toLocaleString("en-US", { month: "short", year: "numeric" });
            acc[monthYear] = (acc[monthYear] || 0) + 1;
            return acc;
          }, {});

          // Sort and prepare data for chart
          const sortedMonths = Object.keys(orderCounts).sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime()
          );
          setMonthCategories(sortedMonths);
          setMonthlyOrderCounts(sortedMonths.map(month => orderCounts[month]));

        } else {
          console.error("Error fetching order list:", res);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [page, limit]);

  const chartConfig: ChartConfig = {
    type: "bar",
    height: 240,
    series: [
      {
        name: "Total Orders",
        data: monthlyOrderCounts,
      },
    ],
    options: {
      chart: {
        toolbar: { show: false },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#5235CD"],
      plotOptions: {
        bar: {
          columnWidth: "30%",
          borderRadius: 3,
        },
      },
      xaxis: {
        categories: monthCategories,
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      grid: {
        borderColor: "#dddddd",
        strokeDashArray: 5,
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  return (
    <section className="px-4 mt-6">
      <Card>
        <CardBody className="px-2 pb-0">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <Chart {...chartConfig} />
          )}
        </CardBody>
      </Card>
    </section>
  );
};

export default BarChart;
