import { Button, Card, Spinner } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import ImageVideoPreview from "./components/ImageVideoPreview";
import TagsPreview from "./components/TagsPreview";
import {
  RootBusinessProfile,
  getBusinessProfile,
} from "../../../utils/business";
import {
  ResultProductCategory,
  RootCategory,
  RootProductDetails,
  addProductApi,
  getProductCategory,
  getProductDetails,
} from "../../../utils/product";
export type FormValue = {
  productName: string;
  slug: string;
  price: number;
  category: string;
  subCategory: string;
  descriptionTitle: string;
  quantity: string;
  thirdCategory: string;
  weight: string;
  description: string;
  commission: number;
  tags: string[];
  videos?: File[];
  brand: string;
  additionalCommission: number;
  images?: File[];
  discountPercentage: number;
  specifications: Specification[];
  attributes: Attribute[];
};

type Specification = {
  title: string;
  description: string;
};
type Attribute = {
  key: string;
  value: string;
};
interface Props {
  step: number;
  id: string;
  handleOpen: () => void;
  repeaterIndex?: number;
  setUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
  newSpecification: Boolean;
  newAttribute: Boolean;
}

const ProductUpdate = ({
  step,
  id,
  handleOpen,
  repeaterIndex,
  setUpdateLoading,
  newSpecification,
  newAttribute,
}: Props) => {
  const [tags, setTags] = useState<string[]>([]);
  const [_id, set_Id] = useState<string>(id!);
  const [product, setProduct] = useState<RootProductDetails>();
  const [loadingP, setLoadingP] = useState(true);

  const [additional, setAdditional] = useState("");
  const [total, setTotal] = useState("");
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
  const [selectedVidoFiles, setSelectedVideoFiles] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<{ image: string; _id: string }[]>(
    []
  );
  const [tagUrls, setTagUrls] = useState<{ tagName: string; _id: string }[]>(
    []
  );

  const [videoUrls, setVideoUrls] = useState<
    { videoUrl: string; _id: string }[]
  >([]);
  const [deleteImages, setDeleteImages] = useState<string[]>([]);
  const [deleteTags, setDeleteTags] = useState<string[]>([]);

  const [deleteVideos, setDeleteVideos] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<ResultProductCategory[]>();
  const [selectCategory, setSelectCategory] = useState<string>("");
  const [selectSubCategory, setSelectSubCategory] = useState<string>("");
  const [selectThirdCategory, setSelectThirdCategory] = useState<string>("");
  const [specifications, setSpecifications] = useState<Specification[]>([
    { title: "", description: "" },
  ]);
  const [attributes, setAttributes] = useState<Attribute[]>([
    { key: "", value: "" },
  ]);

  const [newSpecifications, setNewSpecifications] = useState<Specification[]>([
    { title: "", description: "" },
  ]);
  const [newAttributes, setnewAttributes] = useState<Attribute[]>([
    { key: "", value: "" },
  ]);
  const [businessinfo, setBusinessInfo] = useState<RootBusinessProfile>();
  const [categories, setCategories] = useState<ResultProductCategory[]>();

  const handleSelectCategory = (value: string) => {
    setSelectCategory(value);
    setValue("subCategory", " ");
    setValue("thirdCategory", " ");
  };
  const handleSubCategory = (value: string) => {
    setSelectSubCategory(value);
    setValue("thirdCategory", " ");
  };
  const handleThiredCategory = (value: string) => {
    setSelectThirdCategory(value);
  };

  const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (imageUrls.length < 5 && videoUrls.length < 2) {
      const files = event.target.files;
      if (files) {
        if (files?.length + selectedImageFiles.length <= 5) {
          const newImages: File[] = [];
          const newVideos: File[] = [];
          for (let i = 0; i < files.length; i++) {
            if (files[i].type.split("/")[0] === "image") {
              newImages.push(files[i]);
            } else if (files[i].type.split("/")[0] === "video") {
              newVideos.push(files[i]);
            }
          }
          newImages.length > 0 &&
            setSelectedImageFiles((prevImages) => [
              ...prevImages,
              ...newImages,
            ]);
          newVideos.length > 0 &&
            setSelectedVideoFiles((prevVideos) => [
              ...prevVideos,
              ...newVideos,
            ]);
        }
      } else
        alert(
          "Your are already uploaded maximum number of videos and images !"
        );
    } else {
      alert("You can only select up to five images.");
    }
  };

  const handlePreviewImagesDelete = (index: number) => {
    const datas = selectedImageFiles;
    datas.splice(index, 1);
    setSelectedImageFiles([...datas]);
  };
  const handlePreviewVideoDelete = (index: number) => {
    const datas = selectedVidoFiles;
    datas.splice(index, 1);
    setSelectedVideoFiles([...datas]);
  };
  const handleImageUrlDelete = (index: number) => {
    const datas = imageUrls;

    if (datas[index]) {
      setDeleteImages([...deleteImages, datas[index]._id]);
    }
    datas.splice(index, 1);
    setImageUrls([...datas]);
  };

  const handlePreviewTagDelete = (index: number) => {
    const datas = tags;
    datas.splice(index, 1);
    setTags([...datas]);
  };

  const handleTagUrlDelete = (index: number) => {
    const datas = tagUrls;

    if (datas[index]) {
      setDeleteTags([...deleteTags, datas[index]._id]);
    }
    datas.splice(index, 1);
    setTagUrls([...datas]);
  };

  const handleVideoUrlDelete = (index: number) => {
    const datas = videoUrls;

    if (datas[index]) {
      setDeleteVideos([...deleteVideos, datas[index]._id]);
    }
    datas.splice(index, 1);
    setVideoUrls([...datas]);
  };
  const handleCommission = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const standardCommission =
      (selectCategory &&
        category?.find((cata: { name: string }) => cata.name === selectCategory)
          ?.percentage) ||
      0;
    const additionalCommission = parseFloat(value) || 0;
    const totalCommission = standardCommission + additionalCommission;
    setAdditional(value);
    setTotal(totalCommission.toString());
  };

  const handleTags = (data: string) => {
    if (data.trim() !== "") {
      setTags((prev) => [...prev, data.trim()]);
      form.setValue("tags", [...tags, data.trim()]);
    }
  };
  const form = useForm<FormValue>();
  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;

  useEffect(() => {
    (async () => {
      const response = await getProductDetails(id!);
      setProduct(response);

      let data: RootCategory = await getProductCategory();

      setValue("productName", response.results.productName!);
      setValue("brand", response.results.brand);
      setValue("slug", response.results.slug!);
      setValue("weight", response.results.weight!);
      response.results.quantity &&
        setValue("quantity", response.results.quantity!.toString());
      setValue("price", response.results.price!);
      setValue("discountPercentage", response.results.discountPercentage!);
      setSpecifications(response.results.specifications!);
      setAttributes(response.results.attributes!);
      setSelectCategory(response.results.category!);
      setSelectSubCategory(response.results.subCategory!);
      setSelectThirdCategory(response.results.thirdCategory!);
      response.results.commission &&
        setAdditional(response.results.commission!.toString());
      response.results.commission &&
        setTotal(response.results.commission!.toString());
      setCategory(data.results);
      setImageUrls(response.results.images);
      setVideoUrls(response.results.videos);
      setTagUrls(response.results.tags);
      setLoadingP(false);
    })();
  }, []);

  const onsubmit = async (data: FormValue) => {
    if (!loading) {
      setLoading(true);
      try {
        const formData: FormData = new FormData();
        _id != "" && formData.append("_id", _id);
        formData.append("productName", data.productName);
        formData.append("slug", data.slug);

        data.price && formData.append("price", data.price.toString());
        data.discountPercentage &&
          formData.append(
            "discountPercentage",
            data.discountPercentage.toString()
          );
        formData.append("commission", total.toString());
        data.category && formData.append("category", data.category);

        data.subCategory && formData.append("subCategory", data.subCategory);

        data.thirdCategory &&
          formData.append("thirdCategory", data.thirdCategory);

        formData.append("brand", data.brand);
        formData.append("weight", data.weight);
        formData.append("quantity", data.quantity);

        deleteImages.length > 0 &&
          formData.append("deleteImages", JSON.stringify(deleteImages));
        deleteVideos.length > 0 &&
          formData.append("deleteVideos", JSON.stringify(deleteVideos));
        deleteTags.length > 0 &&
          formData.set("deleteTags", JSON.stringify(deleteTags));
        tags.length > 0 &&
          formData.append(
            "tags",
            JSON.stringify(
              tags.map((tag, index) => {
                return { tagName: tag };
              })
            )
          );

        if (
          newSpecifications &&
          newSpecifications.length > 0 &&
          newSpecifications[0].title &&
          newSpecifications[0].title.length > 0
        ) {
          formData.append(
            "specifications",
            JSON.stringify([...newSpecifications, ...specifications])
          );
        } else if (
          specifications &&
          specifications.length > 0 &&
          specifications[0].title.length > 0
        ) {
          formData.append("specifications", JSON.stringify(specifications));
        }

        if (
          newAttributes &&
          newAttributes.length > 0 &&
          newAttributes[0].key &&
          newAttributes[0].key.length > 0
        ) {
          formData.append(
            "attributes",
            JSON.stringify([...newAttributes, ...attributes])
          );
        } else if (
          attributes &&
          attributes.length > 0 &&
          attributes[0].key.length > 0
        ) {
          formData.append("attributes", JSON.stringify(attributes));
        }

        if (selectedImageFiles && selectedImageFiles.length > 0) {
          const imagesArray = Array.from(selectedImageFiles);
          imagesArray.forEach((image) => {
            formData.append("images", image);
          });
        }
        if (selectedVidoFiles && selectedVidoFiles.length > 0) {
          const videosArray = Array.from(selectedVidoFiles);
          videosArray.forEach((video) => {
            formData.append("videos", video);
          });
        }

        await addProductApi(formData);
        handleOpen();
        setLoading(false);
        setUpdateLoading(true);
      } catch (error) {
        console.log("Error", error);
      }
    }
  };
  useEffect(() => {
    (async () => {
      let data = await getProductCategory();
      let data1 = await getBusinessProfile();
      let stringCategories: string[] = data1.results.categories.map(
        (cata) => cata.name
      );
      setBusinessInfo(data1);
      let filteredCategories = data.results.filter((cata: { name: string }) =>
        stringCategories.includes(cata.name)
      );
      setCategories(filteredCategories);
    })();
  }, []);

  return (
    <section>
      {loadingP && (
        <div className="flex justify-center items-center h-96">
          <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
        </div>
      )}
      {!loadingP && step > 0 && (
        <>
          <div className="bg-white  font-Roboto rounded-md ">
            <Card className="w-full bg-transparent shadow-none  md:px-5">
              <form
                onSubmit={handleSubmit(onsubmit)}
                className="pb-7 formStyle "
              >
                {step === 1 && (
                  <div className="md:p-6 p-3 rounded-lg mt-3 border border-gray-300 space-y-2 h-[400px] overflow-auto">
                    <div className="w-full ">
                      <label
                        htmlFor="taskTitle"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Name <span className="text-red-500">*</span>:
                      </label>
                      <input
                        id="productName"
                        type="text"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("productName", {
                          required: "Name is required",
                        })}
                      />
                      <p className="text-red-500">
                        {errors.productName?.message}
                      </p>
                    </div>
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="brand"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Product Brand<span className="text-red-500">*</span>:
                      </label>
                      <input
                        id="productBrand"
                        type="text"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("brand", {
                          required: "Brand is required",
                        })}
                      />
                      <p className="text-red-500">{errors.brand?.message}</p>
                    </div>
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="weight"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Weight
                      </label>
                      <input
                        id="weight"
                        type="text"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("weight")}
                      />
                    </div>
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="quantity"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Quantity
                      </label>
                      <input
                        id="quantity"
                        type="text"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("quantity")}
                      />
                    </div>
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="tags"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Tags<span className="text-red-500">*</span>
                      </label>
                      <input
                        id="tags"
                        type="text"
                        autoComplete="off"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("tags")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                            handleTags(e.currentTarget.value);
                            e.currentTarget.value = "";
                          }
                        }}
                      />
                      <p className="text-red-500">{errors.tags?.message}</p>
                      <div className="flex flex-wrap gap-5">
                        {tags.map((item, index) => {
                          return (
                            <TagsPreview
                              key={index}
                              tags={item}
                              index={index}
                              action={handlePreviewTagDelete}
                            />
                          );
                        })}

                        {tagUrls.map((item, index) => {
                          return (
                            <TagsPreview
                              key={index}
                              tagUrls={item}
                              action={handleTagUrlDelete}
                              index={index}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="md:md:p-6 p-3  p-3  rounded-lg mt-3 border border-gray-300 space-y-2">
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="price"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Price<span className="text-red-500">*</span>:
                      </label>
                      <input
                        id="price"
                        type="number"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("price", {
                          required: "Price is required",
                        })}
                      />
                      <p className="text-red-500">{errors.price?.message}</p>
                    </div>
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="searchProduct"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Discount
                      </label>
                      <input
                        type="number"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("discountPercentage")}
                      />
                    </div>
                  </div>
                )}

                {step === 3 && (
                  <div className="md:p-6 p-3 rounded-lg mt-3 border border-gray-300 space-y-2">
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="category"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Category:
                      </label>
                      <select
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("category", {
                          required: "Category is required",
                        })}
                        value={selectCategory}
                        onChange={(e) => handleSelectCategory(e.target.value)}
                      >
                        <option value=" " disabled>
                          Select a category
                        </option>

                        {categories?.map((cata, index) => {
                          return (
                            <option value={cata.name} key={index}>
                              {cata.name} ({cata.percentage}%)
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="subCategory"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Sub Category:
                      </label>
                      <select
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("subCategory")}
                        value={selectSubCategory}
                        onChange={(e) => handleSubCategory(e.target.value)}
                        disabled={!selectCategory}
                      >
                        <option value=" " disabled>
                          Select a subcategory
                        </option>
                        {category
                          ?.find((cata) => cata.name === selectCategory)
                          ?.subCategories.map((subCata, index) => {
                            return (
                              <option value={subCata.name} key={index}>
                                {subCata.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="w-full space-y-2">
                      <label
                        htmlFor="thirdCategory"
                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                      >
                        Third Category:
                      </label>
                      <select
                        className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                        {...register("thirdCategory")}
                        value={selectThirdCategory}
                        onChange={(e) => handleThiredCategory(e.target.value)}
                        disabled={!selectSubCategory}
                      >
                        <option value=" " disabled>
                          Select a Third Category
                        </option>
                        {category
                          ?.find((cata) => cata.name === selectCategory)
                          ?.subCategories.find(
                            (subcata) => subcata.name === selectSubCategory
                          )
                          ?.subCategories.map((thirdCata, index) => {
                            return (
                              <option value={thirdCata.name} key={index}>
                                {thirdCata.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                )}
                {step === 4 && (
                  <div className="space-y-8 ">
                    {!newSpecification &&
                      specifications.map((data, index) => {
                        return (
                          index == repeaterIndex && (
                            <div
                              key={index}
                              className="border px-5 rounded-md border-gray-300"
                            >
                              <div className="pb-10  flex flex-row">
                                <div className="rounded-lg mt-5 space-y-2 w-full">
                                  <div className="w-full pt-5 pb-8 space-y-3">
                                    <div className="w-full space-y-2">
                                      <label
                                        htmlFor="title"
                                        className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                      >
                                        Title
                                        <span className="text-red-500">*</span>:
                                      </label>
                                      <input
                                        type="text"
                                        className="border-2 w-full p-3 rounded-md"
                                        required
                                        value={data.title}
                                        onInput={(e) => {
                                          data.title = e.currentTarget.value;
                                          setSpecifications([
                                            ...specifications,
                                          ]);
                                        }}
                                      />
                                      <p className="text-red-500">
                                        {errors.descriptionTitle?.message}
                                      </p>
                                    </div>
                                    <div className="w-full space-y-2">
                                      <label
                                        htmlFor="title"
                                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                                      >
                                        Description
                                        <span className="text-red-500">*</span>:
                                      </label>
                                      <ReactQuill
                                        theme="snow"
                                        value={data.description}
                                        className="rounded-lg h-56"
                                        onChange={(val) => {
                                          data.description = val;
                                          setSpecifications([
                                            ...specifications,
                                          ]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    {newSpecification &&
                      newSpecifications.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="border px-5 rounded-md border-gray-300"
                          >
                            <div className="pb-10  flex flex-row">
                              <div className="rounded-lg mt-5 space-y-2 w-full">
                                <div className="w-full pt-5 pb-8 space-y-3">
                                  <div className="w-full space-y-2">
                                    <label
                                      htmlFor="title"
                                      className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                    >
                                      Title
                                      <span className="text-red-500">*</span> :
                                    </label>
                                    <input
                                      type="text"
                                      className="border-2 w-full p-3 rounded-md"
                                      value={data.title}
                                      onInput={(e) => {
                                        data.title = e.currentTarget.value;
                                        setNewSpecifications([
                                          ...newSpecifications,
                                        ]);
                                      }}
                                    />
                                    <p className="text-red-500">
                                      {errors.descriptionTitle?.message}
                                    </p>
                                  </div>
                                  <div className="w-full space-y-2">
                                    <label
                                      htmlFor="title"
                                      className="text-[16px] text-gray-500 font-Roboto font-medium"
                                    >
                                      Description{" "}
                                      <span className="text-red-500">*</span> :
                                    </label>
                                    <ReactQuill
                                      theme="snow"
                                      value={data.description}
                                      className="rounded-lg h-56"
                                      onChange={(val) => {
                                        data.description = val;
                                        setNewSpecifications([
                                          ...newSpecifications,
                                        ]);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                {step === 5 && (
                  <div className="space-y-8 ">
                    {!newAttribute &&
                      attributes.map((data, index) => {
                        return (
                          index == repeaterIndex && (
                            <div
                              key={index}
                              className="border px-5 rounded-md border-gray-300"
                            >
                              <div className="pb-10  flex flex-row">
                                <div className="rounded-lg mt-5 space-y-2 w-full">
                                  <div className="w-full pt-5 pb-8 space-y-3">
                                    <div className="w-full space-y-2">
                                      <label
                                        htmlFor="title"
                                        className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                      >
                                        Key
                                        <span className="text-red-500">*</span>:
                                      </label>
                                      <input
                                        type="text"
                                        className="border-2 w-full p-3 rounded-md"
                                        value={data.key}
                                        required
                                        onInput={(e) => {
                                          data.key = e.currentTarget.value;
                                          setAttributes([...attributes]);
                                        }}
                                      />
                                    </div>
                                    <div className="w-full space-y-2">
                                      <label
                                        htmlFor="title"
                                        className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                      >
                                        Value Old
                                        <span className="text-red-500">*</span>:
                                      </label>
                                      <input
                                        type="text"
                                        className="border-2 w-full p-3 rounded-md"
                                        required
                                        value={data.value}
                                        onInput={(e) => {
                                          data.value = e.currentTarget.value;
                                          setAttributes([...attributes]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    {newAttribute &&
                      newAttributes.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="border px-5 rounded-md border-gray-300"
                          >
                            <div className="pb-10  flex flex-row">
                              <div className="rounded-lg mt-5 space-y-2 w-full">
                                <div className="w-full pt-5 pb-8 space-y-3">
                                  <div className="w-full space-y-2">
                                    <label
                                      htmlFor="title"
                                      className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                    >
                                      Key
                                      <span className="text-red-500">*</span> :
                                    </label>
                                    <input
                                      type="text"
                                      className="border-2 w-full p-3 rounded-md"
                                      value={data.key}
                                      onInput={(e) => {
                                        data.key = e.currentTarget.value;
                                        setnewAttributes([...newAttributes]);
                                      }}
                                    />
                                  </div>
                                  <div className="w-full space-y-2">
                                    <label
                                      htmlFor="title"
                                      className="text-[16px] text-gray-500 font-Roboto font-medium"
                                    >
                                      Value
                                      <span className="text-red-500">*</span> :
                                    </label>
                                    <input
                                      type="text"
                                      className="border-2 w-full p-3 rounded-md"
                                      value={data.value}
                                      onInput={(e) => {
                                        data.value = e.currentTarget.value;
                                        setnewAttributes([...newAttributes]);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}

                {step === 6 && (
                  <div>
                    <div className="md:md:p-6 p-3  rounded-lg mt-3 border border-gray-300 space-y-2 h-full max-h-[80vh] overflow-auto">
                      <div className=" w-full space-y-2">
                        <label
                          htmlFor="commission"
                          className="text-[16px] font-bold text-gray-500"
                        >
                          Standard Commission %:
                        </label>
                        <input
                          type="number"
                          value={
                            selectCategory &&
                            category?.find(
                              (cata) => cata.name === selectCategory
                            )?.percentage
                          }
                          className="border-2 w-full py-3 px-3 outline-none rounded-md"
                          readOnly
                        />
                      </div>
                      <div className="space-y-2 w-full">
                        <label
                          htmlFor="commission"
                          className="text-[16px] font-bold text-gray-500"
                        >
                          Additional Commission%:
                        </label>
                        <input
                          type="number"
                          onChange={handleCommission}
                          className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        />
                        <sup>put value here</sup>
                      </div>
                      <div className="space-y-2 w-full">
                        <label
                          htmlFor="commission"
                          className="text-[16px] font-bold text-gray-500"
                        >
                          Total Commission %:
                        </label>
                        <input
                          {...register("commission")}
                          type="number"
                          value={total}
                          className="border-2 w-full py-3 px-3 outline-none rounded-md"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 7 && (
                  <div>
                    <div className="flex gap-6 justify-center mt-9">
                      <div className="flex flex-col flex-wrap w-full">
                        <label htmlFor="videoFile">
                          <div className="border-2 border-dashed cursor-pointer border-gray-300 w-full rounded-md h-40 overflow-hidden ">
                            <div className="flex flex-col justify-center h-full items-center">
                              <AiOutlineVideoCameraAdd size={40} />
                              <p>Click to select files</p>
                            </div>
                          </div>
                        </label>
                        <input
                          type="file"
                          onChange={handleImageFile}
                          id="videoFile"
                          multiple={true}
                          className="hidden"
                        />
                        <div>
                          <div className="flex flex-wrap mt-4 gap-3">
                            {selectedImageFiles.map((video, index) => {
                              return (
                                <ImageVideoPreview
                                  key={index}
                                  image={URL.createObjectURL(video)}
                                  action={handlePreviewImagesDelete}
                                  index={index}
                                />
                              );
                            })}
                            {selectedVidoFiles.map((video, index) => {
                              return (
                                <ImageVideoPreview
                                  key={index}
                                  videoUrl={URL.createObjectURL(video)}
                                  action={handlePreviewVideoDelete}
                                  index={index}
                                />
                              );
                            })}
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 relative">
                              {imageUrls.map((img, index) => {
                                return (
                                  <ImageVideoPreview
                                    key={index}
                                    image={img.image}
                                    action={handleImageUrlDelete}
                                    index={index}
                                  />
                                );
                              })}
                              {videoUrls.map((video, index) => {
                                return (
                                  <ImageVideoPreview
                                    key={index}
                                    videoUrl={video.videoUrl}
                                    action={handleVideoUrlDelete}
                                    index={index}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <Button
                  type="submit"
                  className="bg-bluePostaam-600 ml-3  py-3 text-sm font-Roboto font-medium w-48 rounded-full tracking-wider mt-5"
                >
                  <div>{loading ? "Loading" : "Update"}</div>
                </Button>
              </form>
            </Card>
          </div>
        </>
      )}
    </section>
  );
};
export default ProductUpdate;
