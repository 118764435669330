import { RootDashboard } from "../../utils/home";

interface Information1 {
  data: RootDashboard;
}

const Information = ({ data }: Information1) => {
  const cardData = [
    {
      title: "Daily Sale",
      value: data.results.dashboardCount.sales || 0,
    },
    {
      title: "Total Earning",
      value: `Rs. ${data.results.dashboardCount.totalEarning || 0}`,
    },
    {
      title: "Total Sales",
      value: data.results.dashboardCount.totalSales || 0,
    },
    {
      title: "Affiliated Products",
      value: data.results.dashboardCount.affiliatedProduct || 0,
    },
    {
      title: "Total Products",
      value: data.results.dashboardCount.productCount || 0,
    },
  ];

  return (
    <section className="px-4 space-y-6">
      <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
        {cardData.map((data, index) => (
          <div
            key={index}
            className="p-5 box-shadow items-center bg-white rounded-md flex flex-col text-center shadow-md"
          >
            <h4 className="text-xl font-semibold">{data.value}</h4>
            <p className="text-gray-600">{data.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Information;
