import { useEffect, useState } from "react";
import {
  getAllNotificationApi,
  NotificationsData,
  deleteNotificationByIdApi,
} from "../../utils/notificationApi";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@material-tailwind/react";

export default function Notification() {
  const [notificationData, setNotificationData] = useState<NotificationsData[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  // Dummy data
  const dummyNotifications: NotificationsData[] = [
    {
      _id: "1",
      title: "New Update Available",
      body: "A new update has been released. Check it out!",
      image: "https://via.placeholder.com/40",
      createdAt: new Date().toISOString(),
    },
    {
      _id: "2",
      title: "Reminder",
      body: "Don't forget your upcoming meeting at 3 PM!",
      image: "https://via.placeholder.com/40",
      createdAt: new Date().toISOString(),
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getAllNotificationApi();
      setNotificationData([...dummyNotifications, ...res.results]); // Merge fetched and dummy data
    } catch (error) {
      console.log("Error", error);
      setNotificationData(dummyNotifications); // Fallback to dummy data if fetch fails
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteNotificationByIdApi(id);
      setNotificationData((prevData) =>
        prevData.filter((item) => item._id !== id)
      );
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-3 max-h-[50vh] h-full overflow-y-auto space-y-1">
      <h2 className="text-lg font-semibold ">Notification</h2>
      {loading && (
        <div className="flex justify-center items-center h-full">
          <Spinner className="h-10 w-10 text-gray-600/50" color="blue" />
        </div>
      )}

      {!loading && (
        <div className="gap-1 flex flex-col">
          {notificationData.length > 0 ? (
            notificationData.map((notification) => (
              <div
                key={notification._id}
                className="flex w-full items-start flex-col  bg-white p-2 rounded-lg border border-gray-300 "
              >
                <div className="flex w-full flex-row justify-between">
                  <div className="flex justify-start flex-row gap-2">
                    <img
                      src={notification?.image}
                      // alt={notification.title}
                      className="w-10 h-10 object-cover border border-gray-300 "
                    />
                    <span className="flex flex-col">
                      <span className="text-sm font-medium">
                        {notification?.title}
                      </span>
                      <small className="text-xs text-gray-600">
                        {new Date(notification?.createdAt).toLocaleString()}
                      </small>
                    </span>
                  </div>

                  <button
                    onClick={() => handleDelete(notification?._id)}
                    className="ml-4 text-red-500 hover:text-red-700"
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
                <div className="flex-1">
                  <p className="text-sm text-gray-600">{notification?.body}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="text-gray-600">No notifications available.</div>
          )}
        </div>
      )}
    </div>
  );
}
