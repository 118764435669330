import { useState } from "react";
// import loginFormLogo from "../../assets/image/loginFormLogo.png";
import { Button, Spinner } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { VerifyOTP } from "../../utils/user";
// import Loading from "../../assets/image/loading.svg";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoginFormLogo from "../../assets/image/loginFormLogo.png";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
type FormValue = {
  email: string;
  otp: Number;
};
const OTP = () => {
  const form = useForm<FormValue>();
  const [loading, setLoading] = useState(false);
  const { register, control, handleSubmit, formState } = form;
  const { errors } = formState;
  const params = new URLSearchParams(window.location.search);
  let email = params.get("email");

  const navigate = useNavigate();

  const onsubmit = async (data: FormValue) => {
    console.log("OTP submit");
    setLoading(true);
    const response = await VerifyOTP({ otp: data.otp, email: email! });
    if (response.status === 200) {
      toast.success(`Verification Successful`);
      navigate("/login");
    } else {
      toast.error(`Error ${response.data.message}`);
    }
    setLoading(false);
  };

  const handleBack = () => {
    navigate(-1);
  };


  const [isFocus, setIsFocus] = useState(false);
  return (
    <section className="flex flex-col justify-center items-center w-full py-6 h-[100vh] login-background">
      {/* <img src={loginFormLogo} alt="logo" className="w-56 object-contain" /> */}
      <img src={LoginFormLogo} alt="logo" className="w-60 object-contain" />
      <div className="bg-white pb-8 overflow-y-auto login-shadow w-[95%] sm:w-[450px] mt-8 overflow-hidden rounded-md">
        <ArrowLeftIcon className="text-Text mx-5  w-6 h-6 my-4  cursor-pointer" onClick={handleBack} />

        <div className="px-5 pb-4">
          <span className=" font-bold text-bluePostaam-550 text-left mt-5">
            <p className="text-[35px]">Verification Code</p>
          </span>
          <form
            // className="py-5 lg:w-[700px] sm:w-full md:w-full w-full lg:px-0 md:px-5 sm:px-5 px-5"
            onSubmit={handleSubmit(onsubmit)}
          >
            <div className="mt-3 w-full space-y-2">
              <label
                htmlFor="otpcode"
                className="rounded-md text-Text font-[500] outline-none w-full font-mulish"
              >
                OTP Code:
              </label>
              <div
                className={`w-full rounded-md custom-input ${errors.otp
                  ? "focus:border-red-500 focus:ring-red-500 border-red-500 border-2"
                  : isFocus
                    ? "focus:border-indigo-500 focus:ring-border-500 border-indigo-500 border-2"
                    : ""
                  }`}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
              >
                <input
                  type="text"
                  id="otp"
                  className="py-5 w-full px-4 rounded-md outline-none"
                  {...register("otp", {
                    required: "OTP code is required*",
                  })}
                />
              </div>
              <p className="text-red-900 pb-0 mb-0 font-mulish">
                {errors.otp?.message}
              </p>
            </div>

            <div className="w-full items-center flex justify-center mt-7">
              {!loading && (
                <Button
                  type="submit"
                  className="bg-login  w-full py-5 font-Roboto font-bold rounded-md  -tracking-tighter text-[15px] capitalize"
                >
                  Verify
                </Button>
              )}
              {loading && (
                <div className="flex items-center gap-2 login-bg text-white w-full justify-center py-5 tracking-wider text-[15px] outline-none">
                  <Spinner className="h-4 w-4" />
                  <p>Loading</p>
                </div>
              )}
            </div>
            <p className="text-left mt-6 font-Roboto">
              Please enter your OTP Code received on your regestered email.
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default OTP;
