import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dialog, Spinner } from "@material-tailwind/react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import GigUpdate from "./GigUpdate";
import UserList from "../GigsComponents/UserList";
import {
  addGig,
  Gig,
  GigDelete,
  GigDetails,
  GigDetailsPosts,
  GigDetailsUserList,
  GigProps,
  GigUser,
  RootObject,
} from "../../../utils/gig";
import { formatDistanceToNow } from "date-fns";
import { Post } from "../../../utils/post";
import Edit from "../../../assets/image/edit.png";
import PostCard from "../../post/PostCard";

const GigDetailsList = () => {
  const [gigData, setGigData] = useState<Gig | undefined>();
  const [gigSubTasks, setGigSubTasks] = useState<any[]>([]);
  const [canApply, setCanApply] = useState(false);
  const [status, setStatus] = useState(false);
  const page = 1;
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingUserHire, setLoadingHire] = useState(false);
  const [loadingUserApproval, setLoadingUserApproval] = useState(false);
  const [tabs] = useState([
    { name: "Details" },
    { name: "Applied" },
    { name: "Hire" },
    { name: "Approval" },
    { name: "Submit" },
  ]);
  const [activeTab, setActiveTab] = useState<string>("Details");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [newSpecification, setnewSpecification] = useState(false);
  const [repeaterIndex, setRepeaterIndex] = useState<number | undefined>(
    undefined
  );
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = useState(1);
  const [specificationTitle, setSpecificationTitle] = useState("");
  const history = useNavigate();
  const [gigUsers, setGigUsers] = useState<GigUser[]>();
  const [gigUsersHire, setGigUsersHire] = useState<GigUser[]>();
  const [gigUsersApproval, setGigUsersApproval] = useState<GigUser[]>();
  const [reFetch, setReFetch] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
    setUpdateLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      const GigPostData: GigProps = {
        page: page,
        id: id!,
        limit: 5,
      };
      const response: RootObject = await GigDetails(GigPostData);
      if (response.status === 200) {
        setGigData(response.data.gig);
        setCanApply(response.data.gig.canApply);
        setStatus(response.data.gig.status);
        setGigSubTasks(response.data.subTask);
        setLoading(false);
        setSpecificationTitle((prevTitle) => {
          const newTitle =
            repeaterIndex !== undefined
              ? response.data.gig.specifications?.[repeaterIndex]?.title || ""
              : "";
          return newTitle !== "" ? newTitle : prevTitle;
        });
      } else {
        console.log("Error", response);
      }
    })();
    console.log("reFetch", reFetch);
  }, [updateLoading, reFetch]);

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await GigDelete({ _id: id, page: 1 });
        toast.success("Gig Deleted successfully");
        history("/gigList");
      } catch (error) {
        toast.error("Error deleting product");
        console.error("Error deleting product:", error);
      }
    }
  };
  const formatPostDate = (dateString: any) => {
    if (!dateString) return "";
    const postDate = new Date(dateString);
    return formatDistanceToNow(postDate, { addSuffix: true });
  };

  const handleEnableCanApply = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCanApply(event.target.checked);
    const formData = new FormData();
    formData.append("canApply", canApply ? "false" : "true");
    formData.append("_id", id!);
    await addGig(formData);
  };
  const handleStatus = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
    const formData = new FormData();
    formData.append("status", status ? "false" : "true");
    formData.append("_id", id!);
    await addGig(formData);
  };

  const changeTab = async (tab: string) => {
    setActiveTab(tab);

    if (tab != "Submit" && tab != "Chat") {
      const response: RootObject = await GigDetailsUserList({
        id: id!,
        page: 1,
        limit: 5,
        gigStatus: tab,
      });
      if (response.status == 200) {
        if (tab == "Applied") {
          setGigUsers(response.data.results);
        }
        if (tab === "Hire") {
          setGigUsers(response.data.results);
        }
        if (tab == "Approval") {
          setGigUsersApproval(response.data.results);
        }
      }
    }

    if (tab == "Submit") {
      const response: RootObject = await GigDetailsPosts({
        id: id!,
        page: 1,
        limit: 5,
        gigStatus: tab,
      });
      if (response.status == 200) {
        setPosts(response.data.posts);
      }
    }
  };

  return (
    <section className="max-w-7xl h-full m-auto">
      <div className="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-3">
        <div className=" border border-gray-300 rounded-lg bg-white">
          <div className="md:p-8 p-3">
            <div className="flex justify-between pb-2">
              <div>
                <div className="basicgigingos">
                  <p className="">
                    Posted {formatPostDate(gigData?.createdAt)}
                  </p>
                </div>
                <h3 className="gigmaintitle">{gigData?.title}</h3>
              </div>
              <div>
                {gigUsers &&
                  gigUsers?.filter((ele) => ele.gigStatus === "approved")
                    .length === 0 && (

                    <div onClick={() => {
                      handleOpen();
                      setStep(7);
                    }}>
                      <img
                        src={Edit}
                        alt="edit"
                        className="w-10 h-10 object-contain cursor-pointer"
                      />
                    </div>
                  )}
              </div>
            </div>

            <div>
              {/* Show dropdown on small screens (sm) */}
              <select
                className={`block w-full py-2 px-3 border rounded-md md:hidden ${activeTab
                  ? ""
                  : "bg-gray-200 text-black"
                  }`}
                value={activeTab}
                onChange={(e) => changeTab(e.target.value)}
              >
                {tabs.map((ele, index) => (
                  <option key={index} value={ele.name}>
                    {ele.name}
                  </option>
                ))}
              </select>

              {/* Show grid on medium (md) and larger screens */}
              <div className="hidden md:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 p-2">
                {tabs.map((ele, index) => (
                  <div
                    key={index}
                    className={`gigsingleinnertab text-white flex justify-center items-center p-3 rounded-md cursor-pointer transition-all duration-300 ${activeTab === ele.name
                      ? "bg-bluePostaam-600"
                      : "bg-gray-200 hover:bg-gray-300"
                      }`}
                    onClick={() => changeTab(ele.name)}
                    role="button"
                  >
                    <h5
                      className={`text-lg font-medium ${activeTab === ele.name ? "text-white" : "text-black"
                        }`}
                    >
                      {ele.name}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {loading && (
            <div className=" flex justify-center items-center h-[60vh]">
              <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
            </div>
          )}
          {!loading && (
            <div className="">
              {/* Applied start */}
              {activeTab === "Applied" && (
                <div className="md:space-y-4 space-y-2">
                  <div className="border-gray-300 border-t md:p-8 p-3  md:space-y-4 space-y-2">
                    <UserList
                      tab={"Applied"}
                      buttonAction={"Hire"}
                      setReFetch={setReFetch}
                      reFetch={reFetch}
                      requirePosting={gigData?.requirePosting}
                      id={id!}
                    />
                  </div>
                </div>
              )}
              {/* Applied end */}
              {/* Selected Freelancer start */}
              {activeTab == "Hire" && (
                <div>
                  <div className="md:space-y-4 space-y-2">
                    <div className="border-gray-300 border-t md:p-8 p-3  md:space-y-4 space-y-2">
                      <UserList
                        tab={"Hire"}
                        buttonAction={"Hire"}
                        setReFetch={setReFetch}
                        reFetch={reFetch}
                        requirePosting={gigData?.requirePosting}
                        id={id!}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab == "Approval" && (
                <div>
                  <div className="md:space-y-4 space-y-2">
                    <div className="border-gray-300 border-t md:p-8 p-3  md:space-y-4 space-y-2">
                      <UserList
                        tab={"Approval"}
                        buttonAction={"Approve"}
                        setReFetch={setReFetch}
                        reFetch={reFetch}
                        requirePosting={gigData?.requirePosting}
                        id={id!}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* Selected Freelancer end */}
              {/* Content Posted Submit start */}
              {activeTab == "Submit" && (
                <div className="md:space-y-4 space-y-2">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-10 py-5">
                    {posts.map((post, index) => (
                      <PostCard key={index} post={post} />
                    ))}
                  </div>
                </div>
              )}
              {/* Content Posted Submit start */}
              {/* Gig details start */}
              {activeTab == "Details" && (
                <div className="md:space-y-4 space-y-2">
                  {/* Gig details start */}
                  <div className="md:space-y-4 space-y-2">
                    {gigSubTasks && gigSubTasks?.length > 0 && (
                      <div className="border-gray-300 border-t">
                        <div>
                          <div className="border-b space-y-2">
                            <div className="md:px-8 p-3 md:py-5 flex flex-row items-center justify-between">
                              <h5 className="text-gray-600 md:text-2xl text-xl">
                                {gigData?.gigType}
                              </h5>
                              {gigUsers &&
                                gigUsers?.filter(
                                  (ele) => ele.gigStatus == "approved"
                                ).length == 0 && (
                                  <button>Add Sub Task</button>
                                )}
                            </div>
                          </div>
                          <div>
                            <div className="md:px-8 md:py-5  p-3 md:space-y-4 space-y-2">
                              <div className="flex flex-row justify-between items-start w-full">
                                {/* Left Content */}
                                <div className="flex-1 min-w-0">
                                  <h5 className="text-gray-700 font-medium text-lg">
                                    {gigData?.action}
                                  </h5>

                                  <div className="grid grid-cols-12">
                                    <div className="col-span-6">
                                      <div className="flex space-y-2 flex-col md:flex-row">
                                        <p className="text-gray-700 font-medium w-full md:w-6/12">
                                          Business:
                                        </p>
                                        <p className="font-mulish text-gray-600">
                                          {gigData?.businessName}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex space-y-2 flex-col md:flex-row">
                                    <p className="text-gray-700 font-medium w-full md:w-6/12">
                                      Title:
                                    </p>
                                    <p className="font-mulish text-gray-600">
                                      {gigData?.title}
                                    </p>
                                  </div>

                                  <div className="flex space-y-2 flex-col md:flex-row">
                                    <p className="text-gray-700 font-medium w-full md:w-6/12">
                                      Description:
                                    </p>
                                    <p className="font-mulish text-gray-600 break-words w-full">
                                      {gigData?.description}
                                    </p>
                                  </div>

                                  <div>
                                    <p className="font-500 font-Roboto text-gray-600 pb-2">
                                      Tags
                                    </p>
                                    <div className="flex space-y-2 flex-col md:flex-row flex-wrap skillpills">
                                      <p>
                                        {gigData?.tags.length === 0
                                          ? "No Tag Available"
                                          : gigData?.tags}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {/* Right Side - Buttons */}
                                {gigUsers &&
                                  gigUsers?.filter(
                                    (ele) => ele.gigStatus === "approved"
                                  ).length === 0 && (
                                    <div className="flex-shrink-0 flex-col md:flex-row flex space-y-2 flex-col md:flex-row mt-4 md:mt-0">

                                      <div onClick={() => {
                                        handleOpen();
                                        setStep(8);
                                      }}>
                                        <img
                                          src={Edit}
                                          alt="edit"
                                          className="w-10 h-10 object-contain cursor-pointer"
                                        />
                                      </div>

                                      <button
                                        className="p-3 rounded-full bg-red-600/70 shadow-none hover:shadow-none"
                                        disabled
                                      >
                                        <AiFillDelete
                                          size={20}
                                          className="text-white"
                                        />
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {gigData && gigData.product && (
                      <div className="bg-white px-5 leading-8 flex justify-between flex-col mt-4 border-gray-300 border-t">
                        <div className="flex justify-between items-center">
                          <h5 className="text-gray-600 md:text-2xl text-xl mb-1">
                            Product
                          </h5>
                          <div onClick={() => {
                            handleOpen();
                            setStep(6);
                          }}>
                            <img
                              src={Edit}
                              alt="edit"
                              className="w-10 h-10 object-contain cursor-pointer"
                            />
                          </div>
                        </div>
                        <div className="product-card flex gap-3 items-center">
                          <img
                            src={gigData?.product.image}
                            alt={gigData?.product.productName}
                            className="w-32 h-32 object-contain border-[1px] rounded-md"
                          />
                          <ul>
                            <li>
                              <a
                                href={`/productDetails/${gigData?.product._id}`}
                                target="_blank"
                                className="product-link text-xl"
                              >
                                {gigData?.product.productName}
                              </a>
                            </li>
                            <li>Rs. {gigData?.product.price}</li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Gig details end */}
                  <div className="border-gray-300 border-t md:p-8 p-3">
                    <div>
                      <div className="flex flex-row items-center justify-between space-y-2 pb-2">
                        <h5 className="text-gray-600 md:text-2xl text-xl">Preference</h5>
                        {gigUsers &&
                          gigUsers?.filter(
                            (ele) => ele.gigStatus === "approved"
                          ).length === 0 && (
                            <div onClick={() => {
                              handleOpen();
                              setStep(2);
                            }}>
                              <img
                                src={Edit}
                                alt="edit"
                                className="w-10 h-10 object-contain cursor-pointer"
                              />
                            </div>
                          )}
                      </div>

                      <div>
                        <div className="flex space-y-2 flex-col md:flex-row">
                          <p className="text-gray-700 font-medium w-full md:w-6/12">
                            Number of Creator
                          </p>
                          <p className="text-gray-600">
                            {gigData?.numberofcreator
                              ? gigData?.numberofcreator
                              : "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div>
                        <div className="flex space-y-2 flex-col md:flex-row">
                          <p className="text-gray-700 font-medium w-full md:w-6/12">
                            Content Type
                          </p>
                          <p className="text-gray-600 capitalize">
                            {gigData?.gigType
                              ? gigData?.gigType
                              : "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-300 border-t md:p-8 p-3">
                    <div>
                      <div className="flex flex-row items-center justify-between space-y-2 pb-2">
                        <h5 className="text-gray-600 md:text-2xl text-xl">Multi Post</h5>
                        {gigUsers &&
                          gigUsers?.filter(
                            (ele) => ele.gigStatus == "approved"
                          ).length === 0 && (
                            <div onClick={() => {
                              handleOpen();
                              setStep(3);
                            }}>
                              <img
                                src={Edit}
                                alt="edit"
                                className="w-10 h-10 object-contain cursor-pointer"
                              />
                            </div>
                          )}
                      </div>
                      <div className="flex space-y-2 flex-col md:flex-row">
                        <p className="text-gray-700 font-medium w-full md:w-6/12">
                          Number of Images
                        </p>
                        <p className="font-mulish text-gray-600">
                          {gigData?.numberofimage
                            ? gigData?.numberofimage
                            : "Not Available"}
                        </p>
                      </div>

                      <div>
                        <div className="flex space-y-2 flex-col md:flex-row">
                          <p className="text-gray-700 font-medium w-full md:w-6/12">
                            Number of Videos
                          </p>
                          <p className=" text-gray-600">
                            {gigData?.numberofvideo
                              ? gigData?.numberofvideo
                              : "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-300 border-t md:p-8 p-3 flex justify-between">
                    <div className="flex flex-col space-y-1 w-full">
                      <div className="flex flex-row items-center justify-between space-y-2 pb-2">
                        <h5 className="text-gray-600 md:text-2xl text-xl">
                          Tags & Skills
                        </h5>
                        {gigUsers &&
                          gigUsers?.filter(
                            (ele) => ele.gigStatus == "approved"
                          ).length == 0 && (
                            <div onClick={() => {
                              handleOpen();
                              setStep(1);
                            }}>
                              <img
                                src={Edit}
                                alt="edit"
                                className="w-10 h-10 object-contain cursor-pointer"
                              />
                            </div>
                          )}
                      </div>
                      <p className="font-500 font-Roboto text-gray-600">
                        Experties Level
                        <span className="font-normal pl-2 capitalize">
                          {gigData?.level}
                        </span>
                      </p>

                      <div className="grid grid-cols-12 space-x-3">
                        <div className="col-span-6 pb-3">
                          <p className="font-500 font-Roboto text-gray-600 pb-2">
                            Tags
                          </p>
                          <div className="flex space-y-2 flex-col md:flex-row flex-wrap skillpills">
                            {gigData?.tags.map((tag, index) => {
                              return (
                                <p key={index} className="">
                                  #{tag}
                                </p>
                              );
                            })}
                          </div>
                        </div>

                        <div className="col-span-6 ">
                          <p className="font-500 font-Roboto text-gray-600 pb-2">
                            Skills
                          </p>
                          <div className="flex space-y-2 flex-col md:flex-row flex-wrap skillpills">
                            {gigData?.skills.map((skill, index) => {
                              return (
                                <p key={index} className="">
                                  {skill}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row items-center justify-between space-y-2 md:px-8 md:py-5 p-3 border-b border-t">
                    <h5 className="text-gray-600 md:text-2xl text-xl font-medium">
                      Specifications
                    </h5>
                    {gigUsers &&
                      gigUsers?.filter((ele) => ele.gigStatus == "approved")
                        .length == 0 && (
                        <Button
                          className="bg-bluePostaam-600 rounded-md p-2 md:p-4"
                          onClick={() => {
                            setStep(4);
                            setnewSpecification(true);
                            handleOpen();
                          }}
                        >
                          Add Specification
                        </Button>
                      )}
                  </div>

                  <div className="">
                    {gigData?.specifications.map((spec, index) => (
                      <div key={index} className="">
                        <div className="flex flex-col w-full border-b md:py-8 md:px-8 p-3">
                          <div className="flex justify-between items-center">
                            <p className="text-gray-600 text-lg font-semibold w-full">
                              {spec.title}
                            </p>
                            {gigUsers &&
                              gigUsers?.filter(
                                (ele) => ele.gigStatus == "approved"
                              ).length == 0 && (
                                <div className="space-x-2 flex flex-row w-full h-full justify-end items-center ">

                                  <div onClick={() => {
                                    setnewSpecification(false);
                                    handleOpen();
                                    setStep(4);
                                    setRepeaterIndex(index);
                                    setSpecificationTitle(spec.title);
                                  }}>
                                    <img
                                      src={Edit}
                                      alt="edit"
                                      className="w-10 h-10 object-contain cursor-pointer"
                                    />
                                  </div>

                                  <Button
                                    className="h-10 w-10 p-3 justify-center items-center rounded-full bg-red-600/70 shadow-none hover:shadow-none"
                                    disabled
                                  >
                                    <AiFillDelete
                                      size={20}
                                      className="text-white text-center"
                                    />
                                  </Button>
                                </div>
                              )}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: spec.description,
                            }}
                            style={{
                              color: "#92939a",
                              marginTop: "18px",
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Gig details end */}
            </div>
          )}
        </div>

        <div className="bg-white border border-gray-300 rounded-lg  space-y-2">
          <div className="p-6 flex items-center md:space-x-4">
            {gigUsers &&
              gigUsers?.filter((ele) => ele.gigStatus == "applied")
                .length == 0 && (
                <div className="flex">
                  <AiFillDelete size={26} color="#f44336" />

                  <Button
                    className="hover:shadow-transparent shadow-transparent bg-transparent p-0 text-red-500 text-left text-base capitalize font-medium"
                    onClick={() => handleDelete(gigData?._id!)}
                  >
                    Delete
                  </Button>
                </div>
              )}
          </div>
          <div className="p-6 flex items-center space-x-4">
            <div className="-mb-2">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={canApply}
                  onChange={handleEnableCanApply}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bluePostaam-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Enable Button
                </span>
              </label>
            </div>
            <div className="-mb-2">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={status}
                  onChange={handleStatus}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-bluePostaam-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Status
                </span>
              </label>
            </div>
          </div>
          <div className="border-t md:px-6 md:py-4 p-3 justify-between">
            <div className="flex items-center justify-between">
              <h6 className="text-gray-800 font-Roboto text-xl font-medium">
                Price
              </h6>
              {gigUsers &&
                gigUsers?.filter((ele) => ele.gigStatus == "approved")
                  .length == 0 && (
                  <div onClick={() => {
                    handleOpen();
                    setStep(5);
                  }}>
                    <img
                      src={Edit}
                      alt="edit"
                      className="w-10 h-10 object-contain cursor-pointer"
                    />
                  </div>
                )}
            </div>

            <div className="space-y-2">
              <div className="flex space-y-2 flex-col md:flex-row">
                <span className="font-bold font-Roboto text-gray-600 w-full md:w-6/12">
                  Duration Days:
                </span>
                <p className="font-mulish text-gray-600">
                  {gigData?.adDuration
                    ? gigData.adDuration
                    : "Not Available"}
                </p>
              </div>
              <div className="flex space-y-2 flex-col md:flex-row">
                <span className="font-bold font-Roboto text-gray-600 w-full md:w-6/12">
                  Timeframe:
                </span>
                <p className="font-mulish text-gray-600">
                  {gigData?.submissionTimeframe
                    ? gigData.submissionTimeframe
                    : "Not Available"}
                </p>
              </div>

              <div className="flex space-y-2 flex-col md:flex-row">
                <span className="font-bold font-Roboto text-gray-600 w-full md:w-6/12">
                  Offer Amount / Offer From:
                </span>
                <p className="font-mulish text-gray-600">
                  {gigData?.offerAmountFrom
                    ? gigData.offerAmountFrom
                    : "Not Available"}
                </p>
              </div>
              <div className="flex space-y-2 flex-col md:flex-row">
                <span className="font-bold font-Roboto text-gray-600 w-full md:w-6/12">
                  Offer To:
                </span>
                <p className="font-mulish text-gray-600">
                  {gigData?.offerAmountTo
                    ? gigData.offerAmountTo
                    : "Not Available"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} handler={handleOpen} dismiss={{ enabled: false }}>
        <GigUpdate
          step={step}
          id={id!}
          handleOpen={handleOpen}
          repeaterIndex={repeaterIndex}
          newSpecification={newSpecification}
          setUpdateLoading={setUpdateLoading}
          specificationTitle={specificationTitle}
        />
      </Dialog>
    </section>

  );
};

export default GigDetailsList;
