import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import SalesData from "../components/tables/Sales";
import { Result, RootSales, salesList } from "../utils/sales";

const Revenu = () => {
  const [open, setOpen] = useState(false);
  const [salesData, setSalesData] = useState<Result[]>([]);
  const [statusCounts, setStatusCounts] = useState({
    pending: 0,
    completed: 0,
    cancelled: 0,
    processing: 0,
  });
  const [selectedFilter, setSelectedFilter] = useState("All"); // New state for filter

  useEffect(() => {
    const fetchData = async () => {
      const res: RootSales = await salesList({
        page: 1,
        limit: 10,
      });
      if (res.status === 200) {
        setSalesData(res.data.results);

        // Calculate counts
        const newCounts = {
          pending: res.data.results.filter(
            (item) => item.saleStatus === "Pending"
          ).length,
          completed: res.data.results.filter(
            (item) => item.saleStatus === "Completed"
          ).length,
          cancelled: res.data.results.filter(
            (item) => item.saleStatus === "Cancelled"
          ).length,
          processing: res.data.results.filter(
            (item) => item.saleStatus === "Processing"
          ).length,
        };
        setStatusCounts(newCounts);
      }
    };
    fetchData();
  }, []);

  const handleOpen = () => setOpen((prev) => !prev);

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter); // Update selected filter state
    setOpen(false); // Close filter dropdown
  };

  const renderCard = (count: number, label: string) => (
    <div className="md:p-5 w-44 md:w-60 justify-center p-3 box-shadow flex flex-col items-center bg-white rounded-md ">
      <h4 className="text-[#5b3de8] text-xl md:text-3xl font-bold">{count}</h4>
      <p className="text-[#424242] md:text-2xl text-lg font-medium leading-6 tracking-[0.6px]">
        {label}
      </p>
    </div>
  );

  return (
    <div className="flex justify-center items-center">
      <section className="w-full max-w-7xl space-y-3 md:space-y-6">
        {/* Status Cards */}
        <div className="w-full flex flex-wrap gap-4 sm:gap-6 md:gap-8 items-center justify-center cardstyle">
          {renderCard(statusCounts.pending, "Pending")}
          {renderCard(statusCounts.processing, "Processing")}
          {renderCard(statusCounts.cancelled, "Cancelled")}
          {renderCard(statusCounts.completed, "Completed")}
        </div>

        {/* Filter Button */}
        <div className="flex  sm:justify-start flex-col items-start bg-white rounded-md box-shadow px-3 py-2 relative">
          <Button className="filterbutton" onClick={handleOpen}>
            {selectedFilter} ▼
          </Button>
          {open && (
            <div className="bg-white shadow-md w-40 z-20 rounded-md border-[1px] border-gray-200 absolute mt-12">
              <ul>
                {["All", "Weekly", "Monthly", "Yearly"].map((filter, index) => (
                  <li
                    key={index}
                    className="p-3 cursor-pointer hover:bg-indigo-50"
                    onClick={() => handleFilterChange(filter)} // Set selected filter
                  >
                    {filter}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Sales Data Table */}
        <SalesData filter={selectedFilter} />
      </section>
    </div>
  );
};

export default Revenu;
