import { useEffect, useState, useRef } from "react";
import { IoMdNotifications } from "react-icons/io";
import { Avatar, Badge } from "@material-tailwind/react";
import { RootBusinessProfile, getBusinessProfile } from "../../utils/business";
import { Results } from "../../utils/user";
import { useNavigate } from "react-router-dom";
import Notifications from "./Notification";
import { Bars3Icon } from "@heroicons/react/24/outline";
import loginFormLogo from "../../assets/image/loginFormLogo.png";

interface HeaderProps {
  toggleSidebar: () => void;
}

const Header = ({ toggleSidebar }: HeaderProps) => {
  const notificationRef = useRef<HTMLDivElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [businessProfile, setBusinessProfile] = useState<RootBusinessProfile>();
  const [user, setUser] = useState<Results>();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   (async () => {
  //     let data = await getBusinessProfile();
  //     setBusinessProfile(data);
  //   })();
  // }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setMenuVisible(false);
      }
    }
    if (menuVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("token")!));
  }, []);

  const handleButtonClick = () => {
    setMenuVisible(!menuVisible);
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <header className="flex w-full justify-between md:justify-end items-center ">
      <div className="md:hidden">
        <button onClick={toggleSidebar}>
          <Bars3Icon className="h-10 w-10 text-Text" />
        </button>
      </div>
      <div className="block md:hidden">
        <img src={loginFormLogo} alt="logo" className="w-32 h-auto md:w-56 object-contain" />
      </div>

      <div className="flex items-end justify-end">
        {/* Notification menu started */}
        <div ref={notificationRef} className="inline-block text-left z-20 relative">
          <button onClick={handleButtonClick}>
            <Badge className="rounded-md shadow-sm bg-Bgc custom-badge mr-1 mt-1 border-2 border-[#F7F8FC]">
              <IoMdNotifications size={25} className="text-[#C5C7CD]" />
            </Badge>
          </button>
          <div
            className={`${menuVisible ? "" : "hidden"} origin-top-right absolute mt-3 w-80 left-[-220px] rounded-md shadow_box bg-white`}
          >
            <div className="h-full max-h-[50vh] overflow-y-auto">
              <Notifications />
            </div>
          </div>
        </div>
        {/* Notification menu ended */}

        {/* Profile started */}
        <div className="h-8 mx-4 w-[2px] bg-[#DFE0EB]"></div>
        <div
          className="flex items-center relative h-full"
          onMouseOver={() => setIsOpen(true)}
          onMouseOut={() => setIsOpen(false)}
        >
          <p className="font-Roboto font-[500] text-[14px] text-[#252733] tracking-wide mx-2 lg:block md:block sm:hidden hidden">
            {user ? user.user.fullname : "Guest"}
          </p>
          <Avatar
            src={
              businessProfile?.results?.profileFile ||
              "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.explicit.bing.net%2Fth%3Fid%3DOIP.0CZd1ESLnyWIHdO38nyJDAAAAA%26pid%3DApi&f=1"
            }
            onClick={() => setIsOpen(!isOpen)}
            alt="avatar"
            withBorder={true}
            className="p-0.5 bg-white border-gray-400 border-2
            "

          />
          {isOpen && (
            <div className="absolute right-0 bottom-0 top-[50px] px-1 z-50 w-[195px] h-[103px] bg-white shadow_box">
              <div
                onClick={() => navigate("/profile")}
                className="px-3 cursor-pointer hover:bg-gray-200 flex-col flex py-[0.8rem] mt-1 font-Roboto font-[400] text-[14px] text-[#A4A6B3] tracking-wide"
              >
                Edit Profile
              </div>
              <hr />
              <div
                onClick={() => logoutUser()}
                className="hover:bg-gray-200 flex-col flex font-Roboto font-[400] text-[14px] text-[#A4A6B3] tracking-wide"
              >
                <p className="px-3 hover:bg-gray-200 cursor-pointer flex-col flex py-[0.98rem] pb-[0.7rem] font-Roboto font-[400] text-[14px] text-[#A4A6B3] tracking-wide">
                  Logout
                </p>
              </div>
            </div>
          )}
        </div>
        {/* Profile ended */}
      </div>
    </header>
  );
};

export default Header;
