import { useEffect, useState } from "react";
import Information from "../components/information/Information";
import { useNavigate } from "react-router-dom";
import { RootDashboard, getDashboardData } from "../utils/home";
import { Spinner } from "@material-tailwind/react";
import BarChart from "../components/chart/BarChart";
const DashBoard = () => {
  const router = useNavigate();
  const [dashboardCount, setDashboardCount] = useState<RootDashboard>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      if (!localStorage.getItem("token")) {
        router("/login");
      } else {
        try {
          setLoading(true);
          let dashData = await getDashboardData();
          setDashboardCount(dashData);
        } catch (error) {
          console.log("Error fetching Data:", error);
        }
        setLoading(false);
      }
    })();
  }, []);









  return (
    <main>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
        </div>
      ) : (
        <div>
          {dashboardCount ? (
            <div>
              <Information data={dashboardCount} />
              <BarChart />
            </div>
          ) : (
            "No data available"
          )}
        </div>
      )}
    </main>
  );
};

export default DashBoard;
