// import loginFormLogo from "../../assets/image/loginFormLogo.png";
import { Button, Spinner } from "@material-tailwind/react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { userLogin } from "../../utils/user";
// import Loading from "../../assets/image/loading.svg";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

type FormValue = {
  email: string;
  password: string;
};

const LoginForm = () => {
  // const tab = ["Home", "About", "Contact", "Rohit"];
  // const [activeTab, setActiveTab] = useState(0);
  // const [curretTab, setCurrentTab] = useState(0);
  const form = useForm<FormValue>({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const router = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const onsubmit = async (data: FormValue) => {
    setLoading(true);
    const response = await userLogin(data);

    if (response.status === 200) {
      const token = response.data.results;
      localStorage.setItem("token", JSON.stringify(token));
      toast.success("Login Successful");
      router("/");
    } else if (response.status == 443) {
      router(`/otp?email=${data.email}`);
      toast.error(`Error ${response.data.message}`);
    } else {
      toast.error(`Invalid Email or Password`);
    }
    setLoading(false);
  };

  const [passwordEye, setPasswordEye] = useState(false);
  const handelPassword = () => {
    setPasswordEye(!passwordEye);
  };
  const [isFocused, setIsFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);

  const [pageloading, setPageLoading] = useState(true);
  // const [loadingMenu, setLoadingMenu] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      router("/");
    }
    setPageLoading(false);
  }, [pageloading]);

  return (
    <>
      {pageloading && <div>Loading...</div>}
      {!pageloading && (
        <section className="flex flex-col justify-center items-center w-full py-6 h-[100vh] login-background ">
          <div className="bg-white  pt-6 overflow-y-auto login-shadow w-[95%]  sm:w-[450px] mt-4 sm:mt-8 overflow-hidden rounded-md">
            <div className="px-5 pb-6  ">
              <span className=" font-bold  text-left leading-10">
                <p className="text-[32px] text-bluePostaam-550">Hello,</p>
                <p className="text-[32px] mb-4 text-bluePostaam-550">Welcome Back!</p>
              </span>
              <form
                className="h-full"
                // className="py-5 lg:w-[700px] md:w-full sm:w-full w-full lg:px-0 md:px-5 sm:px-5 px-5  flex flex-col justify-center items-center"
                onSubmit={handleSubmit(onsubmit)}
              >
                <div className=" w-full pt-0 space-y-2 ">
                  <label
                    htmlFor="email"
                    className="rounded-md text-Text font-[500] outline-none w-full font-mulish"
                  >
                    Email:
                  </label>
                  <div
                    className={`rounded-md custom-input  ${errors.email
                      ? "focus:border-red-500 focus:ring-red-500 border-red-500 border-2"
                      : emailFocused
                        ? "focus:border-indigo-500 focus:ring-indigo-500 border-indigo-500 border-2"
                        : ""
                      }`}
                    onFocus={() => setEmailFocused(true)}
                    onBlur={() => setEmailFocused(false)}
                  >
                    <input
                      id="email"
                      type="text"
                      color="indigo"
                      className="rounded-md py-5 px-4 outline-none w-full font-mulish"
                      autoFocus={false}
                      {...register("email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid Email Address",
                        },
                        required: "Email is required*",
                      })}
                    />
                  </div>
                  <p className="text-red-900  mb-[-35px] font-mulish">
                    {errors.email?.message}
                  </p>
                </div>

                <div className=" w-full  space-y-2">
                  <label
                    htmlFor="password"
                    className="rounded-md text-Text font-[500] outline-none w-full font-mulish"
                  >
                    Password:
                  </label>
                  <div
                    className={`relative bg-blue-gray-200 rounded-md custom-input ${errors.password
                      ? "focus:border-red-500 focus:ring-red-500 border-red-500 border-2"
                      : isFocused
                        ? "focus:border-indigo-500 focus:ring-indigo-500 border-indigo-500 border-2"
                        : ""
                      }`}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                  >
                    <input
                      type={passwordEye === false ? "password" : "text"}
                      id="password"
                      color="indigo"
                      className="rounded-md py-5 outline-none w-full  px-4 font-mulish"
                      {...register("password", {
                        required: "Password is required*",
                      })}
                    />
                    <div className="absolute top-5 right-5">
                      {passwordEye === false ? (
                        <IoEyeOff
                          size={25}
                          onClick={handelPassword}
                          className="text-[#757575]"
                        />
                      ) : (
                        <IoEye
                          size={25}
                          onClick={handelPassword}
                          className="text-[#757575]"
                        />
                      )}
                    </div>
                  </div>
                  <p className="text-red-900 mb-[-15px] font-mulish">
                    {errors.password?.message}
                  </p>
                </div>

                <div className="w-full items-center flex justify-center mt-9">
                  {!loading && (
                    <Button
                      type="submit"
                      className="login-bg  lg:w-[720px] md:w-full sm:w-full w-full py-5 rounded-md tracking-wider text-[15px] outline-none
                
              "
                    >
                      Login
                    </Button>
                  )}

                  {loading && (
                    <div className="flex items-center gap-2 login-bg text-white w-full justify-center py-5 tracking-wider text-[15px] outline-none">
                      <Spinner className="h-4 w-4" />
                      <p>Loading</p>
                    </div>
                  )}
                </div>
              </form>
              <div className="leading-8 mt-1">
                <Link to="/forgetPassword">
                  <p className="text-center font-mulish underline">
                    Forgot your password?
                  </p>
                </Link>
                <p className="text-center font-mulish mt-2">
                  Don't have an account?{" "}
                  <Link to="/register" className="font-bold">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
            {/* <div className="login-bg h-[450px] w-full flex flex-col justify-center items-center px-3 ">
              <img
                src="https://scontent.xx.fbcdn.net/v/t1.15752-9/423619479_376488498650541_1500579386094849696_n.png?stp=dst-png_p206x206&_nc_cat=102&cb=99be929b-b574a898&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FWxiQ0qwo9MAX9qXebF&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdTamXE28wkcU7LX9rtmgfHJMwbFxf7E2pwULJkCcsj6sA&oe=66124664"
                alt="login"
                className="w-72 mt-[-30px]"
              />
              <p className="text-center text-white font-bold font-mulish">
                We digitize and centralize all business activities for
                streamlined management.
              </p>
            </div> */}
          </div>
        </section>
      )}
    </>
  );
};

export default LoginForm;
