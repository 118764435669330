import { Step, Stepper } from "@material-tailwind/react";
import React from "react";

interface StepperProps {
  activeStep: number;
  setIsLastStep: (value: boolean) => void;
  setIsFirstStep: (value: boolean) => void;
  steps: string[];
}

const ResponsiveStepper = ({
  steps,
  activeStep,
  setIsLastStep,
  setIsFirstStep,
}: StepperProps) => {
  return (
    <div className="w-full p-4 ">
      {/* Desktop View */}
      <div className="hidden md:block">
        <Stepper
          className="mb-2"
          activeStep={activeStep}
          isLastStep={(value) => setIsLastStep(value)}
          isFirstStep={(value) => setIsFirstStep(value)}
        >
          {steps.map((_, index) => (
            <Step key={index} className="h-4 w-4" />
          ))}
        </Stepper>

        <div className="flex justify-between items-center">
          {steps.map((stepLabel, index) => (
            <div
              key={index}
              className={`cursor-pointer font-medium text-sm transition-colors
                ${activeStep === index ? "text-purple-600" : "text-gray-400"}
                ${stepLabel === "Description" ? "ml-1" : ""}
              `}
            >
              {stepLabel}
            </div>
          ))}
        </div>
      </div>

      {/* Mobile View */}
      <div className="md:hidden">
        <div className="flex items-center justify-center mb-2">
          <Stepper
            className="w-full max-w-xs"
            activeStep={activeStep}
            isLastStep={(value) => setIsLastStep(value)}
            isFirstStep={(value) => setIsFirstStep(value)}
          >
            {steps.map((_, index) => (
              <Step key={index} className="h-3 w-3" />
            ))}
          </Stepper>
        </div>

        <div className="text-center">
          <span
            className={`text-sm font-medium ${
              activeStep ? "text-purple-600" : "text-gray-400"
            }`}
          >
            {steps[activeStep]}
          </span>
          <span className="text-gray-400 text-sm ml-2">
            ({activeStep} of {steps.length})
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveStepper;
