import React, { useEffect, useState } from "react";
import Edit from "../assets/image/edit.png";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  ButtonGroup,
  Spinner,
} from "@material-tailwind/react";
import SellerAccount from "../components/forms/accountandsetting/profile/SellerAccount";
import BusinessAccount from "../components/forms/accountandsetting/profile/BusinessAccount";
import BankAccount from "../components/forms/accountandsetting/profile/BankAccount";
import { async } from "q";
import { RxCross2 } from "react-icons/rx";
import { RootBusinessProfile, getBusinessProfile } from "../utils/business";
import SubBusinessList from "../components/details/SubBusinessList";
import SubBusinessForm from "../components/forms/accountandsetting/profile/SubBusinessForm";
const AccountAndSettings = () => {
  const [tabActive, setTabActive] = useState(1);
  const [open, setOpen] = useState(false);
  const [businessProfile, setBusinessProfile] = useState<RootBusinessProfile>();
  const [size, setSize] = React.useState(null);
  const [refetching, setRefetching] = useState(false);
  const [size1, setSize1] = React.useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpen1 = (value: any) => setSize1(value);

  const handleOpen = (value: any) => {
    setSize(value);
    setRefetching(!refetching);
  };

  const handleTab = (tabs: any) => {
    setTabActive(tabs);
  };
  useEffect(() => {
    (async () => {
      let data = await getBusinessProfile();
      setLoading(false);
      setBusinessProfile(data);
    })();
    setLoading(true);
  }, [refetching]);
  return (
    <section className="max-w-7xl m-auto ">
      <div className="w-full flex justify-end py-3">
        <Button
          className="bg-bluePostaam-600 w-auto md:w-[178px] outline-none  rounded-md  md:py-4 "
          onClick={() => handleOpen1("xl")}
        >
          Add Business
        </Button>
      </div>
      <div className="grid  cardstyle space-x-5">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full text-center md:text-left md:w-1/4 bg-white box-shadow md:p-5 p-0 rounded-lg h-full md:gap-2 gap-0 text-nowrap flex justify-between md:justify-start flex-row md:flex-col">
            <p
              className={`p-3 w-full rounded-md cursor-pointer transition-all duration-300 ${tabActive === 1 ? "bg-bluePostaam-600 text-white" : ""
                }`}
              onClick={() => handleTab(1)}
            >
              Seller Account
            </p>
            <p
              className={`p-3 w-full rounded-md cursor-pointer transition-all duration-300 ${tabActive === 2 ? "bg-bluePostaam-600 text-white" : ""
                }`}
              onClick={() => handleTab(2)}
            >
              Business Info
            </p>
            <p
              className={`p-3 w-full rounded-md cursor-pointer transition-all duration-300 ${tabActive === 3 ? "bg-bluePostaam-600 text-white" : ""
                }`}
              onClick={() => handleTab(3)}
            >
              Bank Details
            </p>
          </div>

          {tabActive === 1 && (
            <div className="w-full md:w-3/4">
              {loading && (
                <div className=" flex justify-center items-center h-[60vh]">
                  <Spinner
                    className="h-16 w-16 text-gray-600 font-mulish/50"
                    color="blue"
                  />
                </div>
              )}
              {!loading && (
                <div className="w-full md:w-3/4">
                  <div className="bg-white box-shadow p-3 md:p-5 rounded-lg flex justify-between space-x-5">
                    <div className="w-full">
                      <div className="flex justify-between items-center flex-row">
                        <h4 className="SectionHeader">Seller Account</h4>
                        <div onClick={() => handleOpen("xl")}>
                          <img
                            src={Edit}
                            alt="edit"
                           className="w-10 h-10 object-contain cursor-pointer"
                          />
                        </div>
                      </div>
                      <div className="space-y-4">
                        <div className="flex gap-2 flex-col md:flex-row w-full">
                          <h5 className="font-bold font-Roboto text-gray-700 w-6/12  ">
                            ID Seller
                          </h5>
                          <p className=" text-base text-gray-600 font-mulish  uppercase">
                            {businessProfile?.results._id}
                          </p>
                        </div>

                        <div className="flex gap-2 flex-col md:flex-row w-full">
                          <h5 className="font-bold font-Roboto text-gray-700 w-6/12 ">
                            Full Name
                          </h5>
                          <p className=" text-base text-gray-600 font-mulish  ">
                            {businessProfile?.results.ownerName
                              ? businessProfile.results.ownerName
                              : "Not available"}
                          </p>
                        </div>
                        <div className="flex gap-2 flex-col md:flex-row w-full">
                          <h5 className="font-bold font-Roboto text-gray-700  w-6/12 ">
                            Contact Number
                          </h5>
                          <p className=" text-base text-gray-600 font-mulish ">
                            {businessProfile?.results.phoneNumber
                              ? businessProfile.results.phoneNumber
                              : "Not available"}
                          </p>
                        </div>
                        <div className="flex gap-2 flex-col md:flex-row w-full">
                          <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                            Email Address
                          </h5>
                          <p className=" text-base text-gray-600 font-mulish ">
                            {businessProfile?.results.email}
                          </p>
                        </div>
                        <div className="flex gap-2 flex-col md:flex-row w-full">
                          <h5 className="font-bold font-Roboto text-gray-700 w-6/12 ">
                            Bio
                          </h5>
                          <p className=" text-base text-gray-600 font-mulish ">
                            {businessProfile?.results.bio
                              ? businessProfile.results.bio
                              : "Not available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {tabActive === 2 && (
            <div className="w-full md:w-3/4">
              <div className="bg-white box-shadow p-3 md:p-5 rounded-lg flex justify-between space-x-5">
                <div className="w-full">
                  <div className="flex justify-between items-center flex-row">
                    <h4 className="SectionHeader">Business Information</h4>
                    <div onClick={() => handleOpen("xl")}>
                      <img
                        src={Edit}
                        alt="edit"
                       className="w-10 h-10 object-contain cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="flex gap-2  md:flex-row w-full flex-col">
                      <div className=" text-base text-gray-600 font-mulish  ">
                        <img
                          src={businessProfile?.results.profileFile}
                          alt=""
                          className="w-28 h-28 rounded-md border-[1px] border-gray-300 p-1"
                        />
                      </div>
                    </div>
                    <div className="bg-gray-200 py-2 px-2 rounded-md">
                      <h4 className="font-medium text-lg">Business Details</h4>
                    </div>
                    <div className="flex flex-col md:flex-row  gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Legal Name
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.businessName}
                      </p>
                    </div>

                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Owner
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.ownerName
                          ? businessProfile.results.ownerName
                          : "Not available"}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Business Registration No.
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.registrationNumber
                          ? businessProfile.results.registrationNumber
                          : "Not available"}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Business PAN/VAT
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.vatNumber
                          ? businessProfile.results.vatNumber
                          : "Not available"}
                      </p>
                    </div>
                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Business Category
                      </h5>
                      <div className=" text-base text-gray-600 font-mulish  flex gap-2 flex-wrap">
                        {businessProfile?.results.categories.map(
                          (category, index) => {
                            return (
                              <div key={index} className="">
                                <p className="text-sm">
                                  {index > 0 && <span className="mx-1">|</span>}
                                  {category.name} - ({category.percentage}%)
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Address
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.address
                          ? businessProfile.results.address
                          : "N/A"}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Area
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.area
                          ? businessProfile.results.area
                          : "N/A"}
                      </p>
                    </div>

                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Region
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.region
                          ? businessProfile.results.region
                          : "N/A"}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Zone
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.zone
                          ? businessProfile.results.zone
                          : "N/A"}
                      </p>
                    </div>

                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Country
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.country
                          ? businessProfile.results.country
                          : "N/A"}
                      </p>
                    </div>
                    <div className="bg-gray-200 py-2 px-2 rounded-md">
                      <h4 className="font-medium text-lg">
                        Person Incharge Details
                      </h4>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Person in Charge
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.personInChargeName
                          ? businessProfile.results.personInChargeName
                          : "N/A"}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Person in Charge Email
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.personInChargeEmail
                          ? businessProfile.results.personInChargeEmail
                          : "N/A"}
                      </p>
                    </div>
                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <h5 className="font-bold font-Roboto text-gray-700  w-6/12">
                        Person in Charge Phone
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.personInChargePhone
                          ? businessProfile.results.personInChargePhone
                          : "N/A"}
                      </p>
                    </div>

                    <div className="bg-gray-200 py-2 px-2 rounded-md">
                      <h4 className="font-medium text-lg">Image Documents</h4>
                    </div>
                    <div className="flex gap-2 flex-col md:flex-row w-full flex-wrap">
                      <div className=" text-base text-gray-600 font-mulish">
                        <div className="rounded-md border-[1px] border-gray-300 p-2">
                          <img
                            src={businessProfile?.results.registerationFile}
                            alt=""
                            className=" h-60 object-contain"
                          />
                        </div>
                      </div>
                      <div className=" text-base text-gray-600 font-mulish">
                        <div className=" ">
                          <img
                            src={businessProfile?.results.vatFile}
                            alt=""
                            className=" h-64 object-contain border-[1px] border-gray-300 p-2 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SubBusinessList />
            </div>
          )}
          {tabActive === 3 && (
            <div className="w-full md:w-3/4">
              <div className="bg-white box-shadow p-3 md:p-5 rounded-lg flex justify-between space-x-5">
                <div className="w-full">
                  <div className="flex justify-between items-center flex-row">
                    <h4 className="SectionHeader">Bank Account</h4>
                    <div onClick={() => handleOpen("xl")}>
                      <img
                        src={Edit}
                        alt="edit"
                       className="w-10 h-10 object-contain cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <h5 className="font-bold font-Roboto text-gray-700 w-6/12">
                        Account Title
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.accountName}
                      </p>
                    </div>

                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <h5 className="font-bold font-Roboto text-gray-700 w-6/12">
                        Account Number
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.accountNumber}
                      </p>
                    </div>
                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <h5 className="font-bold font-Roboto text-gray-700 w-6/12 ">
                        Bank Name
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.bankName}
                      </p>
                    </div>
                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <h5 className="font-bold font-Roboto text-gray-700 w-6/12 ">
                        Branch Name
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.branchName}
                      </p>
                    </div>
                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <h5 className="font-bold font-Roboto text-gray-700 w-6/12">
                        Bank Code
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.bankCode}
                      </p>
                    </div>
                    <div className="flex gap-2 flex-col md:flex-row w-full">
                      <h5 className="font-bold font-Roboto text-gray-700 w-6/12">
                        IBAN
                      </h5>
                      <p className=" text-base text-gray-600 font-mulish ">
                        {businessProfile?.results.iban}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={size === "xl"}
        size={size || "xl"}
        handler={() => setOpen(false)}
        dismiss={{ enabled: false }}
      >
        <div
          onClick={handleOpen}
          className="flex justify-end items-center align-middle px-3 py-3.5 cursor-pointer absolute right-0 bg-gray-500 rounded-full text-white h-11 w-11 m-4"
        >
          <RxCross2 size={24} />
        </div>
        {tabActive === 1 && (
          <SellerAccount
            businessProfile={businessProfile!}
            handleOpen={handleOpen}
          />
        )}
        {tabActive === 2 && (
          <BusinessAccount
            businessProfile={businessProfile!}
            handleOpen={handleOpen}
          />
        )}
        {tabActive === 3 && (
          <BankAccount
            businessProfile={businessProfile!}
            handleOpen={handleOpen}
          />
        )}
      </Dialog>
      <Dialog open={size1 === "xl"} size={size1 || "xl"} handler={handleOpen1}>
        <div
          onClick={handleOpen1}
          className="flex justify-end items-center align-middle px-3 py-3.5 cursor-pointer absolute right-0 bg-gray-500 rounded-full text-white h-11 w-11 m-4"
        >
          <RxCross2 size={24} />
        </div>
        <SubBusinessForm
          businessProfile={businessProfile!}
          handleOpen1={handleOpen1}
        />
      </Dialog>
    </section>
  );
};

export default AccountAndSettings;
