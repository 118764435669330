import { Button, Dialog, Spinner } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import {
  CountryListProps,
  ProvinceListProps,
  RootBusinessProfile,
  RootSubBusinessProfileUpdate,
  SubBusiness,
  getBusinessProfile,
  getCountryList,
  getProvinceList,
  putSubBusiness,
} from "../../../utils/business";

import {
  ResultProductCategory,
  getProductCategory,
} from "../../../utils/product";

type FormValue = {
  businessName: string;
  email: string;
  role: string;
  connectedHandle: string;
  ownerName: string;
  country: string;
  region: string;
  zone: string;
  city: string;
  area: string;
  address: string;
  personInChargeName: string;
  personInChargePhone: string;
  personInChargeEmail: string;
  registrationNumber: string;
  registerationFileUrl: string;
  profileFileUrl: string;
  categories: string;
  registerationFile: File[];
  _id: string;
};

interface subBusiness {
  businessProfile: SubBusiness;
  handleOpen: any;
}

const SubBusinessEdit = ({ businessProfile, handleOpen }: subBusiness) => {
  const [loading, setLoading] = useState(false);
  const [loadingP, setLoadingP] = useState(true);
  const form = useForm<FormValue>();
  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;
  const [countryList, setCountryList] = useState<CountryListProps[]>([]);
  const [provinceList, setProvinceList] = useState<ProvinceListProps[]>([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
  const [selectedBusinessFiles, setSelectedBusinessFiles] = useState<File[]>(
    []
  );
  const [businessinfo, setBusinessInfo] = useState<RootBusinessProfile>();
  const [cunt, setCunt] = useState("");
  const [categories, setCategories] = useState<ResultProductCategory[]>();
  const [productId, setProductId] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        let resCountry = await getCountryList();
        setCountryList(resCountry);

        let resProvince = await getProvinceList();
        setProvinceList(resProvince);

        if (businessProfile._id != "") {
          setValue("businessName", businessProfile.businessName);
          setValue("email", businessProfile.email);
          setValue("personInChargeName", businessProfile.personInChargeName);
          setValue("personInChargePhone", businessProfile.personInChargePhone);
          setValue("personInChargeEmail", businessProfile.personInChargeEmail);
          setValue("country", businessProfile.country);
          setValue("region", businessProfile.region);
          setValue("zone", businessProfile.zone);
          setValue("area", businessProfile.area);
          setValue("address", businessProfile.address);
          setValue("registrationNumber", businessProfile.registrationNumber);
          businessProfile.categories.length > 0 &&
            setValue("categories", businessProfile.categories[0].name);
          setValue("_id", businessProfile._id);
          setLoadingP(false);
        }
      } catch (error) {
        console.log("Error fetching seller account:", error);
      }
    })();
  }, [setValue]);
  const onsubmit = async (data: FormValue) => {
    if (!loading) {
      setLoading(true);
      try {
        const formData = new FormData();
        businessProfile._id != "" &&
          formData.append("_id", businessProfile._id);
        formData.append("businessName", data.businessName);
        formData.append("personInChargeName", data.personInChargeName);
        formData.append("personInChargePhone", data.personInChargePhone);
        formData.append("personInChargeEmail", data.personInChargeEmail);
        formData.append("country", data.country);
        formData.append("region", data.region);
        formData.append("zone", data.zone);
        formData.append("city", data.city);
        formData.append("area", data.area);
        formData.append("email", data.email);
        data.categories &&
          formData.append("categories", JSON.stringify([data.categories]));
        formData.append("address", data.address);
        formData.append("registrationNumber", data.registrationNumber);

        if (selectedBusinessFiles && selectedBusinessFiles.length > 0) {
          const businessimagesArray = Array.from(selectedBusinessFiles);
          businessimagesArray.forEach((registerationFile) => {
            formData.append("registerationFile", registerationFile);
          });
        }

        const apiResponse: RootSubBusinessProfileUpdate = await putSubBusiness(
          formData
        );
        const productId = apiResponse.data.results._id;
        setProductId(productId);
        setLoading(false);
        handleOpen();
        toast.success(apiResponse.data.message);
        // if (apiResponse.status === 200) {
        //   handleOpen();
        //   toast.success(apiResponse.data.message);
        // } else {
        //   setOpen(true);
        // }
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      let data = await getBusinessProfile();
      setBusinessInfo(data);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      let data = await getProductCategory();
      setCategories(data.results);
    })();
  }, []);
  const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      let imageCount = 0;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type.split("/")[0] === "image") {
          imageCount++;
        }
      }

      if (imageCount + selectedImageFiles.length <= 1) {
        const newImages: File[] = [];

        for (let i = 0; i < files.length; i++) {
          if (files[i].type.split("/")[0] === "image") {
            newImages.push(files[i]);
          }
        }

        setSelectedImageFiles((prevImages) => [...prevImages, ...newImages]);
      } else {
        alert("You can only select up to 1 images.");
      }
    }
  };
  const handleBusinessFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      let businessimageCount = 0;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type.split("/")[0] === "image") {
          businessimageCount++;
        }
      }

      if (businessimageCount + selectedBusinessFiles.length <= 2) {
        const newBusinessImages: File[] = [];

        for (let i = 0; i < files.length; i++) {
          if (files[i].type.split("/")[0] === "image") {
            newBusinessImages.push(files[i]);
          }
        }

        setSelectedBusinessFiles((prevImages) => [
          ...prevImages,
          ...newBusinessImages,
        ]);
      } else {
        alert("You can only select up to 1 images.");
      }
    }
  };

  const handlePreviewBusinessDelete = (index: number) => {
    const datas = selectedBusinessFiles;
    datas.splice(index, 1);
    setSelectedBusinessFiles([...datas]);
  };

  return (
    <section>
      <div className="flex justify-between items-center">
        <h4 className="popupHeader">Update Business Information</h4>
      </div>
      {loadingP && (
        <div className="flex justify-center items-center h-96">
          <Spinner className="h-16 w-16 text-gray-600/50" color="blue" />
        </div>
      )}
      {!loadingP && (
        <form
          className="   px-4 md:px-8  pb-4  md:pb-7 max-h-[80vh] overflow-auto formStyle"
          onSubmit={handleSubmit(onsubmit)}
        >
          <div className=" ">
            <div className="grid grid-cols-1 md:grid-cols-2 overflow-auto  lg:grid-cols-3 gap-4 ">
              <div className="">
                <label htmlFor="Fname" className="">
                  Legal Name:
                </label>
                <input
                  type="text"
                  readOnly
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("businessName", {
                    required: "Business Name is required",
                  })}
                />
                <p className="text-red-500">{errors.businessName?.message}</p>
              </div>
              <div className="">
                <label htmlFor="charge" className="">
                  Person in Charge:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("personInChargeName")}
                />
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Person in Charge Mobile Number:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("personInChargePhone")}
                />
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Person in Charge Email:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("personInChargeEmail")}
                />
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Country <span className="text-red-500">*</span>:
                </label>
                <select
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("country", {
                    required: "Country is required",
                  })}
                  onChange={(e) => {
                    setCunt(e.target.value);
                  }}
                >
                  {countryList.map((country) => (
                    <option value={country.name}>{country.name}</option>
                  ))}
                </select>
                <p className="text-red-500">{errors.country?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Region <span className="text-red-500">*</span>:
                </label>
                {cunt == "Nepal" && (
                  <select
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("region", {
                      required: "Region is required",
                    })}
                  >
                    {provinceList.map((province) => (
                      <option value={province.name}>{province.name}</option>
                    ))}
                  </select>
                )}
                {cunt != "Nepal" && (
                  <input
                    type="text"
                    className="border-2 w-full py-3 px-3 outline-none rounded-md"
                    {...register("region", {
                      required: "Region is required",
                    })}
                  />
                )}
                <p className="text-red-500">{errors.region?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Zone <span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("zone", { required: "Zone is required" })}
                />
                <p className="text-red-500">{errors.zone?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Area <span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("area", { required: "Area is required" })}
                />
                <p className="text-red-500">{errors.area?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Address<span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("address", {
                    required: "Address is required",
                  })}
                />
                <p className="text-red-500">{errors.address?.message}</p>
              </div>

              <div className="">
                <label htmlFor="email" className="">
                  Business PAN/VAT
                  <span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  readOnly
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("registrationNumber", {
                    required: "Registration Number is required",
                  })}
                />
                <p className="text-red-500">
                  {errors.registrationNumber?.message}
                </p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Email
                  <span className="text-red-500">*</span>:
                </label>
                <input
                  type="text"
                  readOnly
                  className="border-2 w-full py-3 px-3 outline-none rounded-md"
                  {...register("email", {
                    required: "Email is required",
                  })}
                />
                <p className="text-red-500">{errors.email?.message}</p>
              </div>
              <div className="">
                <label htmlFor="email" className="">
                  Category<span className="text-red-500">*</span> :
                </label>
                <select
                  className="border-2 w-full py-3  px-3 outline-none rounded-md"
                  {...register("categories", {
                    required: "Category is required",
                  })}
                >
                  <option value="" selected disabled>
                    Select Category
                  </option>
                  {categories?.map((category, index) => {
                    return (
                      <option value={category.name} key={index}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
                <p className="text-red-500">{errors.categories?.message}</p>
              </div>

              {/* Registration Image Upload */}
            </div>
            {/* <div className="">
              <div className="mt-4 ">
                <label htmlFor="image" className="">
                  Upload Business Document:
                </label>
                <label htmlFor="imageBusiness">
                  <div className="border-2 border-dashed cursor-pointer border-gray-300 w-full rounded-md h-40 overflow-hidden ">
                    <div className="flex flex-col justify-center h-full items-center">
                      <AiOutlineVideoCameraAdd size={40} />
                      <p>Click to select files</p>
                    </div>
                  </div>
                </label>
                <input
                  type="file"
                  onChange={handleBusinessFile}
                  accept="image/*"
                  id="imageBusiness"
                  multiple={true}
                  className="hidden"
                />
              </div>
              <div className="flex flex-wrap gap-3">
                <div className="flex gap-10 mt-4">
                  <div>
                    <p className="text-[12px] font-[300]">Previous Image</p>
                    <img
                      src={businessinfo?.results.registerationFile}
                      alt=""
                      className="w-20 h-20 rounded-md"
                    />
                  </div>
                  <div>
                    {selectedBusinessFiles.length > 0 && (
                      <p className="text-[12px] font-[300]">Newer Image</p>
                    )}
                    {selectedBusinessFiles.map((businesssImg, index) => {
                      return (
                        <div className="w-20 h-20 ">
                          {businesssImg.type.startsWith("image") && (
                            <div key={index} className="relative">
                              <img
                                src={URL.createObjectURL(businesssImg)}
                                className="w-full h-20 rounded-md object-cover"
                                // alt={`Image ${index + 1}`}
                                {...register("registerationFile")}
                              />

                              <div
                                className="absolute top-[-5px] p-1 rounded-full cursor-pointer bg-red-500 right-[-3px]"
                                onClick={() =>
                                  handlePreviewBusinessDelete(index)
                                }
                              >
                                <RxCross2 size={15} color="white" />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="w-full flex justify-end">
            <Button type="submit" className="submitButton">
              <div>{loading ? "Loading" : "Update"}</div>
            </Button>
          </div>
        </form>
      )}
    </section>
  );
};
export default SubBusinessEdit;
