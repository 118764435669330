import { Button, Dialog } from "@material-tailwind/react";
import { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { set, useForm } from "react-hook-form";

import {
  RootBusinessProfile,
  RootBusinessProfileUpdate,
  getBusinessProfile,
  putSellerAccount,
} from "../../../../utils/business";
import { RxCross2 } from "react-icons/rx";
type FormValue = {
  accountName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  bankCode: string;
  iban: string;
  vatFile: string;
};
interface BankAccountProps {
  businessProfile: RootBusinessProfile;
  handleOpen: any;
}
const BankAccount = ({ businessProfile, handleOpen }: BankAccountProps) => {
  const [open, setOpen] = useState(false);
  const [businessinfo, setBusinessInfo] = useState<RootBusinessProfile>();
  const form = useForm<FormValue>();
  const [otp, setOtp] = useState("");
  const { register, handleSubmit, formState, setValue } = form;
  const { errors } = formState;
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [iban, setIban] = useState("");

  useEffect(() => {
    setValue("accountName", businessProfile.results.accountName);
    setValue("accountNumber", businessProfile.results.accountNumber);
    setValue("bankName", businessProfile.results.bankName);
    setValue("branchName", businessProfile.results.branchName);
    setValue("bankCode", businessProfile.results.bankCode);
    setValue("iban", businessProfile.results.iban);
  }, []);

  const onsubmit = async (data: FormValue) => {
    try {
      const formData = new FormData();
      formData.append("accountName", data.accountName);
      formData.append("accountNumber", data.accountNumber);
      formData.append("bankName", data.bankName);
      formData.append("branchName", data.branchName);
      formData.append("bankCode", data.bankCode);
      formData.append("iban", data.iban);

      setAccountName(data.accountName);
      setAccountNumber(data.accountNumber);
      setBankName(data.bankName);
      setBranchName(data.branchName);
      setBankCode(data.bankCode);
      setIban(data.iban);

      const apiResponse: RootBusinessProfileUpdate = await putSellerAccount(
        formData
      );
      if (apiResponse.status === 200) {
        handleOpen();
        toast.success(apiResponse.data.message);
      } else {
        setOpen(true);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleVerifincation = async () => {
    try {
      const formData = new FormData();
      formData.append("accountName", accountName);
      formData.append("accountNumber", accountNumber);
      formData.append("bankName", bankName);
      formData.append("branchName", branchName);
      formData.append("bankCode", bankCode);
      formData.append("iban", iban);

      formData.append("otp", otp);
      const apiResponse: RootBusinessProfileUpdate = await putSellerAccount(
        formData
      );
      if (apiResponse.status === 200) {
        handleOpen();
        toast.success(apiResponse.data.message);
      } else {
        setOpen(true);
        toast.success(apiResponse.data.message);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    (async () => {
      let data = await getBusinessProfile();
      setBusinessInfo(data);
    })();
  }, []);

  return (
    <section>
      <h4 className="popupHeader">Bank Account</h4>
      <form className=" px-4 md:px-8  pb-4  md:pb-7 formstyle overflow-y-auto h-full max-h-[80vh]" onSubmit={handleSubmit(onsubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="flex space-y-2 flex-col ">
            <label htmlFor="Fname" className="">
              Account Name:
            </label>
            <input type="text" className="border-2 w-full py-3 px-3 outline-none rounded-md" {...register("accountName")} />
          </div>
          <div className="flex space-y-2 flex-col ">
            <label htmlFor="contact" className="">
              Account Number:
            </label>
            <input type="text" className="border-2 w-full py-3 px-3 outline-none rounded-md" {...register("accountNumber")} />
          </div>
          <div className="flex space-y-2 flex-col ">
            <label htmlFor="bank" className="">
              Bank Name:
            </label>
            <input type="text" className="border-2 w-full py-3 px-3 outline-none rounded-md" {...register("bankName")} />
          </div>
          <div className="flex space-y-2 flex-col ">
            <label htmlFor="branch" className="">
              Branch Name<span className="text-red-500">*</span>:
            </label>
            <input
              type="text"
              className="border-2 w-full py-3 px-3 outline-none rounded-md"
              {...register("branchName", {
                required: "Branch Name is required",
              })}
            />
            <p className="text-red-500">{errors.branchName?.message}</p>
          </div>
          <div className="flex space-y-2 flex-col ">
            <label htmlFor="code" className="">
              Bank Code<span className="text-red-500">*</span>:
            </label>
            <input
              type="text"
              className="border-2 w-full py-3 px-3 outline-none rounded-md"
              {...register("bankCode", {
                required: "Bank Code is required",
              })}
            />
            <p className="text-red-500">{errors.bankCode?.message}</p>
          </div>
          <div className="flex space-y-2 flex-col ">
            <label htmlFor="code" className="">
              IBAN<span className="text-red-500">*</span>:
            </label>
            <input
              type="text"
              className="border-2 w-full py-3 px-3 outline-none rounded-md"
              {...register("iban", { required: "IBAN is required" })}
            />
            <p className="text-red-500">{errors.iban?.message}</p>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <Button type="submit" className="submitButton">
            Submit
          </Button>
        </div>
      </form>
      <Dialog
        open={open}
        handler={() => setOpen(false)}
        dismiss={{ enabled: false }}
      >
        <div>
          <div className="flex justify-between items-center pt-4 px-9">
            <p className="text-[25px] text-gray-800 ">Verify</p>

            <div
              className="p-2 rounded-full cursor-pointer bg-[#9E9E9E] text-white flex justify-center"
              onClick={() => setOpen(false)}
            >
              <RxCross2 size={24} />
            </div>
          </div>
          <form>
            <div className="py-3 px-9">
              <label htmlFor="otp" className="">
                Verify your code via Email to continue:
              </label>
              <input
                type="text"
                className="border-2 w-full py-3 px-3 outline-none rounded-md"
                onInput={(e) => setOtp(e.currentTarget.value)}
              />
            </div>

            <div className="flex justify-end items-end py-3 px-9">
              <Button
                type="button"
                className="submitButton"
                disabled={otp.length < 6}
                onClick={handleVerifincation}
              >
                Verify
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </section>
  );
};
export default BankAccount;
