import { useEffect, useState } from "react";
import { posts } from "../../../utils/dummyAffiliatedPostData";
import PostCard from "./PostCard";
import { useParams } from "react-router-dom";

function AffiliatedPost() {
  const { id } = useParams();

  return (
    <div>
      <div
        style={{ boxShadow: "0 0 10px #0000001a" }}
        className="  rounded-[0.5rem] bg-white flex flex-row justify-between md:p-5 p-3 items-center mb-5"
      >
        <h3 className="text-gray-800 text-[25px] font-[600] font-mulish">
          Affiliated Posts
        </h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4">
        {posts.map((post, i) => (
          <PostCard item={post} key={i} />
        ))}
      </div>
    </div>
  );
}

export default AffiliatedPost;
