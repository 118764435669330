import { Card, Carousel, Spinner } from "@material-tailwind/react";
import { Media, Post } from "../../utils/post";

type PostCardProps = {
    post: Post;
}

const PostCard = ({ post }: PostCardProps) => {

    const renderMedia = (medias: Media[]) => {
        if (medias.length === 1) {
            const mediaLink = medias[0].mediaLink;
            const fileExtension = mediaLink.split(".").pop();
            if (
                fileExtension === "mp4" ||
                fileExtension === "avi" ||
                fileExtension === "mov" ||
                fileExtension === "gif"
            ) {
                return (
                    <video controls className="w-full h-[30rem]">
                        <source src={mediaLink} />
                    </video>
                );
            } else if (
                fileExtension === "jpg" ||
                fileExtension === "jpeg" ||
                fileExtension === "png"
            ) {
                return (
                    <img
                        src={mediaLink}
                        alt="Post Photo"
                        className="w-full h-[30rem] min-h-[30rem] object-contain"
                    />
                );
            } else {
                return <p className="text-white">Unsupported Media Type</p>;
            }
        } else {
            return (
                <Carousel className=" w-full overflow-hidden">
                    {medias.map((media, index) => (
                        <div
                            key={index}
                            className=" h-full w-full flex justify-center items-center"
                        >
                            <img
                                src={media.mediaLink}
                                alt={`Image ${index}`}
                                className="w-full"
                            />
                        </div>
                    ))}
                </Carousel>
            );
        }
    };
    return (


        <Card className="rounded-lg bg-white overflow-hidden">
            <div className="">
                <div>
                    <h2>
                        {post.userName}
                    </h2>
                </div>
                <div className="h-[30rem] flex justify-center items-center bg-black overflow-hidden">
                    {renderMedia(post.medias)}
                </div>
                <div className="p-5 space-y-3">
                    <h3 className="text-xl font-medium text-gray-800 capitalize">
                        {post.postName}
                    </h3>
                    <h5 className="text-base font-normal text-white rounded-full capitalize bg-bluePostaam-600 w-20 text-center py-0.5">
                        {post.postType}
                    </h5>
                    <div className="grid xl:grid-cols-4 grid-cols-2 gap-2 DashboardCard">
                        <div className="CardStats">
                            <h4 className="text-center font-medium text-lg text-bluePostaam-700">
                                {post.views}
                            </h4>
                            <p className="text-center font-medium text-[0.8rem] text-gray-700 ">
                                Views
                            </p>
                        </div>
                        <div className="CardStats">
                            <h4 className="text-center font-medium text-lg text-bluePostaam-700">
                                {post.likes}
                            </h4>
                            <p className="text-center font-medium text-[0.8rem] text-gray-700">
                                Likes
                            </p>
                        </div>
                        <div className="comment_class flex flex-col justify-center items-center">
                            <h4 className="text-center font-medium text-lg text-bluePostaam-700">
                                {post.comments}
                            </h4>
                            <p className="text-center  font-medium text-[0.8rem] text-gray-700">
                                Comment
                            </p>
                        </div>
                        <div className="CardStats">
                            <h4 className="text-center font-medium text-lg text-bluePostaam-700">
                                {post.shares}
                            </h4>
                            <p className="text-center font-medium text-[0.8rem] text-gray-700">
                                Shares
                            </p>
                        </div>
                    </div>

                    <div className="w-full">
                        <h4 className="text-xl font-medium text-gray-800 capitalize pb-2">
                            Tags
                        </h4>
                        <div className="flex flex-wrap w-full gap-2 h-24 overflow-scroll hide-scrollbar">
                            {post.tags.map((tag, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="bg-bluePostaam-500 px-3 py-1 text-white rounded-full text-sm"
                                    >
                                        #{tag.tagName}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <div className="w-full">
                        <h4 className="text-xl font-medium text-gray-800 capitalize pb-2">
                            Tagged User
                        </h4>
                        <div className="flex flex-wrap w-full gap-2 h-24 overflow-scroll hide-scrollbar">
                            {post.taggedUsers.map((tag, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="bg-bluePostaam-400 px-3 py-1 text-white rounded-full text-sm"
                                    >
                                        {tag.fullname} [{tag.userHandle}]
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default PostCard;