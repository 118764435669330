// import loginFormLogo from "../../assets/image/loginFormLogo.png";
import { Button, Spinner } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { userSignUp } from "../../utils/user";
// import Loading from "../../assets/image/loading.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BusinessCategory, ResultProductCategory } from "../../utils/product";

type FormValue = {
  email: string;
  password: string;
  businessName: string;
  role: string;
  categories: string;
  confirmPws: string;
  // pointOfContact: string;
  // phoneNumber: number;
  // companyEmail: string;
};
const Register = () => {
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const [isConfirmPwsEyeOpen, setIsConfirmPwsEyeOpen] = useState(false);
  const [categories, setCategories] = useState<ResultProductCategory[]>([]);
  const handelIsEyeOpen = () => {
    setIsEyeOpen(!isEyeOpen);
  };
  const handleConfirmEyeOpen = () => {
    setIsConfirmPwsEyeOpen(!isConfirmPwsEyeOpen);
  };
  const form = useForm<FormValue>();
  const [loading, setLoading] = useState(false);
  const { register, control, formState, handleSubmit } = form;
  const { errors } = formState;

  const router = useNavigate();

  const onsubmit = async (data: FormValue) => {
    console.log("Submitted", data);

    setLoading(true);
    // let cata: string[] = [];
    // if (data.categories) {
    //   cata = data.categories;
    // }
    data.categories = JSON.stringify([data.categories]);
    const response = await userSignUp(data);
    if (response.status === 201) {
      toast.success(response.data.message);
      router(`/otp?email=${data.email}`);
    } else {
      toast.error(`Error ${response.data.message}`);
    }
    setLoading(false);
  };
  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [businessNameFocus, setBusinessNameFocus] = useState(false);
  const [categoryNameFocus, setCategoryNameFocus] = useState(false);
  const [roleFocus, setRoleFocus] = useState(false);
  const [pointOfContactFocus, setPointOfContactFocus] = useState(false);
  const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);
  const [companyEmailFocus, setCompanyEmailFocus] = useState(false);
  const [selection, setSelection] = useState("");
  const handleSelect = (event: any) => {
    setSelection(event.target.value);
  };
  useEffect(() => {
    setSelection("business");
  }, []);

  const [pageloading, setPageLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      router("/");
    }
    setPageLoading(false);
  }, [pageloading]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await BusinessCategory();
        setCategories(data.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {pageloading && <div>Loading</div>}
      {!pageloading && (
        <section className="flex flex-col justify-center items-center w-full py-6 h-[100vh] login-background">
          {/* <img src={loginFormLogo} alt="logo" className="w-56 object-contain" /> */}

          <div className="bg-white py-8 overflow-y-auto login-shadow  w-[95%]  md:w-[700px] mt-4 md:mt-8 rounded-md">
            <div className="px-5 pb-4">
              <span className=" font-bold  text-left mt-5">
                <p className="text-[35px] text-bluePostaam-550">Create an account</p>
              </span>
              <form
                // className="py-5 lg:w-[700px] sm:w-full md:w-full w-full lg:px-0 md:px-5 sm:px-5 px-5  flex flex-col justify-center items-center"
                onSubmit={handleSubmit(onsubmit)}
                className="formStyle"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-2 md:gap-4 ">
                  <div className=" w-full space-y-2">
                    <label htmlFor="companyName">Company Name:</label>
                    <div>
                      <input
                        type="text"
                        id="businessName"
                        className="py-5 rounded-md w-full outline-none px-4"
                        {...register("businessName", {
                          required: "Company Name is required*",
                          minLength: {
                            value: 3,
                            message: "Minimum Name Length is 3",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximum Name Length is 90",
                          },
                        })}
                      />
                    </div>
                    <p className="text-red-900 font-mulish mb-[-10px]">
                      {errors.businessName?.message}
                    </p>
                  </div>
                  <div className=" w-full space-y-2">
                    <label htmlFor="email">Email:</label>
                    <div>
                      <input
                        type="text"
                        autoComplete="off"
                        id="email"
                        className="py-5 px-4 rounded-md w-full outline-none"
                        {...register("email", {
                          required: "Email is required*",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message:
                              "Invalid Email Address (Example: test@gmail.com)",
                          },
                        })}
                      />
                    </div>
                    <p className="text-red-900 font-mulish mb-[-10px]">
                      {errors.email?.message}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 item-center  gap-2 md:gap-4">
                  {/* Business Category  started*/}

                  <div className="w-full space-y-2">
                    <label htmlFor="category">Business Category:</label>
                    <div>
                      <select
                        id="category"
                        className="py-5 rounded-md w-full outline-none px-4"
                        {...register("categories", {
                          required: "Category is required*",
                          minLength: {
                            value: 3,
                            message: "Minimum Name Length is 3",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximum Name Length is 90",
                          },
                        })}
                      >
                        <option value="" selected disabled>
                          Select Category
                        </option>
                        {categories.map((category, index) => {
                          return (
                            <option value={category.name} key={index}>
                              {category.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <p className="text-red-900 font-mulish mb-[-10px]">
                      {errors.role?.message}
                    </p>
                  </div>
                  {/* Business Category Ended */}
                  <div className="w-full space-y-2">
                    <label htmlFor="role">Role</label>
                    <div>
                      <select
                        placeholder="Role"
                        id="rold"
                        className="py-5 rounded-md w-full outline-none px-4"
                        {...register("role", {
                          required: "role is required*",
                          minLength: {
                            value: 3,
                            message: "Minimum Name Length is 3",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximum Name Length is 90",
                          },
                        })}
                      >
                        <option value="" selected disabled>
                          Select Role
                        </option>
                        <option value="business">Business</option>
                        <option value="freelancer">Freelancer</option>
                      </select>
                    </div>
                    <p className="text-red-900 font-mulish mb-[-10px]">
                      {errors.role?.message}
                    </p>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className=" w-full relative space-y-2">
                    <label htmlFor="password">Password</label>
                    <div>
                      <input
                        type={isEyeOpen === false ? "password" : "text"}
                        id="password"
                        className="py-5 rounded-md px-4 w-full outline-none"
                        {...register("password", {
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
                            message:
                              "Password must contain at least :  one lowercase letter, one uppercase letter, one digit, and one special character.",
                          },
                          minLength: {
                            value: 6,
                            message: "Password length must be 6",
                          },
                          required: "Password is required*",
                        })}
                      />

                      <div className="absolute top-[39px] right-5">
                        {isEyeOpen === false ? (
                          <IoEye
                            size={25}
                            className="text-[#757575]"
                            onClick={handelIsEyeOpen}
                          />
                        ) : (
                          <IoEyeOff
                            size={25}
                            onClick={handelIsEyeOpen}
                            className="text-[#757575]"
                          />
                        )}
                      </div>
                    </div>
                    <p className="text-red-900 font-mulish mb-[-10px]">
                      {errors.password?.message}
                    </p>
                  </div>
                </div>

                <div className="w-full items-center flex justify-center mt-7">
                  {!loading && (
                    <Button
                      type="submit"
                      className="login-bg  w-[720px] py-5 rounded-md tracking-wider text-[15px] capitalize"
                    >
                      Register
                    </Button>
                  )}
                  {loading && (
                    <div className="flex items-center gap-2 login-bg text-white w-full justify-center py-5 tracking-wider text-[15px] outline-none">
                      <Spinner className="h-4 w-4" />
                      <p>Loading</p>
                    </div>
                  )}
                </div>
              </form>
              <div className="leading-5 mt-3">
                <p className="text-center font-Roboto text-[14.3px] lg:px-0 md:px-5 sm:px-5 px-5">
                  By clicking Register, I agree that I have read and accepted
                  the Postaam <strong>Terms of Use and Privacy Policy</strong>
                </p>
                <p className="text-center font-Roboto text-[14.3px] mt-4">
                  Already have an account?
                  <Link to="/login">
                    <strong>Login</strong>
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* <Link to="/otp">OTP</Link> */}
        </section>
      )}
    </>
  );
};

export default Register;
