import {
  Button,
  Switch,
  Card,
  Stepper,
  Step,
  Checkbox,
  select,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { MdOutlineDelete } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import { GigDelete, RootGig, addGig } from "../../../utils/gig";
import { Results } from "../../../utils/user";
import { Spinner } from "@material-tailwind/react";

import { AiFillDelete } from "react-icons/ai";
// import { GigDelete } from "../../../utils/gitList";
import AddSubGig from "./AddSubGig";
import {
  ResultSearchProduct,
  RootSearchProduct,
  SearchProductName,
} from "../../../utils/product";
import { ProfileAlert } from "../../../utils/business";
import ResponsiveStepper from "../Stepper";
export type FormValueGigAdd = {
  title: string;
  businessName: string;
  businessHandle: string;
  descriptionTitle: string;
  description: string;
  numberofcreator: number;
  numberofimage: number;
  numberofvideo: number;
  submissionTimeframe: number;
  adDuration: number;
  offerAmountFrom: number;
  offerAmount: number;
  offerAmountTo: number;
  requirePosting: Boolean;
  multiplePost: Boolean;
  numberviews: number;
  numberengagements: number;
  searchProduct: string;
  level: string;
  skills: string[];
  tags: string[];
  searchCreator: number;
  gigType: string;
  specifications: Specification[];
  _id: string;
  connectedTo?: string;
};
interface InputItem {
  id: number;
}
type Specification = {
  title: string;
  description: string;
};
type Attribute = {
  key: string;
  value: string;
};
export type SubTaskProps = {
  businessHandle?: string;
  businessName?: string;
  action?: string;
  description?: string;
  quantity?: number;
  title?: string;
  tags?: string[];
  tag?: string;
  loading: boolean;
  _id?: string;
};
const AddGig = () => {
  const [step, setStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [isAdPrefenceChecked, setIsAdPrefenceChecked] = useState(false);
  const [offerOn, setOfferOn] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [skills, setSkills] = useState<string[]>([]);
  const [profileComplete, setProfileComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [businessName, setBusinessName] = useState<Results>();
  const [_id, set_Id] = useState<string>("");
  const [searchList, setSearchList] = useState<ResultSearchProduct[]>([]);
  const [connectedTo, setConnectedTo] = useState("");
  const [requirePosting, setRequirePosting] = useState(false);
  const [productSelected, setProductSelected] = useState<
    ResultSearchProduct | undefined
  >();
  const route = useNavigate();
  const [specifications, setSpecifications] = useState<Specification[]>([
    { title: "", description: "" },
  ]);

  const [attributes, setAttributes] = useState<Attribute[]>([
    { key: "", value: "" },
  ]);
  const [searchValue, setSearchValue] = useState<string>("");
  const addSpecification = () => {
    setSpecifications([...specifications, { title: "", description: "" }]);
  };
  const removeSpecification = (index: number) => {
    setSpecifications(specifications.filter((_, i) => i !== index));
  };
  const handleTitleChange = (index: number, value: string) => {
    const updatedspecifications = [...specifications];
    updatedspecifications[index].title = value;
    setSpecifications(updatedspecifications);
  };
  const handleCheckedAdPreference = () => {
    setIsAdPrefenceChecked(!isAdPrefenceChecked);
  };
  const handleSkills = (data: string) => {
    if (data.trim() !== "") {
      setSkills((prev) => [...prev, data.trim()]);
    }
  };
  const handleTags = (data: string) => {
    if (data.trim() !== "") {
      setTags((prev) => [...prev, data.trim()]);
    }
  };
  const handleRangePrice = () => {
    setOfferOn(!offerOn);
  };
  const handleRequirePosting = () => {
    setRequirePosting(!requirePosting);
  };
  const form = useForm<FormValueGigAdd>();
  const { register, handleSubmit, formState, reset, setValue } = form;
  const { errors } = formState;
  useEffect(() => {
    const checkProfileCompletion = async () => {
      try {
        const response = await ProfileAlert();
        setProfileComplete(response.results);
      } catch (error) {
        console.error("Error checking profile completion:", error);
      }
    };

    checkProfileCompletion();
  }, []);
  const onsubmit = async (data: FormValueGigAdd) => {
    if (!profileComplete) {
      if (
        window.confirm(
          "Please complete your profile first. Do you want to go to the profile page?"
        )
      ) {
        route("/profile");
      } else {
        route("/");
      }
      return;
    }
    if (!loading) {
      try {
        data.businessHandle = JSON.parse(
          localStorage.getItem("token")!
        ).user.userHandle;
        data.description = " ";
        data.gigType = " ";
        if (step === 4 || step === 5 || step === 6) {
          data.gigType = form.getValues("gigType");
        }

        const formData: FormData = new FormData();
        _id != "" && formData.append("_id", _id);
        if (productSelected) {
          formData.append("connectedTo", productSelected._id);
        }

        formData.append("title", data.title);
        formData.append("businessName", data.businessName);
        formData.append("businessHandle", data.businessHandle);
        formData.append("description", data.description);
        formData.append("gigType", data.gigType!);
        formData.append("requirePosting", requirePosting.toString());
        data.searchCreator &&
          formData.append("searchCreator", data.searchCreator.toString());
        data.numberofcreator &&
          formData.append("numberofcreator", data.numberofcreator.toString());

        data.numberofvideo &&
          formData.append("numberofvideo", data.numberofvideo.toString());
        data.numberofimage &&
          formData.append("numberofimage", data.numberofimage.toString());
        data.adDuration &&
          formData.append("adDuration", data.adDuration.toString());
        data.submissionTimeframe &&
          formData.append(
            "submissionTimeframe",
            data.submissionTimeframe.toString()
          );
        data.offerAmountFrom &&
          formData.append("offerAmountFrom", data.offerAmountFrom.toString());
        data.offerAmount &&
          formData.append("offerAmount", data.offerAmount.toString());
        data.offerAmountTo &&
          formData.append("offerAmountTo", data.offerAmountTo.toString());
        tags.length > 0 && formData.append("tags", JSON.stringify(tags));
        skills.length > 0 && formData.append("skills", JSON.stringify(skills));
        formData.append("level", data.level);
        specifications &&
          specifications[0].title.length > 0 &&
          formData.append("specifications", JSON.stringify(specifications));

        attributes &&
          attributes[0].key.length > 0 &&
          formData.append("attributes", JSON.stringify(attributes));
        if ((tags.length > 0 && step == 5) || step !== 5) {
          setLoading(true);
          const apiResponse: RootGig = await addGig(formData);
          set_Id(apiResponse.data.results._id);

          setLoading(false);

          setSpecifications(specifications);
          if (!isLastStep && step < 6) {
            setActiveStep((cur) => cur + 1);
            setStep(step + 1);
          }

          if (isLastStep) {
            reset();
            setStep(1);
            setActiveStep(0);
            setIsLastStep(false);
            setIsFirstStep(false);
            setIsAdPrefenceChecked(false);
            setOfferOn(false);
            setTags([]);
            setSkills([]);
            setProductSelected(undefined);
            set_Id("");
            setSpecifications([{ title: "", description: "" }]);
            alert("Successfully added gig");
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: RootSearchProduct = await SearchProductName({
          productName: searchValue,
          userHandle: JSON.parse(localStorage.getItem("token")!).user
            .userHandle,
        });
        if (response.status === 200) {
          setSearchList(response.data.results);
        }
      } catch (error) {
        console.error("Error during search:", error);
      }
    };

    fetchData();
  }, [searchValue]);
  const goToPreviousStep = () => {
    if (!isFirstStep && step > 1) {
      setActiveStep((cur) => cur - 1);
      setStep(step - 1);
    }
  };
  const handleSkillDelete = (index: string) => {
    setSkills((prev) => prev.filter((del) => del !== index));
  };
  const handleTagDelete = (index: string) => {
    setTags((prev) => prev.filter((del) => del !== index));
  };
  useEffect(() => {
    setBusinessName(JSON.parse(localStorage.getItem("token")!));
  }, []);

  const [addSubTask, setAddSubTask] = useState(false);
  const [listOfSubTask, setListOfSubTask] = useState<SubTaskProps[]>([]);
  const [selectedAction, setSelectedAction] = useState("None");
  const [subTasks, setSubTasks] = useState<SubTaskProps[]>([
    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "None",
      quantity: undefined,
      title: undefined,
      tags: undefined,
      description: undefined,
      loading: false,
    },

    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Book A Ride",
      quantity: 1,
      title: "",
      description: "",
      loading: false,
    },

    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Like on Posts",
      quantity: 1,
      title: "",
      description: "",
      loading: false,
    },

    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Comment on Posts",
      quantity: 1,
      title: "",
      description: "",
      loading: false,
    },
    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Use Postaam App",
      quantity: 1,
      title: "",
      description: "",
      loading: false,
    },
    {
      businessName: businessName?.user.fullname,
      businessHandle: businessName?.user.userHandle,
      action: "Post Content",
      quantity: 1,
      title: "",
      tag: "",
      tags: [],
      description: "",
      loading: false,
    },
  ]);

  const selectAction = (e: { target: { value: any } }) => {
    setSelectedAction(e.target.value);
  };

  const addTask = () => {
    if (selectedAction != "None") {
      let newSub = subTasks.filter((ele) => ele.action == selectedAction);
      newSub[0].businessHandle = businessName?.user.userHandle;
      newSub[0].businessName = businessName?.user.fullname;
      setListOfSubTask((prev) => [...prev, newSub[0]]);
    } else {
      alert("Please select one action!");
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await GigDelete({ _id: id, page: 1 });
        toast.success("Gig Deleted successfully");
      } catch (error) {
        toast.error("Error deleting product");
        console.error("Error deleting product:", error);
      }
    }
  };

  return (
    <section>
      {step > 0 && (
        <>
          <section className="max-w-7xl m-auto ">
            <div className="grid grid-cols-12 space-y-3">
              <div className="col-span-12 border border-gray-300 rounded-lg bg-white px-8 py-5">
                <h6 className="text-xl text-gray-600 font-medium">Add Gig</h6>
              </div>
              <div className="col-span-12 ">
                <Card className="w-full bg-transparent shadow-none">
                  <form onSubmit={handleSubmit(onsubmit)} className="space-y-3">
                    <div className="border border-gray-300 rounded-lg bg-white p-1 md:p-8">
                      <div className="px-2">
                        <ResponsiveStepper
                          steps={[
                            "General Details",
                            "Add Sub Task",
                            "Description",
                            "Preference",
                            "Multi Post",
                            "Price",
                          ]}
                          activeStep={activeStep}
                          setIsLastStep={setIsLastStep}
                          setIsFirstStep={setIsFirstStep}
                        />
                      </div>
                    </div>

                    {step === 1 && (
                      <div className="mt-5 space-y-2 border border-gray-300 rounded-lg bg-white p-1 md:p-8 ">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-start gap-4 mt-4 w-full pb-5">
                          <div className="w-full space-y-2">
                            <label htmlFor="taskTitle" className="custom-label">
                              Task Title<span className="text-red-500">*</span>:
                            </label>
                            <input
                              type="text"
                              autoComplete="off"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("title", {
                                required: "Task Name is required",
                              })}
                            />
                            <p className="text-red-500">
                              {errors.title?.message}
                            </p>
                          </div>
                          <div className="w-full space-y-2">
                            <label
                              htmlFor="bussinessName"
                              className="custom-label"
                            >
                              Business Name:
                            </label>
                            <input
                              type="text"
                              value={businessName?.user.fullname}
                              autoComplete="off"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("businessName")}
                              readOnly
                            />
                            <p className="text-red-500">
                              {errors.businessName?.message}
                            </p>
                          </div>

                          <div className="w-full space-y-2">
                            <label
                              htmlFor="expertiesLevel"
                              className="custom-label"
                            >
                              Experties Level
                              <span className="text-red-500">*</span>:
                            </label>
                            <select
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("level", {
                                required: "Experties Level is required",
                              })}
                            >
                              <option value="" disabled selected>
                                Select Experties
                              </option>
                              <option value="entry" className="w-full">
                                Entry
                              </option>
                              <option value="intermidate" className="w-full">
                                Intermidate
                              </option>
                              <option value="expert">Expert</option>
                            </select>
                            <p className="text-red-500">
                              {errors.level?.message}
                            </p>
                          </div>
                          <div className="w-full relative space-y-2">
                            <label
                              htmlFor="searchProduct"
                              className="custom-label"
                            >
                              Search Product:
                            </label>
                            {productSelected == undefined && (
                              <input
                                type="text"
                                autoComplete="off"
                                className="border-2 w-full py-3 px-3 outline-none rounded-md"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                            )}
                            {productSelected != undefined && (
                              <div className="w-full  bg-white rounded-md py-1  border-[2px] p-1 px-3 flex items-center gap-2">
                                <img
                                  src={productSelected.images}
                                  alt="product"
                                  className="w-10 h-10 rounded-full object-cover"
                                />
                                {productSelected.productName}
                                <div
                                  onClick={() => {
                                    setProductSelected(undefined);
                                  }}
                                  className="bg-red-500 p-[2px] rounded-full"
                                >
                                  <RxCross2
                                    size={13}
                                    className="font-bold"
                                    color="white"
                                  />
                                </div>
                              </div>
                            )}
                            {searchValue != "" && searchList.length > 0 && (
                              <div className="w-full mt-2 bg-white shadow-md border-[1px] p-3 absolute">
                                <ul>
                                  {searchList.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          setConnectedTo(item._id);
                                          setSearchValue("");
                                          setProductSelected(item);
                                        }}
                                        className="flex items-center gap-2 cursor-pointer"
                                      >
                                        <img
                                          src={item.images}
                                          alt="product"
                                          className="w-10 h-10 rounded-full object-cover"
                                        />
                                        {item.productName}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="w-full space-y-2">
                            <label htmlFor="tag" className="custom-label">
                              Tag:
                            </label>
                            <input
                              type="text"
                              autoComplete="off"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  e.preventDefault();
                                  handleTags(e.currentTarget.value);
                                  e.currentTarget.value = "";
                                }
                              }}
                            />

                            <div
                              className={`flex flex-wrap gap-3 mt-2 px-3 ${tags.length > 6 ? "h-16  overflow-y-scroll" : ""
                                }`}
                            >
                              {tags.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex items-center gap-3 p-1 rounded-md bg-gray-200 shadow-md"
                                  >
                                    <span>{item}</span>
                                    <span onClick={() => handleTagDelete(item)}>
                                      <RxCross2 />
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="w-full space-y-2">
                            <label htmlFor="skill" className="custom-label">
                              Skill:
                            </label>

                            <input
                              type="text"
                              autoComplete="off"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  e.preventDefault();
                                  handleSkills(e.currentTarget.value);
                                  e.currentTarget.value = "";
                                }
                              }}
                            />

                            <div
                              className={`flex flex-wrap gap-3 mt-2 px-3 ${skills.length > 6
                                ? "h-16  overflow-y-scroll"
                                : ""
                                }`}
                            >
                              {skills.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <div className="flex bg-gray-200 items-center gap-3  rounded-md  shadow-md ">
                                      <span className=" w-full">{item}</span>
                                      <span
                                        onClick={(e) => handleSkillDelete(item)}
                                      >
                                        <RxCross2 />
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <>
                        <div className="border border-gray-300 rounded-lg bg-white p-1 md:p-8 ">
                          <h2>Add Sub Task</h2>
                          <div className="flex items-center mb-3">
                            <input
                              type="checkbox"
                              id="task"
                              name="vehicle1"
                              value="Bike"
                              onChange={() => setAddSubTask((prev) => !prev)}
                            />
                            <label className="ml-2" htmlFor="task">
                              Do you want to add task in your gig?
                            </label>
                          </div>
                        </div>

                        {addSubTask && (
                          <div className="space-y-3">
                            <div className="flex justify-end">
                              <Button
                                onClick={addTask}
                                className=" bg-bluePostaam-600 rounded-full py-3 text-[15px] font-bold w-48 text-white"
                              >
                                Add Task
                              </Button>
                            </div>
                            <div className="border border-gray-300 rounded-lg bg-white space-y-2 md:p-8">
                              <div>
                                <label htmlFor="">Action</label>
                              </div>

                              <div>
                                <select
                                  className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                                  onChange={selectAction}
                                >
                                  {subTasks.map((ele, index) => (
                                    <option key={index} value={ele.action}>
                                      {ele.action}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {listOfSubTask &&
                              listOfSubTask.map((ele, index) => (
                                <AddSubGig
                                  key={index}
                                  ele={ele}
                                  index={index}
                                  businessName={
                                    businessName
                                      ? businessName?.user.fullname
                                      : ""
                                  }
                                  listOfSubTask={listOfSubTask}
                                  setListOfSubTask={setListOfSubTask}
                                  handleDelete={handleDelete}
                                  _id={_id}
                                  selectedAction={selectedAction}
                                  errors={undefined}
                                />
                              ))}
                          </div>
                        )}
                      </>
                    )}

                    {step === 3 && (
                      <div className="space-y-8 border border-gray-300 rounded-lg bg-white p-1 md:p-8 ">
                        <div className="flex justify-end mt-4">
                          <Button
                            onClick={addSpecification}
                            className="bg-bluePostaam-600 py-3 text-sm font-Roboto font-medium rounded-full tracking-wider flex flex-row space-x-5"
                          >
                            <GoPlus size={20} />
                            Add Description
                          </Button>
                        </div>
                        {specifications.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="border px-5 rounded-md border-gray-300"
                            >
                              <div className="pb-10 relative flex flex-row">
                                <div className="absolute -top-5 -right-9">
                                  {index > 0 && (
                                    <div className="bg-red-600 p-2 rounded-full cursor-pointer">
                                      <MdOutlineDelete
                                        className=" text-white"
                                        size={28}
                                        onClick={() =>
                                          removeSpecification(index)
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="rounded-lg mt-3 space-y-2 w-full">
                                  <div className="w-full pt-3 pb-8 space-y-3">
                                    <div className="w-full space-y-2">
                                      <label
                                        htmlFor="title"
                                        className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                      >
                                        Title
                                        <span className="text-red-500">
                                          *
                                        </span>{" "}
                                        :
                                      </label>
                                      <input
                                        type="text"
                                        autoComplete="off"
                                        id={`title-${index}`}
                                        className="border-2 w-full p-3 rounded-md"
                                        value={data.title}
                                        onChange={(e) =>
                                          handleTitleChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                    <div className="w-full space-y-2">
                                      <label
                                        htmlFor="title"
                                        className="text-[16px] text-gray-500 font-Roboto font-medium"
                                      >
                                        Description
                                        <span className="text-red-500">
                                          *
                                        </span>{" "}
                                        :
                                      </label>
                                      <ReactQuill
                                        theme="snow"
                                        value={data.description}
                                        className="rounded-lg h-56"
                                        onChange={(val) => {
                                          data.description = val;
                                          setSpecifications([
                                            ...specifications,
                                          ]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {step === 4 && (
                      <div className=" mt-5 space-y-2 border border-gray-300 rounded-lg bg-white p-1 md:p-8 ">
                        <div className="flex items-center my-7 gap-1">
                          <input
                            type="checkbox"
                            className="w-[18px] h-[18px] accent-indigo-600"
                            name="checkbox"
                            id="checkbox"
                            onClick={handleCheckedAdPreference}
                          />
                          <label
                            htmlFor="checkbox"
                            className="cursor-pointer custome-label"
                          >
                            Ad Preference
                          </label>
                        </div>
                        <div className="flex flex-wrap justify-start gap-4 w-full pb-5">
                          <div className="w-full space-y-2">
                            <label htmlFor="creators" className="custom-label">
                              Number of Creators
                              {isAdPrefenceChecked ? (
                                <span className="text-red-500">*</span>
                              ) : (
                                ""
                              )}
                              :
                            </label>
                            <input
                              type="number"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("numberofcreator", {
                                required: isAdPrefenceChecked
                                  ? "Field is required*"
                                  : false,
                              })}
                            />
                            {isAdPrefenceChecked ? (
                              <p className="text-red-500">
                                {errors.numberofcreator?.message}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className=" w-full space-y-2">
                            <label
                              htmlFor="contentType"
                              className="custom-label"
                            >
                              Content Type
                              {isAdPrefenceChecked ? (
                                <span className="text-red-500">*</span>
                              ) : (
                                ""
                              )}
                              :
                            </label>
                            <select
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("gigType", {
                                required: isAdPrefenceChecked
                                  ? "Field is required*"
                                  : false,
                              })}
                            >
                              <option value="" disabled selected>
                                Select Content
                              </option>
                              <option value="freemium" className="w-full">
                                Freemium
                              </option>
                              <option value="premium" className="w-full">
                                Premium
                              </option>
                            </select>
                            {isAdPrefenceChecked ? (
                              <p className="text-red-500">
                                {errors.gigType?.message}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="w-full space-y-2">
                            <label htmlFor="creators" className="custom-label">
                              Search Creators
                              {isAdPrefenceChecked ? (
                                <span className="text-red-500">*</span>
                              ) : (
                                ""
                              )}
                              :
                            </label>
                            <input
                              type="number"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("searchCreator", {
                                required: isAdPrefenceChecked
                                  ? "Field is required*"
                                  : false,
                              })}
                            />
                            {isAdPrefenceChecked ? (
                              <p className="text-red-500">
                                {errors.searchCreator?.message}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {step === 5 && (
                      <div className="border border-gray-300 rounded-lg bg-white p-1 md:p-8 mt-5 space-y-2">
                        <div className="flex items-center my-7 gap-2 ml-1">
                          <Switch crossOrigin="switch" color="blue" />
                          Multiple Post
                        </div>
                        <div className="flex flex-wrap justify-start gap-4 w-full pb-5">
                          <div className="md:w-[49%] w-full space-y-2">
                            <label htmlFor="photo" className="custom-label">
                              Number of Content Required Photo
                              <span className="text-red-500">*</span>:
                            </label>
                            <input
                              type="number"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("numberofimage", {
                                required: "This field is required.",
                              })}
                            />
                            <p className="text-red-500">
                              {errors.numberofimage?.message}
                            </p>
                          </div>
                          <div className="md:w-[49%] w-full space-y-2">
                            <label htmlFor="video" className="custom-label">
                              Number of Content Required Video
                              <span className="text-red-500">*</span>:
                            </label>
                            <input
                              type="number"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("numberofvideo", {
                                required: "This field is required",
                              })}
                            />
                            <p className="text-red-500">
                              {errors.numberofvideo?.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {step === 6 && (
                      <div className="border border-gray-300 rounded-lg bg-white p-1 md:p-8  mt-5 space-y-2">
                        <div className="flex gap-4">
                          <div className="flex items-center my-2 gap-2 ml-1">
                            <Switch
                              crossOrigin="switch"
                              color="blue"
                              onClick={handleRangePrice}
                            />
                            Range Price
                          </div>
                          <div className="flex items-center gap-1 ml-1">
                            <Switch
                              crossOrigin="switch"
                              color="blue"
                              onClick={handleRequirePosting}
                            />
                            <p>Content Posting required</p>
                          </div>
                        </div>
                        <div className="flex  gap-4">
                          <div className="w-full space-y-2">
                            <label htmlFor="duration" className="custom-label">
                              Ad Run Duration Day
                              <span className="text-red-500">*</span>:
                            </label>
                            <input
                              type="number"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("adDuration", {
                                required: "Field is Required",
                              })}
                            />
                            <p className="text-red-500">
                              {errors.adDuration?.message}
                            </p>
                          </div>
                          <div className="w-full space-y-2">
                            <label htmlFor="timeframe" className="custom-label">
                              Timeframe<span className="text-red-500">*</span>
                            </label>
                            <input
                              type="number"
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              {...register("submissionTimeframe", {
                                required: "Field is Required",
                              })}
                            />
                            <p className="text-red-500">
                              {errors.submissionTimeframe?.message}
                            </p>
                          </div>
                        </div>
                        {offerOn ? (
                          <div className="flex  justify-start gap-4 w-full pb-5">
                            <div className="w-full space-y-2">
                              <label
                                htmlFor="offerAmount"
                                className="custom-label"
                              >
                                Offer From
                                <span className="text-red-500">*</span>:
                              </label>
                              <input
                                type="number"
                                className="border-2 w-full py-3 px-3 outline-none rounded-md"
                                {...register("offerAmountFrom", {
                                  required: "Field is Required",
                                })}
                              />
                              <p className="text-red-500">
                                {errors.offerAmountFrom?.message}
                              </p>
                            </div>
                            <div className="w-full space-y-2">
                              <label
                                htmlFor="offerAmount"
                                className="custom-label"
                              >
                                Offer To<span className="text-red-500">*</span>:
                              </label>
                              <input
                                type="number"
                                className="border-2 w-full py-3 px-3 outline-none rounded-md"
                                {...register("offerAmountTo", {
                                  required: "Field is Required",
                                })}
                              />
                              <p className="text-red-500">
                                {errors.offerAmountTo?.message}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="w-full space-y-2">
                              <label
                                htmlFor="offerAmount"
                                className="custom-label"
                              >
                                Offer Amount
                                <span className="text-red-500">*</span>:
                              </label>
                              <input
                                type="number"
                                className="border-2 w-full py-3 px-3 outline-none rounded-md"
                                {...register("offerAmountFrom", {
                                  required: "Field is Required",
                                })}
                              />
                              <p className="text-red-500">
                                {errors.offerAmountFrom?.message}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="flex justify-end space-x-3">
                      <Button
                        type="button"
                        className={`mt-9  py-1 text-[15px] font-bold w-48 rounded-full ${step === 1 ? "bg-gray-500" : "bg-bluePostaam-600"
                          }`}
                        onClick={goToPreviousStep}
                      >
                        Previous
                      </Button>
                      <Button
                        type="submit"
                        className="mt-9 bg-bluePostaam-600 rounded-full md:py-3 py-2  text-[15px] font-bold md:w-48 w-32"
                      >
                        {step < 6 ? (
                          <div>
                            {loading ? (
                              <div className="flex items-center gap-2">
                                <Spinner className="h-4 w-4" />
                                <p>Loading</p>
                              </div>
                            ) : (
                              "Next"
                            )}
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setIsLastStep(true);
                            }}
                          >
                            {loading ? (
                              <div className="flex items-center gap-2">
                                <Spinner className="h-4 w-4" />
                                <p>Loading</p>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </div>
                        )}
                      </Button>
                    </div>
                  </form>
                </Card>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};
export default AddGig;
