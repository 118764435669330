import { Button, Card, Stepper, Step } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import { GoPlus } from "react-icons/go";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { FaFileAlt } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import {
  ProfileAlert,
  RootBusinessProfile,
  getBusinessProfile,
} from "../../utils/business";
import { Spinner } from "@material-tailwind/react";
import {
  ResultProductCategory,
  RootProductAdd,
  addProductApi,
  getProductCategory,
} from "../../utils/product";
import { toast } from "react-toastify";
import ResponsiveStepper from "./Stepper";
export type FormValue = {
  productName: string;
  slug: string;
  price: number;
  category: string;
  subCategory: string;
  descriptionTitle: string;
  quantity: string;
  thirdCategory: string;
  weight: string;
  description: string;
  tags: string[];
  commission: number;
  videos?: File[];
  brand: string;
  totalCommission: number;
  percentage: number;
  images?: File[];
  discountPercentage: number;
  specifications: Specification[];
  attributes: Attribute[];
};

interface InputItem {
  id: number;
}

type Specification = {
  title: string;
  description: string;
};
type Attribute = {
  key: string;
  value: string;
};
const ProductAdd = () => {
  const route = useNavigate();
  const [step, setStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [isAdPrefenceChecked, setIsAdPrefenceChecked] = useState(false);
  const [offerOn, setOfferOn] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [skills, setSkills] = useState<string[]>([]);
  const [_id, set_Id] = useState<string>("");

  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
  const [selectedVidoFiles, setSelectedVideoFiles] = useState<File[]>([]);
  const [productId, setProductId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ResultProductCategory[]>();
  const [selectCategory, setSelectCategory] = useState<string>("");
  const [selectSubCategory, setSelectSubCategory] = useState<string>("");
  const [selectThirdCategory, setSelectThirdCategory] = useState<string>("");
  const [additional, setAdditional] = useState(""); // State for the value of the additional commission
  const [total, setTotal] = useState("");
  const [specifications, setSpecifications] = useState<Specification[]>([
    { title: "", description: "" },
  ]);
  const [attributes, setAttributes] = useState<Attribute[]>([
    { key: "", value: "" },
  ]);
  const [isChecked, setIsChecked] = useState(false);
  const [tagValidation, setTagValidation] = useState(true);
  const [productName, setProductName] = useState("");
  const [slug, setSlug1] = useState("");
  const [businessinfo, setBusinessInfo] = useState<RootBusinessProfile>();
  const [isDirty, setIsDirty] = useState(false);
  const [profileComplete, setProfileComplete] = useState(false);
  const [profileMessage, setProfileMessage] = useState("");

  const handleProductNameChange = (event: any) => {
    const value = event.target.value;
    setProductName(value);
    setSlug1(value.replace(/\s+/g, "-"));
  };

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const addSpecification = () => {
    setSpecifications([...specifications, { title: "", description: "" }]);
  };
  const removeSpecification = (index: number) => {
    setSpecifications(specifications.filter((_, i) => i !== index));
  };
  const removeAttribute = (index: number) => {
    setAttributes(attributes.filter((_, i) => i !== index));
  };

  const addAttribute = () => {
    setAttributes([...attributes, { key: "", value: "" }]);
  };
  const removeAttributen = (index: number) => {
    setAttributes(attributes.filter((_, i) => i !== index));
  };

  const handleSelectCategory = (value: string) => {
    setSelectCategory(value);
    setSelectSubCategory("");
    setSelectThirdCategory("");
  };

  const handleSubCategory = (value: string) => {
    setSelectSubCategory(value);
    setSelectThirdCategory("");
  };
  const handleThiredCategory = (value: string) => {
    setSelectThirdCategory(value);
  };

  const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      let imageCount = 0;
      let videoCount = 0;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type.split("/")[0] === "image") {
          imageCount++;
        } else if (files[i].type.split("/")[0] === "video") {
          videoCount++;
        }
      }

      if (
        imageCount + selectedImageFiles.length <= 5 &&
        videoCount + selectedVidoFiles.length <= 2
      ) {
        const newImages: File[] = [];
        const newVideos: File[] = [];

        for (let i = 0; i < files.length; i++) {
          if (files[i].type.split("/")[0] === "image") {
            newImages.push(files[i]);
          } else if (files[i].type.split("/")[0] === "video") {
            newVideos.push(files[i]);
          }
        }

        setSelectedImageFiles((prevImages) => [...prevImages, ...newImages]);
        setSelectedVideoFiles((prevVideos) => [...prevVideos, ...newVideos]);
      } else {
        if (imageCount + selectedImageFiles.length > 5) {
          toast.info("You can only select up to five images.");
        } else {
          toast.info("You can only select up to two videos.");
        }
      }
    }
  };

  const handlePreviewImagesDelete = (index: number) => {
    const datas = selectedImageFiles;
    datas.splice(index, 1);
    setSelectedImageFiles([...datas]);
  };
  const handlePreviewVideoDelete = (index: number) => {
    const datas = selectedVidoFiles;
    datas.splice(index, 1);
    setSelectedVideoFiles([...datas]);
  };

  const handleCommission = (event: any) => {
    const newValue = event.target.value;
    const standardCommission = selectCategory
      ? categories?.find((cata) => cata.name === selectCategory)?.percentage ||
      0
      : 0;
    const totalCommission = Number(standardCommission) + Number(newValue);
    setAdditional(newValue); // Update additional commission state
    setTotal(totalCommission.toString()); // Update total commission state
  };

  const handleTags = (data: string) => {
    if (data.trim() !== "") {
      setTags((prev) => [...prev, data.trim()]);
      form.setValue("tags", [...tags, data.trim()]);
      setTagValidation(true);
    }
  };
  const handleTagDelete = (index: number) => {
    let data = tags;
    data.splice(index, 1);
    setTags([...data]);

    if (data.length == 0) {
      setTagValidation(false);
    }
  };

  const form = useForm<FormValue>();
  const { register, handleSubmit, formState, reset, control } = form;
  const { errors } = formState;

  useEffect(() => {
    (async () => {
      let data = await getProductCategory();

      let data1 = await getBusinessProfile();
      let stringCategories: string[] = data1.results.categories.map(
        (cata) => cata.name
      );
      setBusinessInfo(data1);
      let filteredCategories = data.results.filter((cata: { name: string }) =>
        stringCategories.includes(cata.name)
      );

      setCategories(filteredCategories);
    })();
  }, []);

  useEffect(() => {
    const checkProfileCompletion = async () => {
      try {
        const respones = await ProfileAlert();
        setProfileComplete(respones.results);
        setProfileMessage(respones.message);
      } catch (error) {
        console.error("Error checking profile completion:", error);
      }
    };
    checkProfileCompletion();
  }, []);

  const onsubmit = async (data: FormValue) => {
    if (!profileComplete) {
      if (
        window.confirm(
          `${profileMessage} Do you want to go to the profile page?`
        )
      ) {
        route("/profile");
      } else {
        route("/");
      }
      return;
    }
    if (!loading) {
      try {
        const formData: FormData = new FormData();
        _id != "" && formData.append("_id", _id);
        formData.append("productName", data.productName);
        formData.append("slug", slug);
        formData.append("price", data.price.toString());
        data.category && formData.append("category", data.category);
        data.subCategory && formData.append("subCategory", data.subCategory);
        data.thirdCategory &&
          formData.append("thirdCategory", data.thirdCategory);
        formData.append("brand", data.brand);
        formData.append("weight", data.weight);
        formData.append("quantity", data.quantity);

        formData.append("commission", total);

        formData.append(
          "discountPercentage",
          data.discountPercentage.toString()
        );

        tags.length > 0 &&
          formData.append(
            "tags",
            JSON.stringify(
              tags.map((tag, index) => {
                return { tagName: tag };
              })
            )
          );
        specifications &&
          specifications[0].title.length > 0 &&
          formData.append("specifications", JSON.stringify(specifications));

        attributes &&
          attributes[0].key.length > 0 &&
          formData.append("attributes", JSON.stringify(attributes));

        if (selectedImageFiles && selectedImageFiles.length > 0) {
          const imagesArray = Array.from(selectedImageFiles);
          imagesArray.forEach((image) => {
            formData.append("images", image);
          });
        }
        if (selectedVidoFiles && selectedVidoFiles.length > 0) {
          const videosArray = Array.from(selectedVidoFiles);
          videosArray.forEach((video) => {
            formData.append("videos", video);
          });
        }
        if ((tags.length > 0 && step == 5) || step !== 5) {
          setLoading(true);
          const apiResponse: RootProductAdd = await addProductApi(formData);
          set_Id(apiResponse.data.results._id);
          setLoading(false);
          const productId = apiResponse.data.results._id;
          setProductId(productId);
          setSpecifications(specifications);
          if (!isLastStep && step < 6) {
            setActiveStep((cur) => cur + 1);
            setStep(step + 1);
          }
          if (isLastStep) {
            reset();
            setStep(1);
            setActiveStep(0);
            setIsLastStep(false);
            setIsFirstStep(false);
            setIsAdPrefenceChecked(false);
            setOfferOn(false);
            setTags([]);
            setSkills([]);
            setSelectedImageFiles([]);
            set_Id("");
            setSpecifications([{ title: "", description: "" }]);
            setSelectedVideoFiles([]);
            setProductName("");
            setSlug1("");
            setSelectCategory("");
            setSelectSubCategory("");
            setSelectThirdCategory("");
            setAttributes([{ key: "", value: "" }]);
            setAdditional("");
            setTotal("");
            setIsChecked(false);

            toast.success("Product added successfully");
          }
        } else {
          setTagValidation(false);
        }
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  const goToPreviousStep = () => {
    if (!isFirstStep && step > 1) {
      setActiveStep((cur) => cur - 1);
      setStep(step - 1);
      setTagValidation(true);
    }
  };

  const handleTitleChange = (index: number, value: string) => {
    const updatedspecifications = [...specifications];
    updatedspecifications[index].title = value;
    setSpecifications(updatedspecifications);
  };

  const handleKeyChange = (index: number, value: string) => {
    const updatedAttribute = [...attributes];
    updatedAttribute[index].key = value;
    setAttributes(updatedAttribute);
  };

  const handleValueChange = (index: number, value: string) => {
    const updatedAttribute = [...attributes];
    updatedAttribute[index].value = value;
    setAttributes(updatedAttribute);
  };

  return (
    <section>
      {step > 0 && (
        <>
          <div className="flex justify-between items-center pb-3 bg-white mb-3 p-3 rounded-lg">
            <h3 className="font-Roboto text-xl font-bold">Add Product</h3>
            {/* <Button
              onClick={handleReset}
              className="bg-red-500 rounded-full font-Roboto text-sm font-medium px-6 py-1 tracking-wider"
            >
              Reset
            </Button> */}
          </div>
          <div className="bg-white md:p-3 font-Roboto rounded-md ">
            <Card className="w-full bg-transparent shadow-none">
              <form onSubmit={handleSubmit(onsubmit)}>
                <div className="">
                  <ResponsiveStepper
                    activeStep={activeStep}
                    setIsLastStep={setIsLastStep}
                    setIsFirstStep={setIsFirstStep}
                    steps={[
                      "Details",
                      "Category",
                      "Description",
                      "Attribute",
                      "Promotion",
                      "Medias",
                    ]}
                  />
                </div>
                {step === 1 && (
                  <div className="p-1 md:p-8 rounded-lg mt-5 border border-gray-300 space-y-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 w-full pb-5">
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="taskTitle"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("productName", {
                            required: "Name is required",
                          })}
                          value={productName}
                          onChange={handleProductNameChange}
                        />
                        <p className="text-red-500">
                          {errors.productName?.message}
                        </p>
                      </div>
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="bussinessName"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Slug
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          value={slug}
                          readOnly
                        />
                      </div>

                      <div className="w-full space-y-2">
                        <label
                          htmlFor="photo"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Product Brand<span className="text-red-500">*</span>:
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("brand", {
                            required: "Brand is required",
                          })}
                        />
                        <p className="text-red-500">{errors.brand?.message}</p>
                      </div>
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="searchProduct"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Price<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="number"
                          autoComplete="off"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("price", {
                            required: "Price is required",
                          })}
                        />
                        <p className="text-red-500">{errors.price?.message}</p>
                      </div>
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="photo"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Quantity / Pack<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="number"
                          autoComplete="off"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("quantity", {
                            required: "Quantity / Pack is required",
                          })}
                        />
                        <p className="text-red-500">
                          {errors.quantity?.message}
                        </p>
                      </div>
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="photo"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Weight
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("weight")}
                        />
                      </div>
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="photo"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Discount (%)
                        </label>
                        <input
                          type="number"
                          autoComplete="off"
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("discountPercentage")}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="p-1 md:p-8 rounded-lg mt-5 border border-gray-300 space-y-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center gap-4 w-full pb-5">
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="category"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Category:
                        </label>
                        <select
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("category", {
                            required: "Category is required",
                          })}
                          value={selectCategory}
                          onChange={(e) => handleSelectCategory(e.target.value)}
                        >
                          <option value="" disabled>
                            Select a category
                          </option>
                          {categories?.map((cata, index) => {
                            return (
                              <option value={cata.name} key={index}>
                                {cata.name} ({cata.percentage}%)
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="category"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Sub Category:
                        </label>
                        <select
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("subCategory")}
                          value={selectSubCategory}
                          onChange={(e) => handleSubCategory(e.target.value)}
                          disabled={!selectCategory}
                        >
                          <option value="" disabled>
                            Select a subcategory
                          </option>
                          {categories
                            ?.find((cata) => cata.name === selectCategory)
                            ?.subCategories.map((subCata, index) => {
                              return (
                                <option value={subCata.name} key={index}>
                                  {subCata.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="w-full space-y-2">
                        <label
                          htmlFor="category"
                          className="text-[16px] text-gray-500 font-Roboto font-medium"
                        >
                          Third Category:
                        </label>
                        <select
                          className="border-2 w-full py-3 px-3 outline-none rounded-md font-Roboto"
                          {...register("thirdCategory")}
                          value={selectThirdCategory}
                          onChange={(e) => handleThiredCategory(e.target.value)}
                          disabled={!selectSubCategory}
                        >
                          <option value="" disabled>
                            Select a Third Category
                          </option>
                          {categories
                            ?.find((cata) => cata.name === selectCategory)
                            ?.subCategories.find(
                              (subcata) => subcata.name === selectSubCategory
                            )
                            ?.subCategories.map((thirdCata, index) => {
                              return (
                                <option value={thirdCata.name} key={index}>
                                  {thirdCata.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="space-y-8">
                    <div className="flex justify-end mt-4">
                      <Button
                        onClick={addSpecification}
                        className="bg-bluePostaam-600 py-3 text-sm font-Roboto font-medium rounded-full tracking-wider flex flex-row space-x-5"
                      >
                        <GoPlus size={20} />
                        Add Description
                      </Button>
                    </div>
                    {specifications.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="border px-5 rounded-md border-gray-300"
                        >
                          <div className="pb-10 relative flex flex-row">
                            <div className="absolute -top-5 -right-9">
                              {index > 0 && (
                                <div className="bg-red-600 p-2 rounded-full cursor-pointer">
                                  <MdOutlineDelete
                                    className=" text-white"
                                    size={28}
                                    onClick={() => removeSpecification(index)}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="rounded-lg mt-3 space-y-2 w-full">
                              <div className="w-full pt-3 pb-8 space-y-3">
                                <div className="w-full space-y-2">
                                  <label
                                    htmlFor="title"
                                    className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                  >
                                    Title
                                    <span className="text-red-500">*</span> :
                                  </label>
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    id={`title-${index}`}
                                    className="border-2 w-full p-3 rounded-md"
                                    value={data.title}
                                    onChange={(e) =>
                                      handleTitleChange(index, e.target.value)
                                    }
                                    required
                                  />

                                  {errors.descriptionTitle && (
                                    <p className="text-red-500">
                                      This field is required
                                    </p>
                                  )}
                                </div>

                                <div className="w-full space-y-2">
                                  <label
                                    htmlFor="title"
                                    className="text-[16px] text-gray-500 font-Roboto font-medium"
                                  >
                                    Description
                                    <span className="text-red-500">*</span> :
                                  </label>
                                  <ReactQuill
                                    theme="snow"
                                    value={data.description}
                                    className="rounded-lg h-56"
                                    onChange={(val) => {
                                      data.description = val;
                                      setSpecifications([...specifications]);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {step === 4 && (
                  <div className="space-y-8">
                    <div className="flex justify-end mt-4">
                      <Button
                        onClick={addAttribute}
                        className="bg-bluePostaam-600 py-3 text-sm font-Roboto font-medium rounded-full tracking-wider flex flex-row space-x-5"
                      >
                        <GoPlus size={20} />
                        Add Attribute
                      </Button>
                    </div>
                    {attributes.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="border px-5 rounded-md border-gray-300"
                        >
                          <div className="pb-10 relative flex flex-row">
                            <div className="absolute -top-5 -right-9">
                              {index > 0 && (
                                <div className="bg-red-600 p-2 rounded-full cursor-pointer">
                                  <MdOutlineDelete
                                    className=" text-white"
                                    size={28}
                                    onClick={() => removeAttribute(index)}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="rounded-lg mt-3 space-y-2 w-full">
                              <div className="w-full pt-3 pb-8 space-y-3">
                                <div className="w-full space-y-2">
                                  <label
                                    htmlFor="title"
                                    className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                  >
                                    Key
                                    <span className="text-red-500">*</span> :
                                  </label>
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    id={`key-${index}`}
                                    className="border-2 w-full p-3 rounded-md"
                                    value={data.key}
                                    onChange={(e) =>
                                      handleKeyChange(index, e.target.value)
                                    }
                                  />
                                </div>

                                <div className="w-full space-y-2">
                                  <label
                                    htmlFor="title"
                                    className="text-[16px] text-gray-500 font-Roboto font-medium text-left w-full"
                                  >
                                    Value
                                    <span className="text-red-500">*</span> :
                                  </label>
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    id={`value-${index}`}
                                    className="border-2 w-full p-3 rounded-md"
                                    value={data.value}
                                    onChange={(e) =>
                                      handleValueChange(index, e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {step === 5 && (
                  <div>
                    <div className="mt-3 ">
                      <label className="flex gap-2 items-center w-24">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className="h-5 w-5"
                        />
                        Affiliation
                      </label>
                      {isChecked && (
                        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between items-center gap-4">
                          <div className="my-5 w-full space-y-2">
                            <label
                              htmlFor="photo"
                              className="text-[16px] font-bold text-gray-500"
                            >
                              Standard Commission %:
                            </label>
                            <input
                              type="number"
                              value={
                                selectCategory &&
                                categories?.find(
                                  (cata) => cata.name === selectCategory
                                )?.percentage
                              }
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              readOnly
                            />
                          </div>
                          <div className="my-5 w-full space-y-2">
                            <label
                              htmlFor="photo"
                              className="text-[16px] font-bold text-gray-500"
                            >
                              Additional Commission %:
                            </label>
                            <input
                              type="number"
                              value={additional}
                              onChange={handleCommission}
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                              min={0}
                              max={100}
                            />
                          </div>
                          {/* <input type="number" {...register("commision")} /> */}
                          <div className="my-5 w-full space-y-2">
                            <label
                              htmlFor="photo"
                              className="text-[16px] font-bold text-gray-500"
                            >
                              Total Commission %:
                            </label>
                            <input
                              type="number"
                              value={total}
                              readOnly
                              className="border-2 w-full py-3 px-3 outline-none rounded-md"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-5 w-full space-y-2">
                      <label
                        htmlFor="photo"
                        className="text-[16px] font-bold text-gray-500"
                      >
                        Tag<span className="text-red-500">*</span>:
                      </label>
                      <input
                        type="text"
                        className="border-2 w-full py-3 px-3 outline-none rounded-md"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                            handleTags(e.currentTarget.value);
                            e.currentTarget.value = "";
                          }
                        }}
                      />
                      {!tagValidation && (
                        <span className="text-red-500">Tag is required</span>
                      )}
                      {tags.length >= 1 && (
                        <div className="flex flex-wrap gap-5">
                          {tags.map((item, index) => {
                            return (
                              <div key={index}>
                                <span className="flex py-1 px-1 rounded-md mt-2 items-center bg-gray-100 gap-2 shadow-md">
                                  <p>{item}</p>
                                  <RxCross2
                                    onClick={() => handleTagDelete(index)}
                                  />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {step === 6 && (
                  <div>
                    <div className="flex gap-6 justify-center mt-9">
                      <div className="flex flex-col flex-wrap w-full">
                        <label htmlFor="videoFile">
                          <div className="border-2 border-dashed cursor-pointer border-gray-300 w-full rounded-md h-40 overflow-hidden ">
                            <div className="flex flex-col justify-center h-full items-center">
                              <AiOutlineVideoCameraAdd size={40} />
                              <p>Click to select files</p>
                            </div>
                          </div>
                        </label>
                        <input
                          type="file"
                          onChange={handleImageFile}
                          // accept="video/*"
                          id="videoFile"
                          multiple={true}
                          className="hidden"
                        />
                        <div>
                          <div className="flex flex-wrap mt-4 gap-3">
                            {selectedImageFiles.map((video, index) => {
                              return (
                                <div className="relative" key={index}>
                                  <div className="Image_Preview w-32 border-[1px] border-gray-400 rounded-md h-32 flex justify-center items-center overflow-hidden ">
                                    {video.type.startsWith("image/") && (
                                      <img
                                        src={URL.createObjectURL(video)}
                                        className="w-full h-full object-cover"
                                        alt={`Image ${index + 1}`}
                                      />
                                    )}

                                    {video.type.startsWith(
                                      "application/pdf"
                                    ) && (
                                        <>
                                          {video.type === "application/pdf" ? (
                                            <div>
                                              <FaFileAlt size={60} />
                                              <iframe
                                                src={URL.createObjectURL(video)}
                                                className="w-full hidden"
                                                title="PDF Viewer"
                                              />
                                            </div>
                                          ) : (
                                            "Invalid File"
                                          )}
                                        </>
                                      )}

                                    <div
                                      className="absolute top-[-5px] p-1 rounded-full cursor-pointer bg-red-500 right-[-3px]"
                                      onClick={() =>
                                        handlePreviewImagesDelete(index)
                                      }
                                    >
                                      <RxCross2 size={15} color="white" />
                                    </div>
                                  </div>
                                  {video.type.startsWith("application/pdf") && (
                                    <>
                                      {video.type === "application/pdf" ? (
                                        <div className="w-32 whitespace-nowrap overflow-hidden text-ellipsis">
                                          <p>{video.name}</p>
                                        </div>
                                      ) : (
                                        "Invalid File"
                                      )}
                                    </>
                                  )}
                                </div>
                              );
                            })}
                            {selectedVidoFiles.map((video, index) => {
                              return (
                                <div className="relative" key={index}>
                                  <div className="Image_Preview w-32 border-[1px] border-gray-400 rounded-md h-32 flex justify-center items-center overflow-hidden ">
                                    {video.type.startsWith("video/") && (
                                      <video
                                        src={URL.createObjectURL(video)}
                                        className="w-full"
                                        autoPlay
                                        muted
                                      />
                                    )}

                                    <div
                                      className="absolute top-[-5px] p-1 rounded-full cursor-pointer bg-red-500 right-[-3px]"
                                      onClick={() =>
                                        handlePreviewVideoDelete(index)
                                      }
                                    >
                                      <RxCross2 size={15} color="white" />
                                    </div>
                                  </div>
                                  {video.type.startsWith("application/pdf") && (
                                    <>
                                      {video.type === "application/pdf" ? (
                                        <div className="w-32 whitespace-nowrap overflow-hidden text-ellipsis">
                                          <p>{video.name}</p>
                                        </div>
                                      ) : (
                                        "Invalid File"
                                      )}
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex justify-end space-x-3 pt-5">
                  <Button
                    type="button"
                    className={`py-3 text-sm font-Roboto font-medium w-48 rounded-full tracking-wider ${step === 1 ? "bg-gray-500" : "bg-[#1A76D2]"
                      }`}
                    onClick={goToPreviousStep}
                  >
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    className="bg-bluePostaam-600 py-3 text-sm font-Roboto font-medium w-48 rounded-full tracking-wider"
                    disabled={!tagValidation}
                  >
                    {step < 6 ? (
                      <div>
                        {loading ? (
                          <div className="flex items-center gap-2">
                            <Spinner className="h-4 w-4" />
                            <p>Loading</p>
                          </div>
                        ) : (
                          "Next"
                        )}
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setIsLastStep(true);
                        }}
                      >
                        {loading ? (
                          <div className="flex items-center gap-2">
                            <Spinner className="h-4 w-4" />
                            <p>Loading</p>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </div>
                    )}
                  </Button>
                </div>
              </form>
            </Card>
          </div>
        </>
      )}
    </section>
  );
};
export default ProductAdd;
