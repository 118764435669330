import { Post } from "./post";

export const posts: Post[] = [
  {
    paidPartnerShip: "N/A",
    status: true,
    medias: [
      {
        mediaLink:
          "https://media.postaam.com/production/PTMEDIA720x1280_ptmedias2_1731826531775.mp4",
        fileType: "video",
      },
    ],
    redFlag: false,
    tags: [
      { _id: "673993621f9d75159ae8aed5", tagName: "Nepal" },
      { _id: "673993621f9d75159ae8aed8", tagName: "postaam" },
      { _id: "673993621f9d75159ae8aedb", tagName: "#viral" },
      { _id: "673993621f9d75159ae8aede", tagName: "trending" },
      { _id: "673993631f9d75159ae8aee1", tagName: "fyp" },
      { _id: "673993631f9d75159ae8aee4", tagName: "foryou" },
      { _id: "673993631f9d75159ae8aee7", tagName: "travel" },
      { _id: "673993631f9d75159ae8aeea", tagName: "nepal" },
      { _id: "673993631f9d75159ae8aeed", tagName: "photography" },
      { _id: "673993631f9d75159ae8aef0", tagName: "love" },
      { _id: "673993631f9d75159ae8aef3", tagName: "viral" },
      { _id: "673993631f9d75159ae8aef6", tagName: "#postaam" },
      { _id: "673993631f9d75159ae8aef9", tagName: "nature" },
      { _id: "673993631f9d75159ae8aefc", tagName: "funny" },
      { _id: "673993631f9d75159ae8aeff", tagName: "Postaam" },
      {
        _id: "673993631f9d75159ae8af02",
        tagName:
          " #dreambusinessofficial  #casurajdhakal  #MoneyManagement #FinancialFreedom #SmartSaving ,#dreambusinessofficial #investment #nepalibusiness #nepalistartup #business #entrepreneur #smallbusiness #entrepreneurship #businessowner #fyp",
      },
    ],
    productId: "",
    productType: "",
    productName: "",
    brand: "",
    _id: "673993621f9d75159ae8aece",
    postName: "Mustang trip ",
    postType: "post",
    userHandle: "@_sand445940",
    allowComment: true,
    postPrivacy: "public",
    userName: "sandarva ghimire",
    finalSubmitted: true,
    views: 15,
    likes: 3,
    comments: 0,
    isLiked: false,
    price: 0,
    isFollowed: false,
    auctWinningStatus: false,
    profileFile:
      "https://media.postaam.com/production/ptmedias2-1731825687999.jpg",
    auctionWinner: "",
    auctionViewed: false,
    shareUrl: "",
    shares: 0,
    totalLikes: 4,
    category: "",
    subCategory: "",
    metaImage: "https://business.postaam.com/img/logoOriginal.3ac6a51c.png",
    metaTitle: "Mustang trip ",
    verified: false,
    taggedUser: 0,
    taggedUsers: [],
    fileType: "video",
    thumbnail:
      "https://media.postaam.com/production/PTMEDIA576x1024_720x1280_ptmedias2_1731826531775.mp4.gif",
    preview:
      "https://media.postaam.com/production/PTMEDIA576x1024_720x1280_ptmedias2_1731826531775.mp4.jpg",
    blockStatus: false,
    blockedBy: "",
    createdAt: "2024-11-17",
    updatedAt: "2024-11-17",
  },
  {
    paidPartnerShip: "N/A",
    status: true,
    medias: [
      {
        mediaLink:
          "https://media.postaam.com/production/ptmedias4-1731826112067.jpg",
        fileType: "image",
      },
    ],
    redFlag: false,
    tags: [
      { _id: "673991bf1f9d75159ae8adf7", tagName: "Nepal" },
      { _id: "673991bf1f9d75159ae8adfa", tagName: "postaam" },
      { _id: "673991bf1f9d75159ae8adfd", tagName: "#viral" },
      { _id: "673991bf1f9d75159ae8ae00", tagName: "fyp" },
      { _id: "673991bf1f9d75159ae8ae03", tagName: "trending" },
      { _id: "673991bf1f9d75159ae8ae06", tagName: "foryou" },
      { _id: "673991bf1f9d75159ae8ae09", tagName: "travel" },
      { _id: "673991bf1f9d75159ae8ae0c", tagName: "nepal" },
      { _id: "673991bf1f9d75159ae8ae0f", tagName: "photography" },
      { _id: "673991bf1f9d75159ae8ae12", tagName: "love" },
      { _id: "673991bf1f9d75159ae8ae15", tagName: "viral" },
      { _id: "673991bf1f9d75159ae8ae18", tagName: "#postaam" },
    ],
    productId: "",
    productType: "",
    productName: "",
    brand: "",
    _id: "673991bf1f9d75159ae8adf0",
    postName: "New user! ",
    postType: "post",
    userHandle: "@_sand445940",
    allowComment: true,
    postPrivacy: "public",
    userName: "sandarva ghimire",
    finalSubmitted: true,
    views: 14,
    likes: 1,
    comments: 1,
    isLiked: false,
    price: 0,
    isFollowed: false,
    auctWinningStatus: false,
    profileFile:
      "https://media.postaam.com/production/ptmedias2-1731825687999.jpg",
    auctionWinner: "",
    auctionViewed: false,
    shareUrl: "",
    shares: 0,
    totalLikes: 4,
    category: "",
    subCategory: "",
    metaImage: "https://business.postaam.com/img/logoOriginal.3ac6a51c.png",
    metaTitle: "New user! ",
    verified: false,
    taggedUser: 0,
    taggedUsers: [],
    fileType: "image",
    thumbnail:
      "https://media.postaam.com/production/ptmedias4-1731826112067.jpg",
    preview: "https://media.postaam.com/production/ptmedias4-1731826112067.jpg",
    blockStatus: false,
    blockedBy: "",
    createdAt: "2024-11-17",
    updatedAt: "2024-11-17",
  },
];


